import { useNavigate } from 'react-router-dom';

import { useAppStore } from 'core/store/app/useAppStore';
import { useUserStore } from 'core/store/user/useUserStore';
import { axiosWithAuthHeader } from 'core/http';
import { USERS } from 'core/http/endpoints';
import { Routes } from 'navigation/routes';
import { UserFormType } from 'types/user/UserFormType';
import { AxiosError } from 'axios';

const useCreateUser = () => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);
    const user = useUserStore((state) => state.user);

    const createUser = (
        data: UserFormType,
        { password, confirmPassword }: { password: string; confirmPassword: string },
    ): void => {
        if (!user) return;

        axiosWithAuthHeader
            .post(USERS, {
                password,
                confirmPassword,
                name: data.name,
                surname: data.lastName,
                patronymic: data.patronymic,
                departmentId: data.department,
                jobTitle: data.jobTitle,
                roleId: data.role,
                email: data.email,
                login: data.login,
                externalUsername: data.externalUsername,
            })
            .then(() => {
                setSuccessAlertText('Пользователь успешно создан.');
                navigate(Routes.Users);
            })
            .catch((error: AxiosError<{ message: string }>) => {
                const errorMessage = error?.response?.data?.message;
                setErrorAlertText(errorMessage || 'Ошибка при сохранении пользователя.');
            });
    };

    return {
        createUser,
    };
};

export { useCreateUser };
