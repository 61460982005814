import { FC, useState } from 'react';
import * as React from 'react';
import { Button, Stack, Paper, Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { Control } from 'react-hook-form';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import { FilterDateFields } from 'components/common/FilterDateFields';
import styles from './NotificationsFilter.module.scss';

type Props = {
    onSubmit: () => void;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDetectionDate: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    onChangeFilter: (key: string, value: string) => void;
    // eslint-disable-next-line
    control: Control<any>;
    onReset: () => void;
};

const NotificationsFilter: FC<Props> = ({
    onSubmit,
    setDetectionDate,
    dateFrom,
    setDateFrom,
    onChangeFilter,
    dateTo,
    setDateTo,
    onReset,
    control,
}) => {
    const { notificationStatusesInputOptions, dictionaryPrioritiesInputOptions, dictionaryRiskObjectsInputOptions } =
        useAdditionalData();

    const [showAllFields, setShowAllFields] = useState<boolean>(false);

    return (
        <div>
            <FilterDateFields
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                dateFrom={dateFrom}
                setShowAllFields={setShowAllFields}
                showAllFields={showAllFields}
            />

            {showAllFields && (
                <Paper elevation={3} className={styles.allFieldsContainer}>
                    <div className={styles.allFields}>
                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'name'}
                                control={control}
                                label={'Наименование уведомления'}
                                onChange={(value) => onChangeFilter('name', value)}
                            />
                            <ControlledTextInput
                                name={'reason'}
                                control={control}
                                label={'Причина риска'}
                                onChange={(value) => onChangeFilter('reason', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Объект воздействия'}
                                name={'category'}
                                options={dictionaryRiskObjectsInputOptions}
                                onChange={(value) => onChangeFilter('category', value.toString())}
                            />
                            <ControlledTextInput
                                name={'informationSource'}
                                control={control}
                                label={'Требования к рискам'}
                                onChange={(value) => onChangeFilter('informationSource', value)}
                            />
                            <ControlledDateInput
                                control={control}
                                label={'Время обнаружения'}
                                name={'detectionDate'}
                                onChange={setDetectionDate}
                            />
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onSubmit}>
                                    Искать
                                </Button>
                                <Button variant="text" onClick={onReset}>
                                    Очистить поиск
                                </Button>
                            </Box>
                        </Stack>

                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'possibleConsequences'}
                                control={control}
                                label={'Описание воздействия'}
                                onChange={(value) => onChangeFilter('possibleConsequences', value)}
                            />
                            <ControlledTextInput
                                name={'riskLocation'}
                                control={control}
                                label={'Местоположение риска'}
                                onChange={(value) => onChangeFilter('riskLocation', value)}
                            />
                            <ControlledTextInput
                                name={'responseTime'}
                                control={control}
                                label={'Сроки реагирования'}
                                onChange={(value) => onChangeFilter('responseTime', value)}
                            />
                            <ControlledTextInput
                                name={'author'}
                                control={control}
                                label={'Кем создано'}
                                onChange={(value) => onChangeFilter('author', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Статус'}
                                name={'state'}
                                options={notificationStatusesInputOptions}
                                onChange={(value) => onChangeFilter('state', value.toString())}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Приоритет'}
                                name={'priority'}
                                options={dictionaryPrioritiesInputOptions}
                                onChange={(value) => onChangeFilter('priority', value.toString())}
                            />
                        </Stack>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export { NotificationsFilter };
