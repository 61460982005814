import * as React from 'react';
import { Control } from 'react-hook-form';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import { Stack } from '@mui/material';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useAdditionalData } from 'hooks/useAdditionalData';

type Props = {
    // eslint-disable-next-line
    control: Control<any>;
    // eslint-disable-next-line
    errors: FieldErrors<any>;
};

const EditRiskComponent: FC<Props> = ({ errors, control }) => {
    const {
        dictionaryRiskObjectsInputOptions,
        dictionaryClassesInputOptions,
        dictionaryLifecycleStagesInputOptions,
        dictionaryRiskSystemInputOptions,
        dictionaryStaffingTableInputOptions,
    } = useAdditionalData();

    return (
        <Stack spacing={2} mb={'32px'}>
            <ControlledTextInput
                name={'name'}
                control={control}
                label={'Краткое наименование риска'}
                error={!!errors.name}
                required
            />
            <ControlledTextInput name={'sourceOfOrigin'} control={control} label={'Источник возникновения'} disabled />
            <ControlledTextInput
                name={'riskRequirements'}
                control={control}
                label={'Требования к рискам'}
                error={!!errors.riskRequirements}
                required
            />

            <h3>Идентификация риска</h3>

            <ControlledSelectInput
                control={control}
                label={'Классификация риска'}
                name={'riskClassification'}
                error={!!errors.riskClassification}
                options={dictionaryClassesInputOptions}
                required
            />
            <ControlledSelectInput
                control={control}
                label={'Объект воздействия'}
                name={'objectOfInfluence'}
                error={!!errors.objectOfInfluence}
                options={dictionaryRiskObjectsInputOptions}
                required
            />
            <ControlledSelectInput
                control={control}
                label={'Этап ЖЦИ'}
                name={'lifeCycleStage'}
                error={!!errors.lifeCycleStage}
                options={dictionaryLifecycleStagesInputOptions}
                required
            />
            <ControlledSelectInput
                control={control}
                label={'Система/подсистема/узел'}
                name={'systemSubsystemNode'}
                error={!!errors.systemSubsystemNode}
                options={dictionaryRiskSystemInputOptions}
                required
            />
            <ControlledSelectInput
                control={control}
                label={'Штатное расписание'}
                name={'staffingSchedule'}
                error={!!errors.staffingSchedule}
                options={dictionaryStaffingTableInputOptions}
                required
            />
            <ControlledTextInput
                name={'reason'}
                control={control}
                label={'Причина риска'}
                error={!!errors.reason}
                required
            />
            <ControlledTextInput
                name={'description'}
                control={control}
                label={'Описание воздействия риска'}
                error={!!errors.description}
                required
            />
            <ControlledTextInput
                name={'comment'}
                control={control}
                label={'Комментарий'}
                error={!!errors.comment}
                multiline
            />
        </Stack>
    );
};

export { EditRiskComponent };
