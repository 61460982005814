import { FC, useState } from 'react';
import * as React from 'react';
import { Button, Stack, Paper, Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { Control } from 'react-hook-form';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { FilterDateFields } from 'components/common/FilterDateFields';
import { useRequirementsAdditionalData } from 'hooks/useRequirementsAdditionalData';
import { ControlledSelectInput } from '../../../../components/common/ControlledSelectInput';
import styles from './RequirementsFilter.module.scss';

type Props = {
    onSubmit: () => void;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    onChangeFilter: (key: string, value: string) => void;
    // eslint-disable-next-line
    control: Control<any>;
    onReset: () => void;
};

const RequirementsFilter: FC<Props> = ({
    onSubmit,
    dateFrom,
    setDateFrom,
    onChangeFilter,
    dateTo,
    setDateTo,
    onReset,
    control,
}) => {
    const { dictionaryRequirementStatusesInputOptions } = useRequirementsAdditionalData();

    const [showAllFields, setShowAllFields] = useState<boolean>(false);

    return (
        <div>
            <FilterDateFields
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                dateFrom={dateFrom}
                setShowAllFields={setShowAllFields}
                showAllFields={showAllFields}
            />

            {showAllFields && (
                <Paper elevation={3} className={styles.allFieldsContainer}>
                    <div className={styles.allFields}>
                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'text'}
                                control={control}
                                label={'Текст требования'}
                                onChange={(value) => onChangeFilter('text', value)}
                            />
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onSubmit}>
                                    Искать
                                </Button>
                                <Button variant="text" onClick={onReset}>
                                    Очистить поиск
                                </Button>
                            </Box>
                        </Stack>

                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'author'}
                                control={control}
                                label={'Кем создан'}
                                onChange={(value) => onChangeFilter('author', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Статус'}
                                name={'statusId'}
                                options={dictionaryRequirementStatusesInputOptions}
                                onChange={(value) => onChangeFilter('statusId', value)}
                            />
                        </Stack>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export { RequirementsFilter };
