import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { AppContainer } from 'components/common/appContainer';
import { risksService } from 'core/services/risksService';
import { UILoader } from 'components/UI/UILoader';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { useAppStore } from 'core/store/app/useAppStore';
import { RiskAssessmentFormDefaultValues } from 'core/constants/forms/RiskAssessmentFormDefaultValues';
import { useRisk } from 'hooks/useRisk';
import { fillRiskFormDefaultValues } from 'utils/fillRiskAssessFromDefaultValues';
import { RiskStatus } from 'core/constants/RiskStatus';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { Routes } from 'navigation/routes';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { AssessRiskForm } from '../components/AssessRiskForm';

const AssessRisk = () => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const { riskId } = useParams<{ riskId: string }>();
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const { risk } = useRisk(riskId);

    const {
        watch,
        control,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        defaultValues: RiskAssessmentFormDefaultValues,
        shouldUseNativeValidation: false,
        mode: 'all',
    });

    const watchFields = watch(['expectedScheduleChange', 'riskProbability', 'expectedDamageCost']);

    useEffect(() => {
        if (risk) {
            fillRiskFormDefaultValues(risk, setValue);
        }
    }, [risk, setValue]);

    const onSave = async () => {
        await trigger();

        if (!riskId || Object.keys(errors).length > 0) {
            setErrorAlertText('Заполните все обязательные поля');
            return;
        }

        const values = getValues();
        risksService.assessRisk(
            riskId,
            { ...values, riskAssessmentId: values.riskAssessmentMethodId },
            () => {
                setSuccessAlertText('Оценка риска успешно сохранена');
                if (risk?.riskStatus.sysName === RiskStatus.RiskOwnerAppointed) {
                    onGoBack();
                }
            },
            () => {
                setErrorAlertText('Ошибка при сохранении оценки риска');
            },
        );
    };

    const sendAssessmentForApproval = async () => {
        await trigger();
        if (!riskId || Object.keys(errors).length > 0) {
            setErrorAlertText('Заполните все обязательные поля');
            return;
        }

        const values = getValues();
        risksService.sendAssessmentForApproval(
            riskId,
            { ...values, riskAssessmentId: values.riskAssessmentMethodId },
            () => {
                setSuccessAlertText('Риск успешно оценен');
                onGoBack();
            },
            () => {
                setErrorAlertText('Ошибка при оценке риска');
            },
        );
    };

    const onGoBack = () => {
        navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.assessRisk}>
                <div className={'container_thin'}>
                    <h2>Оценка риска</h2>

                    {risk ? (
                        <form noValidate>
                            <ViewRiskComponent risk={risk} />
                            <h3>Оценка</h3>

                            <AssessRiskForm
                                control={control}
                                errors={errors}
                                riskProbability={watchFields[1]}
                                expectedScheduleChange={watchFields[0]}
                                expectedDamageCost={watchFields[2]}
                            />

                            {risk.revisionReason && (
                                <div className={'fields-container'}>
                                    <span>Комментарий для уточнения оценки риска:</span>
                                    <span>{risk.revisionReason}</span>
                                </div>
                            )}

                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                {risk?.riskStatus.sysName !== RiskStatus.RiskOwnerAppointed && (
                                    <Box mr={'10px'}>
                                        <Button variant="contained" onClick={sendAssessmentForApproval}>
                                            Отправить на утверждение
                                        </Button>
                                    </Box>
                                )}
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onSave}>
                                        Сохранить оценку
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onGoBack}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { AssessRisk };
