import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { AppContainer } from 'components/common/appContainer';
import { useSendRequirementForApproval } from 'hooks/useSendRequirementForApproval';
import { Routes } from 'navigation/routes';
import { UILoader } from 'components/UI/UILoader';
import { UITextField } from 'components/UI/UITextField';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';

const ConfirmRequirement = () => {
    const navigate = useNavigate();
    const { requirementId } = useParams();

    const { requirement, onConfirm } = useSendRequirementForApproval(requirementId);

    const onEditRequirement = (): void => {
        navigate(`${Routes.EditRequirement}/${requirementId as string}`);
    };

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.requirements.confirm}>
                <div className={'container_thin'}>
                    <h2>Принять решение</h2>
                    {requirement ? (
                        <div>
                            <UITextField value={requirement?.text} label={'Текст требования'} multiline disabled />
                            <Box sx={{ display: 'flex', marginBottom: 4, marginTop: 2 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" onClick={onConfirm}>
                                        Утвердить требование
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onEditRequirement}>
                                        Редактировать требование
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ConfirmRequirement };
