import { Controller, Control } from 'react-hook-form';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { Typography } from '@mui/material';

import { UITextField } from 'components/UI/UITextField';
import { INPUT_MAX_LENGTH, MULTILINE_INPUT_MAX_LENGTH } from 'core/constants/common';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    multiline?: boolean;
    error?: boolean;
    disabled?: boolean;
    hint?: string;
    isEmail?: boolean;
    type?: string;
    rules?: (value: string) => boolean;
    onChange?: (value: string) => void;
    // eslint-disable-next-line
    control: Control<any>;
};

const ControlledTextInput: FC<Props> = ({
    name,
    isEmail,
    rules,
    hint,
    required,
    onChange,
    multiline,
    error,
    label,
    control,
    disabled,
    type = 'string',
}) => {
    const maxLength = useMemo(() => (multiline ? MULTILINE_INPUT_MAX_LENGTH : INPUT_MAX_LENGTH), [multiline]);

    return (
        <div>
            <Controller
                name={name}
                control={control}
                rules={{
                    required,
                    ...(isEmail && {
                        pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Невалидный email',
                        },
                    }),
                    validate: rules,
                    maxLength,
                }}
                render={({ field }) => (
                    <div>
                        <UITextField
                            required={required}
                            multiline={multiline}
                            error={error}
                            label={label}
                            disabled={disabled}
                            type={type}
                            value={field.value as string}
                            setValue={(text) => {
                                field.onChange(text);
                                onChange && onChange(text);
                            }}
                        />
                        {maxLength < ((field.value as string) || '').length && (
                            <Typography color={'error'} variant="caption" display="block" gutterBottom>
                                Превышен лимит количества символов
                            </Typography>
                        )}
                    </div>
                )}
            />
            {hint && (
                <Typography variant="caption" display="block" gutterBottom>
                    {hint}
                </Typography>
            )}
        </div>
    );
};

export { ControlledTextInput };
