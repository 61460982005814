import { FC, useMemo } from 'react';
import * as React from 'react';

import { AppContainer } from 'components/common/appContainer';
import { useDashboard } from 'hooks/useDashboard';
import { UILoader } from 'components/UI/UILoader';
import { StatisticHistogram } from 'components/common/StatisticHistogram/StatisticHistogram';
import { DashboardPeriodFilter } from './components/DashboardPeriodFilter';
import styles from './Dashboard.module.scss';
import { RiskDataCard } from './components/RiskDataCard';

const Dashboard: FC = () => {
    const { selectOptions, period, handleChangeFilter, dashboardData } = useDashboard();

    const risksData = useMemo(() => {
        if (!dashboardData) return;

        return [
            {
                title: 'Средняя продолжительность работы с риском:',
                dynamicInPercent: dashboardData.averageRiskWorkTime.dynamicInPercent,
                dynamicInValue: dashboardData.averageRiskWorkTime.dynamicInValue,
                totalCount: `${dashboardData.averageRiskWorkTime.totalCount || '0'} дн.`,
            },
            {
                title: 'Создано рисков:',
                dynamicInPercent: dashboardData.risksCreatedAmount.dynamicInPercent,
                dynamicInValue: dashboardData.risksCreatedAmount.dynamicInValue,
                totalCount: dashboardData.risksCreatedAmount.totalCount,
            },
            {
                title: 'Утверждено рисков:',
                dynamicInPercent: dashboardData.risksApprovedAmount.dynamicInPercent,
                dynamicInValue: dashboardData.risksApprovedAmount.dynamicInValue,
                totalCount: dashboardData.risksApprovedAmount.totalCount,
            },
        ];
    }, [dashboardData]);

    return (
        <AppContainer>
            <h2>Мониторинг текущих состояний</h2>
            <DashboardPeriodFilter
                period={period}
                handleChangeFilter={handleChangeFilter}
                selectOptions={selectOptions}
            />
            {dashboardData ? (
                <div>
                    <div className={styles.cardsContainer}>
                        {risksData?.map((data, index) => <RiskDataCard key={index} {...data} />)}
                    </div>
                    <div className={styles.cardsContainer}>
                        <StatisticHistogram
                            name={'Уведомления'}
                            data={dashboardData.notificationsByStatus.map((data) => +data.count)}
                            categories={dashboardData.notificationsByStatus.map((data) => data.statusName)}
                        />
                        <StatisticHistogram
                            name={'Риски'}
                            data={dashboardData.risksByStatus.map((data) => +data.count)}
                            categories={dashboardData.risksByStatus.map((data) => data.statusName)}
                        />
                        <StatisticHistogram
                            name={'Мероприятия'}
                            data={dashboardData.eventsByStatus.map((data) => +data.count)}
                            categories={dashboardData.eventsByStatus.map((data) => data.statusName)}
                        />
                        <StatisticHistogram
                            name={'Требования'}
                            data={dashboardData.requirementsByStatus.map((data) => +data.count)}
                            categories={dashboardData.requirementsByStatus.map((data) => data.statusName)}
                        />
                    </div>
                </div>
            ) : (
                <UILoader isVisible />
            )}
        </AppContainer>
    );
};

export { Dashboard };
