import { AppContainer } from 'components/common/appContainer';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { risksService } from 'core/services/risksService';
import { RiskType } from 'types/risks/RiskType';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { UILoader } from 'components/UI/UILoader';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { UserRoles } from 'core/constants/UserRoles';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { Routes } from 'navigation/routes';
import { useAppStore } from 'core/store/app/useAppStore';
import { useUsers } from 'hooks/useUsers';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';

const AddRiskOwner = () => {
    const { riskId } = useParams();
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [risk, setRisk] = useState<RiskType>();
    const { users } = useUsers(UserRoles.risk_owner);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            riskOwner: { label: '', value: '' },
        },
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (riskId) {
            getRisk(riskId);
        }
    }, []);

    const getRisk = (id: string) => {
        risksService.getRisk(id, (res) => {
            const risk = res.data as RiskType;
            setRisk(risk);
            if (risk.riskOwner) {
                setValue('riskOwner', {
                    label: userFullNameFromUser(risk.riskOwner),
                    value: risk.riskOwner.id.toString(),
                });
            }
        });
    };

    const onAddRiskOwner = ({ riskOwner }: { riskOwner: { label: string; value: string } }) => {
        if (!riskId) return;

        risksService.appointRiskOwner(
            riskId,
            riskOwner.value,
            () => {
                setSuccessAlertText('Владелец риска добавлен.');
                navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
            },
            () => {
                setErrorAlertText('Ошибка при добавлении владельца риска.');
            },
        );
    };

    const onCancel = () => {
        navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
    };

    const riskOwners = useMemo(() => {
        return users.map((user) => ({ label: userFullNameFromUser(user), value: user.id }));
    }, [users]);

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.addRiskOwner}>
                <div className={'container_thin'}>
                    <h2>Назначить владельца риска</h2>
                    {risk ? (
                        <>
                            <ViewRiskComponent risk={risk} />
                            <div className={'fields-container'}>
                                <span>Владелец риска:</span>
                                <ControlledAutocompleteInput
                                    control={control}
                                    label={'Владелец риска'}
                                    name={'riskOwner'}
                                    error={!!errors.riskOwner}
                                    options={riskOwners}
                                    required
                                />
                            </div>
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={handleSubmit(onAddRiskOwner)}>
                                        Назначить владельца
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Отменить
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { AddRiskOwner };
