import { CardContent, Typography } from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import * as React from 'react';
import { FC } from 'react';

import styles from '../Dashboard.module.scss';

type Props = {
    title: string;
    totalCount: string | number | null;
    dynamicInPercent: number | null;
    dynamicInValue: number | null;
};

const RiskDataCard: FC<Props> = ({ title, totalCount, dynamicInPercent, dynamicInValue }) => {
    return (
        <CardContent className={styles.card}>
            <Typography variant="body2" color="text.secondary" className={styles.cardTitle}>
                {title}
            </Typography>
            <div className={styles.cardStatistic}>
                <Typography variant="h5" component="div">
                    {totalCount}
                </Typography>
                <div className={styles.cardStatistic}>
                    {dynamicInPercent && (
                        <>{dynamicInPercent > 0 ? <NorthIcon color={'success'} /> : <SouthIcon color={'success'} />}</>
                    )}
                    <Typography variant="h5" component="div" className={styles.cardPercentText}>
                        {dynamicInPercent || '0'} % / {dynamicInValue || 0}
                    </Typography>
                </div>
            </div>
        </CardContent>
    );
};

export { RiskDataCard };
