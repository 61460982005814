import { Controller, Control } from 'react-hook-form';
import * as React from 'react';
import { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    error?: boolean;
    disabled?: boolean;
    // eslint-disable-next-line
    control: Control<any>;
    options: {
        label: string;
        value: string | number;
    }[];
};

const ControlledAutocompleteInput: FC<Props> = ({ name, required, disabled, error, label, control, options }) => {
    const hasValue = (data: { label: string; value: string | number }) => !!data.value;

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required, validate: hasValue }}
            render={({ field }) => (
                <Autocomplete
                    size={'small'}
                    options={options}
                    noOptionsText={'Список пуст'}
                    value={field.value as { label: string; value: string | number }}
                    renderInput={(params) => <TextField error={error} required={required} label={label} {...params} />}
                    disabled={disabled}
                    disablePortal
                    onChange={(e, data) => field.onChange(data)}
                />
            )}
        />
    );
};

export { ControlledAutocompleteInput };
