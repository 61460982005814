import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useMemo } from 'react';

import { tabProps, UITabPanel } from 'components/UI/UITabs';
import { AppContainer } from 'components/common/appContainer';
import { useNotificationsPermissions } from 'hooks/useNotificationsPermissions';
import { useRisksPermissions } from 'hooks/useRisksPermissions';
import { useEventsPermissions } from 'hooks/useEventsPermissions';
import { useRequirementsPermissions } from 'hooks/useRequirementsPermissions';
import { useUsersPagePermissions } from 'hooks/useUsersPagePermissions';
import { Notifications } from '../notifications';
import { Risks } from '../risks';
import { Events } from '../events';
import { Requirements } from '../requirements';
import { Users } from '../users';

const KnowledgeBase = () => {
    const [value, setValue] = React.useState(0);

    const { canViewNotificationsPage } = useNotificationsPermissions();
    const { canViewRisksPage } = useRisksPermissions();
    const { canViewEventsPage } = useEventsPermissions();
    const { canViewRequirementsPage } = useRequirementsPermissions();
    const { canViewUsersPage } = useUsersPagePermissions();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs = useMemo(() => {
        const tabsArr = [];

        if (canViewNotificationsPage) {
            tabsArr.push({ title: 'Уведомления', component: <Notifications renderFilter /> });
        }

        if (canViewRisksPage) {
            tabsArr.push({ title: 'Риски', component: <Risks renderFilter /> });
        }

        if (canViewEventsPage) {
            tabsArr.push({ title: 'Мероприятия', component: <Events renderFilter /> });
        }

        if (canViewRequirementsPage) {
            tabsArr.push({ title: 'Требования', component: <Requirements renderFilter /> });
        }

        if (canViewUsersPage) {
            tabsArr.push({ title: 'Пользователи', component: <Users renderFilter /> });
        }

        return tabsArr;
    }, [canViewNotificationsPage, canViewRisksPage, canViewEventsPage, canViewRequirementsPage, canViewUsersPage]);

    return (
        <AppContainer>
            <h2>База знаний</h2>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.title} {...tabProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => (
                <UITabPanel key={index} value={value} index={index}>
                    {tab.component}
                </UITabPanel>
            ))}
        </AppContainer>
    );
};

export { KnowledgeBase };
