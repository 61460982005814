import { AppContainer } from 'components/common/appContainer';
import { Risks } from './index';

const RisksPage = () => {
    return (
        <AppContainer>
            <Risks renderButtons renderFilter />
        </AppContainer>
    );
};

export { RisksPage };
