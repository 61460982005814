import Chart from 'react-apexcharts';
import { FC } from 'react';

type Props = {
    data: number[];
    name: string;
    categories: string[];
};

const StatisticHistogram: FC<Props> = ({ data, name, categories }) => {
    return (
        <div style={{ border: '1px solid #8e9698' }}>
            <p style={{ padding: '12px 12px 0' }}>{name}</p>
            <Chart
                options={{
                    chart: {
                        id: 'apexchart-example2',
                    },
                    xaxis: {
                        categories,
                    },
                }}
                series={[
                    {
                        name,
                        data,
                    },
                ]}
                type="bar"
            />
        </div>
    );
};

export { StatisticHistogram };
