import { useEffect, useState } from 'react';

import { NotificationType } from 'types/notifications/NotificationType';
import { axiosWithAuthHeader } from 'core/http';
import { NOTIFICATIONS } from 'core/http/endpoints';

const useViewNotification = (id?: string | number) => {
    const [notification, setNotification] = useState<NotificationType>();
    const [isFetching, setIsFetching] = useState<boolean>(true);

    useEffect(() => {
        if (!id) return;

        axiosWithAuthHeader
            .get(`${NOTIFICATIONS}/${id}`)
            .then((res) => {
                setNotification(res.data as NotificationType);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    }, []);

    return {
        isFetching,
        notification,
    };
};

export { useViewNotification };
