import { FC } from 'react';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import './styles.scss';
import { Box, FormControl, Switch } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

interface Column {
    id: 'name' | 'code' | 'population' | 'size' | 'density';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size (km)',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
];

//TODO
// eslint-disable-next-line
function createData(name: string, code: string, population: string, size: string) {
    return { name, code, population, size, density: '132417135423' };
}

const rows = [
    createData('India', 'IN', '1324171354', '3287263'),
    createData('China', 'CN', '1403500365', '9596961'),
    createData('Italy', 'IT', '60483973', '301340'),
    createData('United States', 'US', '327167434', '9833520'),
    createData('Canada', 'CA', '37602103', '9984670'),
    createData('Australia', 'AU', '25475400', '7692024'),
    createData('Germany', 'DE', '83019200', '357578'),
    createData('Ireland', 'IE', '4857000', '70273'),
    createData('Mexico', 'MX', '126577691', '1972550'),
    createData('Japan', 'JP', '126317000', '377973'),
    createData('France', 'FR', '67022000', '640679'),
    createData('United Kingdom', 'GB', '67545757', '242495'),
    createData('Russia', 'RU', '146793744', '17098246'),
    createData('Nigeria', 'NG', '200962417', '923768'),
    createData('Brazil', 'BR', '210147125', '8515767'),
];

const UIKit: FC = () => {
    const [value, setValue] = React.useState<number>(30);
    const [age, setAge] = React.useState('');
    //TODO
    // eslint-disable-next-line
    const [page, setPage] = React.useState(0);
    //TODO
    // eslint-disable-next-line
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //TODO
    // eslint-disable-next-line
    const [open, setOpen] = React.useState(false);

    //TODO
    // eslint-disable-next-line
    const handleOpen = () => setOpen(true);
    //TODO
    // eslint-disable-next-line
    const handleClose = () => setOpen(false);

    //TODO
    // eslint-disable-next-line
    const handleChangeSelect = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    //TODO
    // eslint-disable-next-line
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    return (
        <div className={'uikit'}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">This is a success Alert.</Alert>
                <Alert severity="info">This is an info Alert.</Alert>
                <Alert severity="warning">This is a warning Alert.</Alert>
                <Alert severity="error">This is an error Alert.</Alert>
            </Stack>

            <h1>Заголовок h1</h1>
            <h2>Заголовок h2</h2>
            <h3>Заголовок h3</h3>
            <p>Обычный текст</p>

            <h1>Colors</h1>

            <div className={'uikit__color uikit__color_black'}>--black-color</div>
            <div className={'uikit__color uikit__color_white'}>--white-color</div>
            <div className={'uikit__color uikit__color_red'}>--red-color</div>
            <div className={'uikit__color uikit__color_yellow'}>--dark-yellow-color</div>
            <div className={'uikit__color uikit__color_grey'}>--grey-color</div>
            <div className={'uikit__color uikit__color_dark-grey'}>--dark-grey-color</div>
            <div className={'uikit__color uikit__color_blue'}>--dark-blue-color</div>

            <h1>Buttons</h1>

            <Box mt={'10px'}>
                <Button variant="text">Text button</Button>
            </Box>
            <Box mt={'10px'}>
                <Button variant="text" disabled>
                    Text button disabled
                </Button>
            </Box>
            <Box mt={'10px'}>
                <Button variant="contained" color="primary">
                    Contained button primary
                </Button>
            </Box>
            <Box mt={'10px'}>
                <Button variant="contained" color="secondary">
                    Contained button secondary
                </Button>
            </Box>
            <Box mt={'10px'}>
                <Button variant="contained" disabled>
                    Contained button disabled
                </Button>
            </Box>
            <Box mt={'10px'}>
                <Button variant="outlined">Outlined button</Button>
            </Box>
            <Box mt={'10px'}>
                <Button variant="outlined" disabled>
                    Outlined button disabled
                </Button>
            </Box>

            <h1>Inputs</h1>

            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                autoComplete="off"
                noValidate
            >
                <TextField id="outlined-basic" label="Text field" variant="outlined" />
            </Box>

            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                autoComplete="off"
                noValidate
            >
                <TextField label="Multiline" rows={4} multiline />
            </Box>

            <Switch {...label} defaultChecked />

            <Box sx={{ width: 200, marginBottom: 2 }}>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <Slider aria-label="Volume" value={value} onChange={handleChange} />
                </Stack>
                <div>{value}</div>
            </Box>

            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Select"
                    onChange={handleChangeSelect}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>

            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                <FormControlLabel control={<Checkbox />} label="Required" required />
                <FormControlLabel control={<Checkbox />} label="Disabled" disabled />
            </FormGroup>

            <h1>Пример таблицы</h1>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table aria-label="sticky table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, background: '#8e9698' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow key={row.code} role="checkbox" tabIndex={-1} hover>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} onClick={handleOpen}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export { UIKit };
