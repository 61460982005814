import { useMemo } from 'react';

import { useUserStore } from 'core/store/user/useUserStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRoles } from 'core/constants/UserRoles';

export const useEventsPermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canCreateFromDraft = useMemo(() => {
        return userRolesPermissions.events.createFromDraft.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateFromUnderRevision = useMemo(() => {
        return userRolesPermissions.events.createFromUnderRevision.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEdit = useMemo(() => {
        return userRolesPermissions.events.edit.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateFromWaitsApproval = useMemo(() => {
        return userRolesPermissions.events.createFromWaitsApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateFromResponsibleAppointed = useMemo(
        () =>
            userRolesPermissions.events.createFromResponsibleAppointed.some((r) => getUserRolesSysNames().includes(r)),
        [user],
    );

    const canCreateFromApprovedByProjectManager = useMemo(
        () =>
            userRolesPermissions.events.createFromApprovedByProjectManager.some((r) =>
                getUserRolesSysNames().includes(r),
            ),
        [user],
    );

    const canCreateFromUnderRevisionByResponsible = useMemo(
        () =>
            userRolesPermissions.events.createFromUnderRevisionByResponsible.some((r) =>
                getUserRolesSysNames().includes(r),
            ),
        [user],
    );

    const canEditFromDraft = useMemo(() => {
        return userRolesPermissions.events.editFromDraft.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditFromWaitsApproval = useMemo(() => {
        return userRolesPermissions.events.editFromWaitsApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditFromUnderRevision = useMemo(() => {
        return userRolesPermissions.events.editFromUnderRevision.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditFromResponsibleAppointed = useMemo(() => {
        return userRolesPermissions.events.editFromResponsibleAppointed.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditFromUnderRevisionByResponsible = useMemo(
        () =>
            userRolesPermissions.events.editFromUnderRevisionByResponsible.some((r) =>
                getUserRolesSysNames().includes(r),
            ),
        [user],
    );

    const canSendForApproval = useMemo(() => {
        return userRolesPermissions.events.sendForApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canApprove = useMemo(() => {
        return userRolesPermissions.events.approveEvents.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canAppointResponsible = useMemo(() => {
        return userRolesPermissions.events.appointResponsible.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canSendForRevision = useMemo(
        () => userRolesPermissions.events.sendForRevision.some((r) => getUserRolesSysNames().includes(r)),
        [user],
    );

    const canSendForApprovalByManager = useMemo(
        () => userRolesPermissions.events.canSendForApprovalByManager.some((r) => getUserRolesSysNames().includes(r)),
        [user],
    );

    const canApproveEventPlanWithDates = useMemo(
        () => userRolesPermissions.events.approveEventPlanWithDates.some((r) => getUserRolesSysNames().includes(r)),
        [user],
    );

    const canSendEventPlanWithDatesForRevision = useMemo(
        () =>
            userRolesPermissions.events.sendEventPlanWithDatesForRevision.some((r) =>
                getUserRolesSysNames().includes(r),
            ),
        [user],
    );

    const canSendEventPlanWithDatesForRevisionFromWaitsManagersApproval = useMemo(
        () => getUserRolesSysNames().includes(UserRoles.risk_owner),
        [user],
    );

    const canApproveEventPlanWithDatesFromWaitsManagersApproval = useMemo(
        () => getUserRolesSysNames().includes(UserRoles.risk_owner),
        [user],
    );

    const canSendEventPlanWithDatesForRevisionFromApprovedByRiskOwner = useMemo(
        () => getUserRolesSysNames().includes(UserRoles.project_manager),
        [user],
    );

    const canApproveEventPlanWithDatesFromApprovedByRiskOwner = useMemo(
        () => getUserRolesSysNames().includes(UserRoles.project_manager),
        [user],
    );

    const canSendEventPlanWithDatesForRevisionFromApprovedByProjectManager = useMemo(
        () => getUserRolesSysNames().includes(UserRoles.coordinator),
        [user],
    );

    const canApproveEventPlanWithDatesFromApprovedByProjectManager = useMemo(
        () => getUserRolesSysNames().includes(UserRoles.coordinator),
        [user],
    );

    const canViewEventsPage = useMemo(() => {
        return userRolesPermissions.events.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return {
        canEdit,
        canViewEventsPage,
        canApprove,
        canCreateFromDraft,
        canCreateFromUnderRevision,
        canSendForApproval,
        canAppointResponsible,
        canSendForRevision,
        canCreateFromResponsibleAppointed,
        canCreateFromApprovedByProjectManager,
        canCreateFromUnderRevisionByResponsible,
        canCreateFromWaitsApproval,
        canEditFromDraft,
        canEditFromWaitsApproval,
        canEditFromUnderRevision,
        canEditFromResponsibleAppointed,
        canSendForApprovalByManager,
        canEditFromUnderRevisionByResponsible,
        canApproveEventPlanWithDates,
        canSendEventPlanWithDatesForRevision,
        canSendEventPlanWithDatesForRevisionFromWaitsManagersApproval,
        canApproveEventPlanWithDatesFromWaitsManagersApproval,
        canSendEventPlanWithDatesForRevisionFromApprovedByRiskOwner,
        canApproveEventPlanWithDatesFromApprovedByRiskOwner,
        canSendEventPlanWithDatesForRevisionFromApprovedByProjectManager,
        canApproveEventPlanWithDatesFromApprovedByProjectManager,
    };
};
