import { Box, Button, Tooltip } from '@mui/material';
import * as React from 'react';
import { FC, useMemo } from 'react';

import { useEventsPermissions } from 'hooks/useEventsPermissions';
import { EventStatus } from 'core/constants/EventStatus';
import { EventType } from 'types/events/EventType';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { Routes } from 'navigation/routes';

type Props = {
    events: EventType[];
    eventStatus: string;
    selectedEvent?: EventType;
    onCreateEvent: () => void;
    onEditEvent: () => void;
    sendDraftOnApproval: () => void;
    approveEvents: () => void;
    onSendForRevision: () => void;
    onAppointResponsible: () => void;
    sendForApprovalByManager: () => void;
    sendEventPlanWithDatesForRevision: () => void;
    approveEventPlanWithDates: () => void;
};

const RiskEventsActionButtons: FC<Props> = ({
    events,
    selectedEvent,
    eventStatus,
    onCreateEvent,
    onEditEvent,
    sendDraftOnApproval,
    approveEvents,
    onSendForRevision,
    sendForApprovalByManager,
    onAppointResponsible,
    approveEventPlanWithDates,
    sendEventPlanWithDatesForRevision,
}) => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const {
        canCreateFromDraft,
        canCreateFromUnderRevision,
        canSendForApproval,
        canCreateFromWaitsApproval,
        canApprove,
        canAppointResponsible,
        canSendForRevision,
        canCreateFromResponsibleAppointed,
        canCreateFromApprovedByProjectManager,
        canEditFromResponsibleAppointed,
        canEditFromDraft,
        canEditFromWaitsApproval,
        canEditFromUnderRevision,
        canSendForApprovalByManager,
        canApproveEventPlanWithDates,
        canSendEventPlanWithDatesForRevision,
        canApproveEventPlanWithDatesFromWaitsManagersApproval,
        canSendEventPlanWithDatesForRevisionFromWaitsManagersApproval,
        canSendEventPlanWithDatesForRevisionFromApprovedByRiskOwner,
        canApproveEventPlanWithDatesFromApprovedByRiskOwner,
        canSendEventPlanWithDatesForRevisionFromApprovedByProjectManager,
        canApproveEventPlanWithDatesFromApprovedByProjectManager,
        canCreateFromUnderRevisionByResponsible,
    } = useEventsPermissions();

    const canSendEventsForApprovalByManager = useMemo(() => {
        let filled = true;
        events.map((event) => {
            if (!event.responsible) filled = false;
        });
        return (
            filled &&
            (eventStatus === EventStatus.ResponsibleAppointed || eventStatus === EventStatus.UnderRevisionByResponsible)
        );
    }, [events]);

    const canCreateEvent = useMemo(
        () =>
            (!eventStatus && canCreateFromDraft) ||
            (eventStatus === EventStatus.Draft && canCreateFromDraft) ||
            (eventStatus === EventStatus.UnderRevision && canCreateFromUnderRevision) ||
            (eventStatus === EventStatus.WaitsApproval && canCreateFromWaitsApproval) ||
            (eventStatus === EventStatus.ResponsibleAppointed && canCreateFromResponsibleAppointed) ||
            (eventStatus === EventStatus.UnderRevisionByResponsible && canCreateFromUnderRevisionByResponsible) ||
            (eventStatus === EventStatus.ApprovedByProjectManager && canCreateFromApprovedByProjectManager),
        [eventStatus, canCreateFromDraft, canCreateFromWaitsApproval],
    );

    const canEditEvent = useMemo(
        () =>
            !!selectedEvent &&
            ((selectedEvent.eventStatus.sysName === EventStatus.Draft && canEditFromDraft) ||
                (selectedEvent.eventStatus.sysName === EventStatus.WaitsApproval && canEditFromWaitsApproval) ||
                (selectedEvent.eventStatus.sysName === EventStatus.UnderRevision && canEditFromUnderRevision) ||
                (selectedEvent.eventStatus.sysName === EventStatus.UnderRevisionByResponsible &&
                    canCreateFromUnderRevisionByResponsible) ||
                (selectedEvent.eventStatus.sysName === EventStatus.ResponsibleAppointed &&
                    canEditFromResponsibleAppointed)),
        [selectedEvent],
    );

    const canSendDraftForApproval = useMemo(() => {
        return eventStatus === EventStatus.Draft || eventStatus === EventStatus.UnderRevision;
    }, [eventStatus]);

    const canSendEventsForRevision = useMemo(() => {
        return eventStatus === EventStatus.WaitsApproval;
    }, [eventStatus]);

    const canApproveEvents = useMemo(() => {
        return eventStatus === EventStatus.WaitsApproval;
    }, [eventStatus]);

    const canAddResponsible = useMemo(() => {
        return eventStatus === EventStatus.Approved;
    }, [eventStatus]);

    const canSendPlanWithDatesForRevision = useMemo(() => {
        return (
            (eventStatus === EventStatus.WaitsManagersApproval &&
                canSendEventPlanWithDatesForRevisionFromWaitsManagersApproval) ||
            (eventStatus === EventStatus.ApprovedByProjectManager &&
                canSendEventPlanWithDatesForRevisionFromApprovedByProjectManager) ||
            (eventStatus === EventStatus.ApprovedByRiskOwner &&
                canSendEventPlanWithDatesForRevisionFromApprovedByRiskOwner)
        );
    }, [eventStatus]);

    const canApprovePlanWithDates = useMemo(() => {
        return (
            (eventStatus === EventStatus.WaitsManagersApproval &&
                canApproveEventPlanWithDatesFromWaitsManagersApproval) ||
            (eventStatus === EventStatus.ApprovedByProjectManager &&
                canApproveEventPlanWithDatesFromApprovedByProjectManager) ||
            (eventStatus === EventStatus.ApprovedByRiskOwner && canApproveEventPlanWithDatesFromApprovedByRiskOwner)
        );
    }, [eventStatus]);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box mr={'10px'} mb={'10px'}>
                <Button variant="outlined" disabled={!canCreateEvent} onClick={onCreateEvent}>
                    Создать мероприятие
                </Button>
            </Box>
            <Box mr={'10px'} mb={'10px'}>
                <Button variant="outlined" disabled={!canEditEvent} onClick={onEditEvent}>
                    Редактировать мероприятие
                </Button>
            </Box>
            {canSendForApproval && (
                <Box mr={'10px'} mb={'10px'}>
                    <Button variant="outlined" disabled={!canSendDraftForApproval} onClick={sendDraftOnApproval}>
                        Отправить на согласование
                    </Button>
                </Box>
            )}
            {canApprove && (
                <Box mr={'10px'} mb={'10px'}>
                    <Button variant="outlined" disabled={!canApproveEvents} onClick={approveEvents}>
                        Согласовать план мероприятий
                    </Button>
                </Box>
            )}
            {canSendForRevision && (
                <Box mr={'10px'} mb={'10px'}>
                    <Button variant="outlined" disabled={!canSendEventsForRevision} onClick={onSendForRevision}>
                        Отправить на доработку
                    </Button>
                </Box>
            )}
            {canAppointResponsible && (
                <Box mr={'10px'} mb={'10px'}>
                    <Button variant="outlined" disabled={!canAddResponsible} onClick={onAppointResponsible}>
                        Назначить ответственного
                    </Button>
                </Box>
            )}
            {canSendForApprovalByManager && (
                <Box mr={'10px'} mb={'10px'}>
                    <Tooltip
                        title={
                            'Отправить на согласование руководителем можно только если у всех мероприятий заполнены все поля'
                        }
                    >
                        <span>
                            <Button
                                variant="outlined"
                                disabled={!canSendEventsForApprovalByManager}
                                onClick={sendForApprovalByManager}
                            >
                                Отправить на согласование руководителем
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            )}
            {canApproveEventPlanWithDates && (
                <Box mr={'10px'} mb={'10px'}>
                    <Button variant="outlined" disabled={!canApprovePlanWithDates} onClick={approveEventPlanWithDates}>
                        Утвердить план с датами
                    </Button>
                </Box>
            )}
            {canSendEventPlanWithDatesForRevision && (
                <Box mr={'10px'} mb={'10px'}>
                    <Button
                        variant="outlined"
                        disabled={!canSendPlanWithDatesForRevision}
                        onClick={sendEventPlanWithDatesForRevision}
                    >
                        Вернуть ответственному
                    </Button>
                </Box>
            )}
            <Box mr={'10px'} mb={'10px'}>
                <Button variant="outlined" onClick={() => navigateToOriginalUrl([Routes.Risks, Routes.Tasks])}>
                    Назад
                </Button>
            </Box>
        </Box>
    );
};

export { RiskEventsActionButtons };
