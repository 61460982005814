import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { FC } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactElement;
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '6px',
    p: 4,
};

const UIModal: FC<Props> = ({ isOpen, onClose, title, children }) => (
    <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={onClose}
    >
        <Box sx={style}>
            <div
                style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}
            >
                <Typography variant="h6" component="h2">
                    {title}
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            {children}
        </Box>
    </Modal>
);

export { UIModal };
