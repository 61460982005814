const RiskAssessmentFormDefaultValues = {
    rank: '',
    minimalScheduleChange: '',
    expectedScheduleChange: '',
    maximumScheduleChange: '',
    minimalDamageCost: '',
    expectedDamageCost: '',
    maximumDamageCost: '',
    riskProbability: '',
    riskAcceptanceCriterion: '',
    riskAssessmentMethodId: '',
    assessmentRevisionComment: '-',
};

export { RiskAssessmentFormDefaultValues };
