import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const SearchRisksFilterDefaultValues = {
    name: true,
    riskReason: true,
    objectOfInfluence: true,
    riskRequirements: true,
    impactDescription: true,
    comment: true,
    includeQualificationRisks: true,
    sourceOfOrigin: '',
    riskClassification: '',
    lifeCycleStage: '',
    systemSubsystemNode: '',
    staffingSchedule: '',
};

export type UseSearchRisksStoreType = {
    paginationPage: number;
    filter: {
        name: boolean;
        riskReason: boolean;
        objectOfInfluence: boolean;
        riskRequirements: boolean;
        impactDescription: boolean;
        comment: boolean;
        includeQualificationRisks: boolean;
        sourceOfOrigin: string;
        riskClassification: string;
        lifeCycleStage: string;
        systemSubsystemNode: string;
        staffingSchedule: string;
    };

    setPaginationPage: (page: number) => void;
    resetFilter: () => void;
    setFilter: (key: string, value: string | boolean) => void;
};

const useSearchRisksStore = create<UseSearchRisksStoreType>()(
    persist(
        (set, get) => ({
            filter: SearchRisksFilterDefaultValues,
            paginationPage: 0,

            setPaginationPage: (page) => set({ paginationPage: page }),
            setFilter: (key: string, value: string | boolean) => {
                const filter = get().filter;
                set({ filter: { ...filter, [key]: value } });
            },
            resetFilter: () => {
                set({ filter: SearchRisksFilterDefaultValues });
            },
        }),
        {
            name: 'search-risks-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useSearchRisksStore };
