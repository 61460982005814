import React, { FC } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';

import { muiTheme } from './styles/MuiTheme';
import { ErrorAlert } from './components/common/alerts/ErrorAlert';
import { router } from './navigation/router';
import { SuccessAlert } from './components/common/alerts/SuccessAlert';
import './index.css';
import './styles/index.scss';

const App: FC = () => {
    return (
        <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <ErrorAlert />
                <SuccessAlert />
                <RouterProvider router={router} />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export { App };
