import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import { useAppStore } from 'core/store/app/useAppStore';
import { Routes } from 'navigation/routes';
import { AppContainer } from 'components/common/appContainer';
import { axiosGateway } from 'core/http';
import { useUserStore } from 'core/store/user/useUserStore';
import { LoginUserResponse } from 'types/user/LoginUserResponse';
import { EXTERNAL_LOGIN, LOGIN } from 'core/http/endpoints';
import { useUser } from 'hooks/useUser';
import { UITextField } from 'components/UI/UITextField';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import styles from './login.module.scss';

const Login: FC = () => {
    const navigate = useNavigate();
    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { setAccessToken, accessToken, setRefreshToken } = useUserStore((state) => state);
    const { fetchUser } = useUser();

    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [externalLogin, setExternalLogin] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);

    useEffect(() => {
        if (accessToken) {
            navigate(Routes.Main);
        }
    }, [navigate, accessToken]);

    const onSubmit = (): void => {
        if (!login || !password) {
            setLoginError(!login);
            setPasswordError(!password);
            return;
        }

        const url = externalLogin ? EXTERNAL_LOGIN : LOGIN;

        axiosGateway
            .post(url, {
                login,
                password,
            })
            .then(async (res) => {
                const responseData = res.data as LoginUserResponse;
                setRefreshToken(responseData.refreshToken);
                setAccessToken(responseData.accessToken);
                await fetchUser(responseData.userId);
                onSuccess();
            })
            .catch(onError);
    };

    const onChangeLogin = (value: string): void => {
        setLoginError(false);
        setLogin(value);
    };

    const onChangePassword = (value: string): void => {
        setPasswordError(false);
        setPassword(value);
    };

    const onError = (): void => {
        setErrorAlertText(
            'Неправильный логин или пароль. Если у вас возникли трудности со входом, обратитесь к руководителю.',
        );
    };

    const onSuccess = (): void => {
        setErrorAlertText('');
        navigate(Routes.Main);
    };

    return (
        <AppContainer>
            <div className={styles.container}>
                <div className={styles.fields}>
                    <h2>Авторизация</h2>

                    <Stack spacing={2}>
                        <UITextField
                            value={login}
                            label={'Логин'}
                            setValue={onChangeLogin}
                            error={loginError}
                            required
                        />
                        <UITextField
                            value={password}
                            label={'Пароль'}
                            setValue={onChangePassword}
                            error={passwordError}
                            type={'password'}
                            required
                        />
                        <Button variant="contained" color="primary" size="large" fullWidth onClick={onSubmit}>
                            Вход
                        </Button>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size={'small'}
                                    value={externalLogin}
                                    onChange={(e) => setExternalLogin(e.target.checked)}
                                />
                            }
                            label="Вход через учетную запись Kamotive"
                        />
                    </Stack>
                </div>
            </div>
        </AppContainer>
    );
};

export { Login };
