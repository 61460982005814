import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAppStore } from 'core/store/app/useAppStore';
import { useUserStore } from 'core/store/user/useUserStore';
import { axiosWithAuthHeader } from 'core/http';
import { REQUIREMENTS } from 'core/http/endpoints';
import { RequirementType } from 'types/requirements/RequirementType';

const useEditRequirement = (requirementId?: string) => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);
    const user = useUserStore((state) => state.user);
    const [requirement, setRequirement] = useState<RequirementType>();

    useEffect(() => {
        if (requirementId && !requirement) {
            axiosWithAuthHeader
                .get(`${REQUIREMENTS}/${requirementId}`)
                .then((res) => {
                    setRequirement(res.data as RequirementType);
                })
                .catch((error) => console.log(REQUIREMENTS, error));
        }
    }, [requirementId]);

    const editRequirement = (data: { text: string }, goBack = true): void => {
        if (!user || !requirementId) return;

        axiosWithAuthHeader
            .put(`${REQUIREMENTS}/${requirementId}`, {
                text: data.text,
            })
            .then(() => {
                setSuccessAlertText('Требование успешно отредактировано');
                if (goBack) {
                    navigate(-1);
                }
            })
            .catch(() => {
                setErrorAlertText('Ошибка при редактировании требования.');
            });
    };

    return {
        editRequirement,
        requirement,
        setErrorAlertText,
        setSuccessAlertText,
        navigate,
    };
};

export { useEditRequirement };
