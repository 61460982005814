import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Dayjs } from 'dayjs';

export type UseTasksStoreType = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;

    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
};

const useTasksStore = create<UseTasksStoreType>()(
    persist(
        (set) => ({
            dateFrom: null,
            dateTo: null,

            setDateFrom: (date) => set({ dateFrom: date }),
            setDateTo: (date) => set({ dateTo: date }),
        }),
        {
            name: 'tasks-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useTasksStore };
