import * as React from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect } from 'react';

import { useNotifications } from 'hooks/useNotifications';
import { UITable } from 'components/UI/UITable';
import { Routes } from 'navigation/routes';
import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { NotificationsTableColumns } from 'core/constants/tables/NotificationsTableColumns';
import { NotificationsActionButtons } from './components/NotificationsActionButtons';
import { NotificationsFilter } from './components/NotificationsFilter';

type Props = {
    renderButtons?: boolean;
    renderFilter?: boolean;
};

const Notifications: FC<Props> = ({ renderButtons, renderFilter }) => {
    const { setOriginUrl } = useAsyncAppStore();

    const {
        tableRows,
        isFetching,
        onFilter,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        control,
        onReset,
        notifications,
        setFilter,
        setDetectionDate,
        setPaginationPage,
        exportCSVNotifications,
        totalCount,
    } = useNotifications(!!renderFilter);

    useEffect(() => {
        setOriginUrl(Routes.Notifications);
    }, []);

    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    return (
        <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.view}>
            {renderFilter && (
                <>
                    <h2>Уведомления</h2>

                    <NotificationsFilter
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        control={control}
                        setDateTo={setDateTo}
                        setDateFrom={setDateFrom}
                        setDetectionDate={setDetectionDate}
                        onReset={onReset}
                        onSubmit={onFilter}
                        onChangeFilter={setFilter}
                    />
                </>
            )}

            <NotificationsActionButtons
                renderButtons={renderButtons}
                notifications={notifications}
                exportCSVNotifications={exportCSVNotifications}
                selectedNotificationId={+selectedRows[0] || null}
            />

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет уведомлений'}
                rows={tableRows}
                columns={NotificationsTableColumns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                onChangePaginationPage={setPaginationPage}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
            />
        </UserRightsContainer>
    );
};

export { Notifications };
