import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Routes } from 'navigation/routes';
import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { axiosWithAuthHeader } from 'core/http';
import { REQUIREMENTS } from 'core/http/endpoints';
import { RequirementType } from 'types/requirements/RequirementType';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import styles from './ViewRequirement.module.scss';

const ViewRequirement: FC = () => {
    const navigate = useNavigate();
    const { requirementId } = useParams();

    const [requirement, setRequirement] = useState<RequirementType>();

    useEffect(() => {
        if (!requirementId) return;

        axiosWithAuthHeader
            .get(`${REQUIREMENTS}/${requirementId}`)
            .then((res) => {
                setRequirement(res.data as RequirementType);
            })
            .catch((error) => console.log(error));
    }, []);

    const onCancel = (): void => {
        navigate(Routes.Requirements);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.requirements.view}>
                <div className={styles.container}>
                    {requirement ? (
                        <>
                            <h2>Просмотр требования</h2>
                            <Paper elevation={3} className={styles.fields}>
                                <div className="fields-container">
                                    <span>Текст требования:</span>
                                    <span>{requirement.text}</span>
                                    <span>Статус:</span>
                                    <span>{requirement.requirementStatus.name}</span>
                                    <span>Кем создано:</span>
                                    <span>{userFullNameFromUser(requirement.createdBy)}</span>
                                    <span>Когда создано:</span>
                                    <span>{requirement.createDate}</span>
                                </div>
                                <Button variant="outlined" onClick={onCancel}>
                                    Назад
                                </Button>
                            </Paper>
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ViewRequirement };
