import * as React from 'react';
import { Box, Button } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo, useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContainer } from 'components/common/appContainer';
import { ViewRiskIdentification } from 'components/common/ViewRiskIdentification';
import { useRisk } from 'hooks/useRisk';
import { UITable } from 'components/UI/UITable';
import { EventsTableColumns } from 'core/constants/tables/EventsTableColumns';
import { useRiskEvents } from 'hooks/useRiskEvents';
import { Routes } from 'navigation/routes';
import { EventStatus } from 'core/constants/EventStatus';
import { RiskAssessmentInfo } from 'pages/risks/components/RiskAssessmentInfo';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { ReturnRiskEventsForRevisionModal } from '../components/ReturnRiskEventsForRevisionModal';
import { AppointResponsibleModal } from '../components/AppointResponsibleModal';
import { RiskEventsActionButtons } from '../components/RiskEventsActionButtons';
import { SendEventPlanWithDatesForRevisionModal } from '../components/SendEventPlanWithDatesForRevisionModal';
import { checkStatusIn } from '../../../utils/checkStatusUtils';

const RiskEvents = () => {
    const { riskId } = useParams();
    const navigate = useNavigate();

    const { risk } = useRisk(riskId);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [sendForRevisionModalVisible, setSendForRevisionModalVisible] = useState<boolean>(false);
    const [appointResponsibleModalVisible, setAppointResponsibleModalVisible] = useState<boolean>(false);
    const [sendWithDatesForRevisionModalVisible, setSendWithDatesForRevisionModalVisible] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const {
        tableRows,
        isFetching,
        events,
        totalCount,
        setPaginationPage,
        sendDraftOnApproval,
        approveEvents,
        sendForApprovalByManager,
        approveEventPlanWithDates,
    } = useRiskEvents(riskId);

    const selectedEvent = useMemo(() => {
        return events.find((e) => e.id === selectedRows[0]);
    }, [selectedRows, events]);

    const eventStatus = useMemo(() => {
        return events[0]?.eventStatus.sysName;
    }, [events]);

    const onCreateEvent = (): void => {
        if (!riskId) return;

        navigate(`${Routes.CreateEvent}/${riskId}`);
    };

    const onEditEvent = (): void => {
        if (!selectedEvent || !riskId) return;
        navigate(`${Routes.EditEvent}/${riskId}/${selectedEvent.id}`);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.events.view}>
                <div className={'container_thin'}>
                    <h2>Создание плана мероприятий</h2>

                    {risk && (
                        <>
                            <div className="fields-container">
                                <span>Краткое наименование риска:</span>
                                <span>{risk.shortName}</span>
                                <span>Источник возникновения:</span>
                                <span>{risk?.sourceOfRiskData?.name}</span>
                                <span>Требования к рискам:</span>
                                <span>{risk.riskRequirements}</span>
                            </div>
                            <ViewRiskIdentification risk={risk} />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h3>Оценка</h3>
                                <Button
                                    variant="text"
                                    endIcon={showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    style={{ marginLeft: 12 }}
                                    onClick={() => setShowAll(!showAll)}
                                >
                                    Развернуть
                                </Button>
                            </div>
                            {showAll && <RiskAssessmentInfo risk={risk} />}
                        </>
                    )}
                </div>
                <h2>План мероприятий</h2>
                {!checkStatusIn(eventStatus, [EventStatus.Draft]) && (
                    <>
                        {risk?.eventRevisionReason && (
                            <Box mb={'16px'}>
                                <p>Комментарий для владельца риска: {risk?.eventRevisionReason}</p>
                            </Box>
                        )}
                    </>
                )}
                {!checkStatusIn(eventStatus, [
                    EventStatus.ResponsibleAppointed,
                    EventStatus.UnderRevision,
                    EventStatus.Approved,
                    EventStatus.WaitsApproval,
                    EventStatus.Draft,
                ]) && (
                    <>
                        {risk?.eventRevisionComment && (
                            <Box mb={'16px'}>
                                <p>Комментарий для ответственного за план мероприятий: {risk?.eventRevisionComment}</p>
                            </Box>
                        )}
                    </>
                )}
                <RiskEventsActionButtons
                    events={events}
                    eventStatus={eventStatus}
                    selectedEvent={selectedEvent}
                    sendDraftOnApproval={sendDraftOnApproval}
                    approveEvents={approveEvents}
                    sendForApprovalByManager={sendForApprovalByManager}
                    approveEventPlanWithDates={approveEventPlanWithDates}
                    sendEventPlanWithDatesForRevision={() => setSendWithDatesForRevisionModalVisible(true)}
                    onEditEvent={onEditEvent}
                    onSendForRevision={() => setSendForRevisionModalVisible(true)}
                    onCreateEvent={onCreateEvent}
                    onAppointResponsible={() => setAppointResponsibleModalVisible(true)}
                />

                <UITable
                    totalCount={totalCount}
                    noRowsText={'Нет мероприятий'}
                    rows={tableRows}
                    columns={EventsTableColumns}
                    rowSelectionModel={selectedRows}
                    loading={isFetching}
                    onChangePaginationPage={setPaginationPage}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                />

                {riskId && (
                    <>
                        <ReturnRiskEventsForRevisionModal
                            riskId={riskId}
                            isVisible={sendForRevisionModalVisible}
                            setIsVisible={setSendForRevisionModalVisible}
                        />
                        <AppointResponsibleModal
                            riskId={riskId}
                            isVisible={appointResponsibleModalVisible}
                            setIsVisible={setAppointResponsibleModalVisible}
                        />
                        <SendEventPlanWithDatesForRevisionModal
                            riskId={riskId}
                            isVisible={sendWithDatesForRevisionModalVisible}
                            setIsVisible={setSendWithDatesForRevisionModalVisible}
                        />
                    </>
                )}
            </UserRightsContainer>
        </AppContainer>
    );
};

export { RiskEvents };
