export const RiskFormDefaultValues = {
    name: '',
    description: '',
    sourceOfOrigin: '',
    riskRequirements: '',
    riskClassification: '',
    objectOfInfluence: '',
    lifeCycleStage: '',
    systemSubsystemNode: '',
    staffingSchedule: '',
    reason: '',
    comment: '',
};
