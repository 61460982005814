import { useNavigate } from 'react-router-dom';

import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';

const useNavigateToOriginUrl = () => {
    const navigate = useNavigate();
    const { originUrl } = useAsyncAppStore();

    const navigateToOriginalUrl = (routes: string[]) => {
        if (originUrl && routes.includes(originUrl)) {
            navigate(originUrl);
        } else {
            navigate(-1);
        }
    };

    return {
        navigateToOriginalUrl,
    };
};

export { useNavigateToOriginUrl };
