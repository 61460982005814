import { AppContainer } from 'components/common/appContainer';
import { Notifications } from './index';

const NotificationsPage = () => {
    return (
        <AppContainer>
            <Notifications renderButtons renderFilter />
        </AppContainer>
    );
};

export { NotificationsPage };
