import { FC, useState } from 'react';
import * as React from 'react';
import { Button, Stack, Paper, Box } from '@mui/material';
import { Control } from 'react-hook-form';
import { Dayjs } from 'dayjs';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { FilterDateFields } from 'components/common/FilterDateFields';
import styles from './UsersFilter.module.scss';

type Props = {
    onSubmit: () => void;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    // eslint-disable-next-line
    control: Control<any>;
    onReset: () => void;
    onChangeFilter: (key: string, value: string) => void;
};

const UsersFilter: FC<Props> = ({
    onSubmit,
    dateFrom,
    onChangeFilter,
    setDateFrom,
    dateTo,
    setDateTo,
    control,
    onReset,
}) => {
    const { userDepartmentsInputOptions, userRolesInputOptions } = useAdditionalData();
    const [showAllFields, setShowAllFields] = useState<boolean>(false);

    return (
        <div>
            <FilterDateFields
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                dateFrom={dateFrom}
                setShowAllFields={setShowAllFields}
                showAllFields={showAllFields}
            />

            {showAllFields && (
                <Paper elevation={3} className={styles.allFieldsContainer}>
                    <div className={styles.allFields}>
                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'name'}
                                control={control}
                                label={'ФИО'}
                                onChange={(value) => onChangeFilter('name', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Роль'}
                                name={'role'}
                                options={userRolesInputOptions}
                                onChange={(value) => onChangeFilter('role', value.toString())}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Подразделение'}
                                name={'department'}
                                options={userDepartmentsInputOptions}
                                onChange={(value) => onChangeFilter('department', value.toString())}
                            />
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onSubmit}>
                                    Искать
                                </Button>
                                <Button variant="text" onClick={onReset}>
                                    Очистить поиск
                                </Button>
                            </Box>
                        </Stack>

                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'jobTitle'}
                                control={control}
                                label={'Должность'}
                                onChange={(value) => onChangeFilter('jobTitle', value)}
                            />
                            <ControlledTextInput
                                name={'email'}
                                control={control}
                                label={'Email'}
                                onChange={(value) => onChangeFilter('email', value)}
                            />
                            <ControlledTextInput
                                name={'login'}
                                control={control}
                                label={'Логин'}
                                onChange={(value) => onChangeFilter('login', value)}
                            />
                        </Stack>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export { UsersFilter };
