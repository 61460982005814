import { Box, Button, Stack } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { AppContainer } from 'components/common/appContainer';
import { Routes } from 'navigation/routes';
import { UserFormType } from 'types/user/UserFormType';
import { useAppStore } from 'core/store/app/useAppStore';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { useEditUser } from 'hooks/useEditUser';
import { UILoader } from 'components/UI/UILoader';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { ChangePasswordModal } from '../components/ChangePasswordModal';

const EditUser = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { user, editUser } = useEditUser(id);

    const { userRolesInputOptions, userDepartmentsInputOptions } = useAdditionalData();
    const [passwordModalVisible, setPasswordModalVisible] = useState<boolean>(false);

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            name: '',
            lastName: '',
            patronymic: '',
            role: '',
            department: '',
            email: '',
            jobTitle: '',
            login: '',
            externalUsername: '',
        },
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (user) {
            setValue('name', user.name);
            setValue('lastName', user.surname);
            setValue('patronymic', user.patronymic);
            setValue('role', user.userRoles[0]?.id?.toString());
            setValue('department', user.department?.id?.toString());
            setValue('email', user.email);
            setValue('jobTitle', user.jobTitle);
            setValue('login', user.login);
            if (user.externalUsername) {
                setValue('externalUsername', user.externalUsername);
            }
        }
    }, [user, setValue]);

    const onSubmit: SubmitHandler<UserFormType> = (data) => {
        editUser(data);
        setErrorAlertText('');
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCancel = (): void => {
        navigate(Routes.Users);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.users.edit}>
                <div className={'container_thin'}>
                    <h2>Редактирование пользователя</h2>
                    {user ? (
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2} mb={'32px'}>
                                <ControlledTextInput
                                    name={'lastName'}
                                    control={control}
                                    label={'Фамилия'}
                                    error={!!errors.lastName}
                                    required
                                />
                                <ControlledTextInput
                                    name={'name'}
                                    control={control}
                                    label={'Имя'}
                                    error={!!errors.name}
                                    required
                                />
                                <ControlledTextInput
                                    name={'patronymic'}
                                    control={control}
                                    label={'Отчество'}
                                    error={!!errors.patronymic}
                                    required
                                />
                                <ControlledSelectInput
                                    control={control}
                                    label={'Подразделение'}
                                    name={'department'}
                                    error={!!errors.department}
                                    options={userDepartmentsInputOptions}
                                    required
                                />
                                <ControlledTextInput
                                    name={'jobTitle'}
                                    control={control}
                                    label={'Должность'}
                                    error={!!errors.jobTitle}
                                    required
                                />
                                <ControlledSelectInput
                                    control={control}
                                    label={'Роль'}
                                    name={'role'}
                                    error={!!errors.role}
                                    options={userRolesInputOptions.filter((role) => role.label !== 'Администратор')}
                                    required
                                />
                                <ControlledTextInput
                                    name={'login'}
                                    control={control}
                                    label={'Логин'}
                                    error={!!errors.login}
                                    required
                                />
                                <ControlledTextInput
                                    name={'externalUsername'}
                                    control={control}
                                    label={'Логин Kamotive'}
                                    error={!!errors.externalUsername}
                                />
                                <ControlledTextInput
                                    name={'email'}
                                    control={control}
                                    label={'Email'}
                                    error={!!errors.email}
                                    required
                                    isEmail
                                />
                                <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                    <Box mr={'10px'}>
                                        <Button variant="contained" type={'submit'} onClick={onSave}>
                                            Редактировать пользователя
                                        </Button>
                                    </Box>
                                    <Box mr={'10px'}>
                                        <Button variant="outlined" onClick={() => setPasswordModalVisible(true)}>
                                            Изменить пароль
                                        </Button>
                                    </Box>
                                    <Box mr={'10px'}>
                                        <Button variant="outlined" onClick={onCancel}>
                                            Отменить
                                        </Button>
                                    </Box>
                                </Box>
                            </Stack>
                        </form>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>

                <ChangePasswordModal
                    user={user}
                    isVisible={passwordModalVisible}
                    setIsVisible={setPasswordModalVisible}
                />
            </UserRightsContainer>
        </AppContainer>
    );
};

export { EditUser };
