import * as React from 'react';

import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { CreateRequirement } from 'pages/requirements/CreateRequirement';
import { EditRequirement } from 'pages/requirements/EditRequirement';
import { SendRequirementForApproval } from 'pages/requirements/SendRequirementForApproval';
import { ApproveRequirement } from 'pages/requirements/ApproveRequirement';
import { ConfirmRequirement } from 'pages/requirements/ConfirmRequirement';
import { ViewRequirement } from 'pages/requirements/ViewRequirement';
import { RequirementsPage } from 'pages/requirements/RequirementsPage';
import { Routes } from '../routes';

export const requirementRoutes = [
    {
        path: Routes.Requirements,
        element: (
            <ProtectedRoute>
                <RequirementsPage />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.CreateRequirement,
        element: (
            <ProtectedRoute>
                <CreateRequirement />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.EditRequirement}/:requirementId`,
        element: (
            <ProtectedRoute>
                <EditRequirement />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.SendRequirementForApproval}/:requirementId`,
        element: (
            <ProtectedRoute>
                <SendRequirementForApproval />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ApproveRequirement}/:requirementId`,
        element: (
            <ProtectedRoute>
                <ApproveRequirement />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ConfirmRequirement}/:requirementId`,
        element: (
            <ProtectedRoute>
                <ConfirmRequirement />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ViewRequirement}/:requirementId`,
        element: (
            <ProtectedRoute>
                <ViewRequirement />
            </ProtectedRoute>
        ),
    },
];
