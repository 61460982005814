import { useEffect, useMemo } from 'react';

import { axiosWithAuthHeader } from 'core/http';
import { DICTIONARY_REQUIREMENT_STATUS } from 'core/http/endpoints';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';
import { useUserStore } from 'core/store/user/useUserStore';
import { useRequirementsStore } from '../core/store/requirements/useRequirementsStore';

const useRequirementsAdditionalData = () => {
    const { dictionaryRequirementStatuses, setDictionaryRequirementStatuses } = useRequirementsStore((state) => state);
    const { accessToken } = useUserStore((state) => state);

    useEffect(() => {
        if (accessToken) {
            getRequirementStatuses().catch((error) => console.log(DICTIONARY_REQUIREMENT_STATUS, error));
        }
    }, [accessToken]);

    const getRequirementStatuses = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_REQUIREMENT_STATUS);
        const statuses = result.data as IBaseDictionaryEntity[];
        setDictionaryRequirementStatuses(statuses);
    };

    const dictionaryRequirementStatusesInputOptions = useMemo(() => {
        return dictionaryRequirementStatuses.map((type) => ({
            value: type.id,
            label: type.name,
        }));
    }, [dictionaryRequirementStatuses]);

    return {
        dictionaryRequirementStatusesInputOptions,
    };
};

export { useRequirementsAdditionalData };
