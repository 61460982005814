export const RiskStatus = {
    Draft: 'draft',
    WaitsApproval: 'waits_approval',
    Approved: 'approved',
    RiskOwnerAppointed: 'risk_owner_appointed',
    WaitsAssessment: 'waits_assessment',
    Assessed: 'assessed',
    AssessmentApproved: 'assessment_approved',
    UnderRevision: 'under_revision',
    ApprovedByCoordinator: 'approved_by_coordinator',
    ReturnedToProjectManager: 'returned_to_project_manager',
    Rejected: 'rejected',
};
