import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import { FC } from 'react';

import styles from '../Dashboard.module.scss';

type Props = {
    period: string;
    handleChangeFilter: (event: SelectChangeEvent) => void;
    selectOptions: {
        value: string;
        label: string;
    }[];
};

const DashboardPeriodFilter: FC<Props> = ({ selectOptions, period, handleChangeFilter }) => (
    <div className={styles.filterContainer}>
        <p className={styles.filterTitle}>Выберите период:</p>
        <FormControl size={'small'} sx={{ width: '340px' }}>
            <InputLabel id="demo-simple-select-label">Период</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={period}
                label="Период"
                onChange={handleChangeFilter}
            >
                {selectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </div>
);

export { DashboardPeriodFilter };
