import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { UIKit } from 'pages/uikit';
import { Login } from 'pages/login';
import { Dashboard } from 'pages/dashboard';
import { Tasks } from 'pages/tasks';
import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { Search } from 'pages/search';
import { Reports } from 'pages/reports';
import { KnowledgeBase } from 'pages/knowledgeBase';
import { Routes } from './routes';
import { notificationRoutes } from './routeStacks/notificationRoutes';
import { eventRoutes } from './routeStacks/eventRoutes';
import { userRoutes } from './routeStacks/userRoutes';
import { riskRoutes } from './routeStacks/riskRoutes';
import { requirementRoutes } from './routeStacks/requirementRoutes';

const router = createBrowserRouter([
    {
        path: Routes.Main,
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.UIKit,
        element: <UIKit />,
    },
    {
        path: Routes.Login,
        element: <Login />,
    },
    {
        path: Routes.Dashboard,
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.Tasks,
        element: (
            <ProtectedRoute>
                <Tasks />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.Search,
        element: (
            <ProtectedRoute>
                <Search />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.FAQ,
        element: (
            <ProtectedRoute>
                <KnowledgeBase />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.Reports,
        element: (
            <ProtectedRoute>
                <Reports />
            </ProtectedRoute>
        ),
    },
    ...notificationRoutes,
    ...eventRoutes,
    ...userRoutes,
    ...riskRoutes,
    ...requirementRoutes,
]);

export { router };
