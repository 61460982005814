import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { AppContainer } from 'components/common/appContainer';
import { notificationsService } from 'core/services/notificationsService';
import { NotificationType } from 'types/notifications/NotificationType';
import { RiskFormType } from 'types/risks/RiskFormType';
import { useAppStore } from 'core/store/app/useAppStore';
import { axiosWithAuthHeader } from 'core/http';
import { RISKS } from 'core/http/endpoints';
import { risksService } from 'core/services/risksService';
import { RiskType } from 'types/risks/RiskType';
import { Routes } from 'navigation/routes';
import { RiskFormDefaultValues } from 'core/constants/forms/RiskFormDefaultValues';
import { fillRiskFormDefaultValues } from 'utils/fillRiskFormDefaultValues';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { EditRiskComponent } from '../components/EditRiskComponent';

const CreateRisk = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: RiskFormDefaultValues,
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (params.notificationId) {
            getNotification(params.notificationId);
        } else if (params.riskId) {
            getRisk(params.riskId);
        } else {
            setValue('sourceOfOrigin', 'Новый риск');
        }
    }, []);

    const getNotification = (id: string) => {
        notificationsService.getNotification(id, (res) => {
            const notification = res.data as NotificationType;
            setValue('name', notification.notificationName);
            setValue('sourceOfOrigin', 'Hа основании уведомления');
            setValue('riskRequirements', notification.riskRequirements);
            setValue('objectOfInfluence', notification.riskObject.id.toString());
            setValue('reason', notification.impactDescription);
            setValue('description', notification.riskReason);
            setValue('comment', notification.comment);
        });
    };

    const getRisk = (id: string) => {
        risksService.getRisk(id, (res) => {
            const risk = res.data as RiskType;
            fillRiskFormDefaultValues(risk, setValue);
            setValue('sourceOfOrigin', 'Hа основании риска');
        });
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCreateRisk: SubmitHandler<RiskFormType> = (data) => {
        let url = RISKS;
        if (params.notificationId) {
            url = `${RISKS}?notificationId=${params.notificationId}`;
        } else if (params.riskId) {
            url = `${RISKS}?riskId=${params.riskId}`;
        }

        axiosWithAuthHeader
            .post(url, {
                shortName: data.name,
                riskRequirements: data.riskRequirements,
                riskClassId: data.riskClassification,
                riskObjectId: data.objectOfInfluence,
                lifecycleStageId: data.lifeCycleStage,
                riskSystemId: data.systemSubsystemNode,
                staffingTableId: data.staffingSchedule,
                reason: data.reason,
                riskImpactDescription: data.description,
                comment: data.comment,
            })
            .then(() => {
                setSuccessAlertText('Риск успешно создан');
                if (params.notificationId || params.riskId) {
                    navigate(Routes.Notifications);
                } else {
                    navigate(Routes.Risks);
                }
            })
            .catch(() => {
                setErrorAlertText('Ошибка при создании риска.');
            });
    };

    const onCancel = () => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.create}>
                <form className={'container_thin'} noValidate onSubmit={handleSubmit(onCreateRisk)}>
                    <h2>Создание нового риска</h2>
                    <EditRiskComponent errors={errors} control={control} />
                    <Box sx={{ display: 'flex', marginBottom: 4 }}>
                        <Box mr={'10px'}>
                            <Button variant="contained" type={'submit'} onClick={onSave}>
                                Создать новый риск
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onCancel}>
                                Отменить
                            </Button>
                        </Box>
                    </Box>
                </form>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { CreateRisk };
