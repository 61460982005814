import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationType } from 'types/notifications/NotificationType';
import { axiosWithAuthHeader } from 'core/http';
import { NOTIFICATIONS } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { Routes } from 'navigation/routes';
import { NotificationFormType } from 'types/notifications/NotificationFormType';
import { notificationsService } from 'core/services/notificationsService';

const useEditNotification = (id?: string | number) => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);

    const [notification, setNotification] = useState<NotificationType>();
    const [isFetching, setIsFetching] = useState<boolean>(true);

    useEffect(() => {
        if (!id) return;

        notificationsService.getNotification(id, (res) => {
            const data = res.data as NotificationType;
            setNotification(data);
            setIsFetching(false);
        });
    }, []);

    const editNotification = (data: NotificationFormType): void => {
        if (!id) return;

        axiosWithAuthHeader
            .put(`${NOTIFICATIONS}/${id}`, {
                notificationName: data.name,
                riskReason: data.reason,
                riskObjectId: data.riskObjectId,
                riskRequirements: data.riskRequirements,
                detectionDate: data.detectionDate?.format('YYYY-MM-DD HH:mm:ss'),
                impactDescription: data.impactDescription,
                riskLocation: data.riskLocation,
                responseTime: data.responseTime,
                comment: data.comment,
                priorityId: data.priorityId,
            })
            .then(() => {
                setSuccessAlertText('Уведомление успешно отредактировано.');
                navigate(Routes.Notifications);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при сохранении формы.');
            });
    };

    return {
        isFetching,
        notification,
        editNotification,
    };
};

export { useEditNotification };
