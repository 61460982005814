export const LOGIN = '/login';
export const EXTERNAL_LOGIN = '/externalLogin';
export const LOG_OUT = '/log_out';
export const REFRESH = '/refresh';
export const NOTIFICATIONS = '/notifications';
export const REQUIREMENTS = '/requirements';
export const SEND_FOR_APPROVAL = '/sendForApproval';
export const REJECT = '/reject';
export const APPROVE = '/approve';
export const CONFIRM = '/confirm';
export const USERS = '/users';
export const RISKS = '/risks';
export const EVENTS = '/events';
export const TASKS = '/tasks';
export const APPOINT_RISK_SPECIALIST = '/appointRiskSpecialist';
export const APPOINT_RISK_OWNER = '/appointRiskOwner';
export const SEND_FOR_REVISION = '/sendForRevision';
export const SEND_ASSESSMENT_FOR_APPROVAL = '/sendAssessmentForApproval';
export const ASSESS_RISK = '/assessRisk';
export const APPROVE_RISK_ASSESSMENT = '/approveRiskAssessment';
export const SEND_ASSESSMENT_FOR_REVISION = '/sendAssessmentForRevision';
export const CHANGE_RISK_OWNER = '/changeRiskOwner';
export const SEND_FOR_REVISION_BY_COORDINATOR = '/sendForRevisionByCoordinator';
export const APPROVE_BY_COORDINATOR = '/approveByCoordinator';
export const APPOINT_RESPONSIBLE = '/appointResponsible';
export const BY_PROJECT_MANAGER = '/byProjectManager';
export const SEND_FOR_APPROVAL_BY_MANAGER = '/sendForApprovalByManager';
export const SEND_EVENT_PLAN_WITH_DATES_FOR_REVISION = '/sendEventPlanWithDatesForRevision';
export const APPROVE_EVENT_PLAN_WITH_DATES = '/approveEventPlanWithDates';

// dictionary
export const NOTIFICATION_STATUS = '/dictionary/notificationStatus';
export const DICTIONARY_RISK_OBJECT = '/dictionary/riskObject';
export const DICTIONARY_RISK_STATUS = '/dictionary/riskStatus';
export const DICTIONARY_PRIORITY = '/dictionary/priority';
export const DICTIONARY_ROLE = '/dictionary/role';
export const DICTIONARY_DEPARTMENTS = '/dictionary/department';
export const DICTIONARY_SOURCE_OF_RISK_DATA = '/dictionary/sourceOfRiskData';
export const DICTIONARY_CLASS = '/dictionary/class';
export const DICTIONARY_LIFECYCLE_STAGE = '/dictionary/lifecycleStage';
export const DICTIONARY_RISK_SYSTEM = '/dictionary/riskSystem';
export const DICTIONARY_STAFFING_TABLE = '/dictionary/staffingTable';
export const DICTIONARY_RISK_ASSESSMENT_METHOD = '/dictionary/riskAssessmentMethod';
export const DICTIONARY_EVENT_TYPES = '/dictionary/eventType';
export const DICTIONARY_EVENT_STATUS = '/dictionary/eventStatus';

export const DICTIONARY_REQUIREMENT_STATUS = '/dictionary/requirementStatus';

export const DASHBOARD = '/dashboard';
export const DASHBOARD_RISKS = '/dashboard/risks';
export const DASHBOARD_REQUIREMENTS = '/dashboard/requirements';
export const DASHBOARD_EVENTS = '/dashboard/events';

// csv
export const NOTIFICATIONS_CSV = '/notifications/csv';
export const RISKS_CSV = '/risks/csv';
export const EVENTS_CSV = '/events/csv';
export const REQUIREMENTS_CSV = '/requirements/csv';
