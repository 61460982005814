import { AxiosResponse } from 'axios';

import { NotificationEditRequestType } from 'types/notifications/NotificationEditRequestType';
import { NotificationType } from 'types/notifications/NotificationType';
import { axiosWithAuthHeader } from '../http';
import { APPOINT_RISK_SPECIALIST, APPROVE, NOTIFICATIONS, REJECT } from '../http/endpoints';

// eslint-disable-next-line
type CallbackType = (res: AxiosResponse<any>) => void;

export const notificationsService = {
    getNotification: (id: string | number, successCallback: CallbackType) => {
        axiosWithAuthHeader
            .get<NotificationType>(`${NOTIFICATIONS}/${id}`)
            .then((res) => {
                successCallback(res);
            })
            .catch((error) => console.log(error));
    },
    editNotification: (
        id: string | number,
        params: NotificationEditRequestType,
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader.put(`${NOTIFICATIONS}/${id}`, params).then(successCallback).catch(errorCallback);
    },
    approveNotification: (id: string | number, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader
            .patch(`${NOTIFICATIONS}/${id as string}${APPROVE}`)
            .then(successCallback)
            .catch(errorCallback);
    },
    rejectNotification: (
        id: string | number,
        rejectReason: string,
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader
            .post(`${NOTIFICATIONS}/${id}${REJECT}`, { rejectReason })
            .then(successCallback)
            .catch(errorCallback);
    },
    addRiskSpecialist: (
        id: string | number,
        riskSpecialistId: string | number,
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader
            .patch(`${NOTIFICATIONS}/${id}${APPOINT_RISK_SPECIALIST}`, {
                riskSpecialistId,
            })
            .then(successCallback)
            .catch(errorCallback);
    },
};
