import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { notificationsService } from 'core/services/notificationsService';
import { NotificationType } from 'types/notifications/NotificationType';
import { ViewNotificationComponent } from 'components/common/ViewNotificaionComponent';
import { Routes } from 'navigation/routes';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { RejectNotificationModal } from '../components/RejectNotificationModal';

const MakeRiskDecision: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [notification, setNotification] = useState<NotificationType>();
    const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        notificationsService.getNotification(id, (res) => {
            setNotification(res.data as NotificationType);
        });
    }, []);

    const onSearchRisk = (): void => {
        navigate(`${Routes.NotificationsSearchRisk}/${id as string}`);
    };

    const onCreateRisk = (): void => {
        navigate(`${Routes.CreateRisk}?notificationId=${id as string}`);
    };

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.makeRiskDecision}>
                <div className={'container'}>
                    <h2>Принять решение</h2>
                    {notification ? (
                        <div>
                            <ViewNotificationComponent notification={notification} />
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onSearchRisk}>
                                        Поиск существующего риска в БД
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCreateRisk}>
                                        Создать риск на основе уведомления
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={() => setRejectModalVisible(true)}>
                                        Отклонить уведомление
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>

                            <RejectNotificationModal
                                notificationId={notification.id}
                                isVisible={rejectModalVisible}
                                setIsVisible={setRejectModalVisible}
                            />
                        </div>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { MakeRiskDecision };
