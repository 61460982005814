import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

export const EventsTableColumns: GridColDef[] = [
    {
        field: 'type',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Тип <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'text',
        flex: 30,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Текст <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'responsible',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Ответственный за <br /> исполнение <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'dueDate',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Срок выполнения <br /> (плановая дата)
            </strong>
        ),
    },
    {
        field: 'controlDate',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Срок контроля <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'createdBy',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Кем создано</strong>,
    },
    {
        field: 'createDate',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Когда создано</strong>,
    },
    {
        field: 'status',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Статус</strong>,
    },
];
