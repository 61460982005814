import { Box } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    isVisible: boolean;
};

const UILoader: FC<Props> = ({ isVisible }) => {
    if (!isVisible) return null;

    return (
        <Box sx={{ display: 'flex', height: 300, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
};

export { UILoader };
