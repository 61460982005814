import * as React from 'react';

import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { CreateUser } from 'pages/users/CreateUser';
import { EditUser } from 'pages/users/EditUser';
import { ViewUser } from 'pages/users/ViewUser';
import { UsersPage } from 'pages/users/UsersPage';
import { Routes } from '../routes';

export const userRoutes = [
    {
        path: Routes.Users,
        element: (
            <ProtectedRoute>
                <UsersPage />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.CreateUser,
        element: (
            <ProtectedRoute>
                <CreateUser />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.EditUser}/:id`,
        element: (
            <ProtectedRoute>
                <EditUser />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ViewUser}/:id`,
        element: (
            <ProtectedRoute>
                <ViewUser />
            </ProtectedRoute>
        ),
    },
];
