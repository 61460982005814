import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            error: string;
        };
        typography: {
            fontSize: number;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            error?: string;
        };
    }
}

const muiTheme = createTheme({
    status: {
        error: '#b90f36',
    },
    typography: {
        button: {
            textTransform: 'none',
        },
        fontSize: 12,
    },
    palette: {
        primary: {
            main: '#012e55',
        },
        secondary: {
            main: '#ab7c12',
        },
        error: {
            main: '#b90f36',
        },
    },
});

export { muiTheme };
