export const EventStatus = {
    Draft: 'draft',
    WaitsApproval: 'waits_approval',
    UnderRevision: 'under_revision',
    Approved: 'approved',
    ResponsibleAppointed: 'responsible_appointed',
    WaitsManagersApproval: 'waits_managers_approval',
    ApprovedByRiskOwner: 'approved_by_risk_owner',
    UnderRevisionByResponsible: 'under_revision_by_responsible',
    ApprovedByProjectManager: 'approved_by_project_manager',
    ApprovedByCoordinator: 'approved_by_coordinator',
};
