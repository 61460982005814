import { FC, useEffect } from 'react';
import Alert from '@mui/material/Alert';

import { useAppStore } from 'core/store/app/useAppStore';
import styles from './alert.module.scss';

const ErrorAlert: FC = () => {
    const errorAlertText = useAppStore((state) => state.errorAlertText);
    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);

    useEffect(() => {
        if (errorAlertText) {
            const timeout = setTimeout(() => {
                setErrorAlertText('');
            }, 5000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [errorAlertText, setErrorAlertText]);

    if (!errorAlertText) return null;

    return (
        <div className={styles.container}>
            <Alert className={styles.alert} severity="error">
                {errorAlertText}
            </Alert>
        </div>
    );
};

export { ErrorAlert };
