import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContainer } from 'components/common/appContainer';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { UILoader } from 'components/UI/UILoader';
import { useRisk } from 'hooks/useRisk';
import { useAppStore } from 'core/store/app/useAppStore';
import { RiskAssessmentFormDefaultValues } from 'core/constants/forms/RiskAssessmentFormDefaultValues';
import { risksService } from 'core/services/risksService';
import { Routes } from 'navigation/routes';
import { AssessRiskForm } from '../components/AssessRiskForm';

const SendForRevisionByCoordinator = () => {
    const navigate = useNavigate();
    const { riskId } = useParams();

    const { risk } = useRisk(riskId);

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const {
        watch,
        control,
        getValues,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { ...RiskAssessmentFormDefaultValues, assessmentRevisionComment: '' },
        shouldUseNativeValidation: false,
    });

    const watchFields = watch(['expectedScheduleChange', 'riskProbability', 'expectedDamageCost']);

    useEffect(() => {
        if (risk) {
            setValue('rank', risk.rank.toString());
            setValue('minimalScheduleChange', risk.minimalScheduleChange.toString());
            setValue('expectedScheduleChange', risk.expectedScheduleChange.toString());
            setValue('maximumScheduleChange', risk.maximumScheduleChange.toString());
            setValue('minimalDamageCost', risk.minimalDamageCost.toString());
            setValue('expectedDamageCost', risk.expectedDamageCost.toString());
            setValue('maximumDamageCost', risk.maximumDamageCost.toString());
            setValue('riskProbability', risk.riskProbability.toString());
            setValue('riskAcceptanceCriterion', risk.riskAcceptanceCriterion.toString());
            setValue('riskAssessmentMethodId', risk.riskAssessmentMethod.id.toString());
        }
    }, [risk]);

    const onSubmit = () => {
        if (!riskId) return;

        const data = getValues();
        risksService.sendForRevisionByCoordinator(
            riskId,
            { ...data, riskAssessmentId: data.riskAssessmentMethodId },
            () => {
                setSuccessAlertText('Оценка риска успешно отправлена на доработку');
                navigate(Routes.Risks);
            },
            () => {
                setErrorAlertText('Ошибка при отправке оценки риска на доработку');
            },
        );
    };

    const onSendForRevision = () => {
        if (!isValid) {
            setErrorAlertText('Заполните все обязательные поля');
            return;
        }
    };

    return (
        <AppContainer>
            <div className={'container_thin'}>
                <h2>Оценка риска</h2>
                {risk ? (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <ViewRiskComponent risk={risk} />
                        <AssessRiskForm
                            control={control}
                            errors={errors}
                            riskProbability={watchFields[1]}
                            expectedScheduleChange={watchFields[0]}
                            expectedDamageCost={watchFields[2]}
                        />
                        <div className={'fields-container'}>
                            <span>Комментарий для уточнения оценки риска:</span>
                            <ControlledTextInput
                                name={'assessmentRevisionComment'}
                                control={control}
                                label={'Комментарий'}
                                error={!!errors.assessmentRevisionComment}
                                multiline
                                required
                            />
                        </div>
                        <Box sx={{ display: 'flex', marginBottom: 4 }}>
                            <Box mr={'10px'}>
                                <Button variant="contained" type={'submit'} onClick={onSendForRevision}>
                                    Отправить на доработку
                                </Button>
                            </Box>
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={() => navigate(-1)}>
                                    Назад
                                </Button>
                            </Box>
                        </Box>
                    </form>
                ) : (
                    <UILoader isVisible />
                )}
            </div>
        </AppContainer>
    );
};

export { SendForRevisionByCoordinator };
