import { AppContainer } from 'components/common/appContainer';
import { Requirements } from './index';

const RequirementsPage = () => {
    return (
        <AppContainer>
            <Requirements renderButtons renderFilter />
        </AppContainer>
    );
};

export { RequirementsPage };
