import { Typography } from '@mui/material';
import { FC } from 'react';

import styles from './PageNotFound.module.scss';

type Props = {
    text?: string;
    errorCode?: string;
};

const PageNotFound: FC<Props> = ({ text = 'Страница не найдена', errorCode = '404' }) => (
    <div className={styles.container}>
        <Typography variant="h1" className={styles.text}>
            <strong>{errorCode}</strong>
        </Typography>
        <Typography className={styles.text}>{text}</Typography>
    </div>
);

export { PageNotFound };
