import { FC } from 'react';
import * as React from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Routes } from 'navigation/routes';
import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import styles from './ViewNotification.module.scss';

const ViewEvent: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    console.log('id', id);
    // const { notification, isFetching } = useViewNotification(id);
    const isFetching = false;
    const onCancel = (): void => {
        navigate(Routes.Events);
    };

    return (
        <AppContainer>
            <div className={styles.container}>
                {isFetching ? (
                    <UILoader isVisible />
                ) : (
                    <>
                        <h2>Просмотр мероприятия</h2>
                        <Paper elevation={3} className={styles.fields}>
                            <span>Наименование мероприятия:</span>
                            <span>Наименование</span>
                            <span>Описание мероприятия:</span>
                            <span>Описание</span>
                            <span>Тип:</span>
                            <span>Тип</span>
                            <span>Срок выполнения (плановая дата):</span>
                            <span>25.05.2024</span>
                            <span>Срок выполнения (фактическая дата):</span>
                            <span>25.05.2024</span>
                            <span>Ответственный за выполнение:</span>
                            <span>Иванов И И</span>
                            <span>Срок контроля мероприятия:</span>
                            <span>25.05.2024</span>
                            {/*<span>Причина отклонения:</span>*/}
                            {/*<span>Такой риск уже существует</span>*/}
                            <Button variant="outlined" onClick={onCancel}>
                                Назад
                            </Button>
                        </Paper>
                    </>
                )}
            </div>
        </AppContainer>
    );
};

export { ViewEvent };
