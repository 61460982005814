import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

import { MULTILINE_INPUT_MAX_LENGTH } from 'core/constants/common';
import { axiosWithAuthHeader } from 'core/http';
import { EVENTS, SEND_EVENT_PLAN_WITH_DATES_FOR_REVISION } from 'core/http/endpoints';
import { UIModal } from 'components/UI/UIModal';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { useAppStore } from 'core/store/app/useAppStore';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    riskId: string;
};

const SendEventPlanWithDatesForRevisionModal: FC<Props> = ({ isVisible, setIsVisible, riskId }) => {
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const {
        control,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { rejectionText: '' },
        shouldUseNativeValidation: false,
    });

    const onSubmit = (): void => {
        const { rejectionText } = getValues();

        if (!isValid || rejectionText.length < 5) {
            setErrorAlertText(`Текст должен быть не меньше 5 и не больше ${MULTILINE_INPUT_MAX_LENGTH} символов`);
        } else {
            onSendRiskForRevision(rejectionText);
        }
    };

    const onSendRiskForRevision = (reason: string) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .patch(`${riskId}${EVENTS}${SEND_EVENT_PLAN_WITH_DATES_FOR_REVISION}`, {
                reason,
            })
            .then(() => {
                setSuccessAlertText('Мероприятия отправлены на доработку');
                setIsVisible(false);
                window.location.reload();
            })
            .catch(() => setErrorAlertText('Ошибка при отправке мероприятий на доработку'));
    };

    return (
        <UIModal isOpen={isVisible} title={'Укажите, что необходимо доработать'} onClose={() => setIsVisible(false)}>
            <>
                <ControlledTextInput
                    name={'rejectionText'}
                    control={control}
                    label={'Введите текст'}
                    error={!!errors.rejectionText}
                    required
                    multiline
                />
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box mr={'10px'}>
                        <Button variant="contained" type={'submit'} onClick={onSubmit}>
                            Вернуть на доработку
                        </Button>
                    </Box>
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={() => setIsVisible(false)}>
                            Назад
                        </Button>
                    </Box>
                </Box>
            </>
        </UIModal>
    );
};

export { SendEventPlanWithDatesForRevisionModal };
