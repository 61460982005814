import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { AppContainer } from 'components/common/appContainer';
import { RiskFormType } from 'types/risks/RiskFormType';
import { useAppStore } from 'core/store/app/useAppStore';
import { axiosWithAuthHeader } from 'core/http';
import { RISKS } from 'core/http/endpoints';
import { RiskFormDefaultValues } from 'core/constants/forms/RiskFormDefaultValues';
import { risksService } from 'core/services/risksService';
import { RiskType } from 'types/risks/RiskType';
import { fillRiskFormDefaultValues } from 'utils/fillRiskFormDefaultValues';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { EditRiskComponent } from '../components/EditRiskComponent';

const EditRisk = () => {
    const navigate = useNavigate();
    const { riskId } = useParams();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: RiskFormDefaultValues,
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (riskId) {
            getRisk(riskId);
        }
    }, []);

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const getRisk = (id: string) => {
        risksService.getRisk(id, (res) => {
            const risk = res.data as RiskType;
            fillRiskFormDefaultValues(risk, setValue);
        });
    };

    const onEditRisk: SubmitHandler<RiskFormType> = (data) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .put(`${RISKS}/${riskId}`, {
                shortName: data.name,
                riskRequirements: data.riskRequirements,
                riskClassId: data.riskClassification,
                riskObjectId: data.objectOfInfluence,
                lifecycleStageId: data.lifeCycleStage,
                riskSystemId: data.systemSubsystemNode,
                staffingTableId: data.staffingSchedule,
                reason: data.reason,
                riskImpactDescription: data.description,
                comment: data.comment,
            })
            .then(() => {
                setSuccessAlertText('Риск успешно отредактирован');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при редактировании риска');
            });
    };

    const onCancel = () => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.edit}>
                <form className={'container_thin'} noValidate onSubmit={handleSubmit(onEditRisk)}>
                    <h2>Редактирование риска</h2>
                    <EditRiskComponent errors={errors} control={control} />
                    <Box sx={{ display: 'flex', marginBottom: 4 }}>
                        <Box mr={'10px'}>
                            <Button variant="contained" type={'submit'} onClick={onSave}>
                                Сохранить изменения
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onCancel}>
                                Отменить
                            </Button>
                        </Box>
                    </Box>
                </form>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { EditRisk };
