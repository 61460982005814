import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';

import { AppContainer } from 'components/common/appContainer';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { useAppStore } from 'core/store/app/useAppStore';
import { useSendRequirementForApproval } from 'hooks/useSendRequirementForApproval';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';

const SendRequirementForApproval = () => {
    const navigate = useNavigate();
    const { requirementId } = useParams();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { editRequirement, requirement, onSendForApproval } = useSendRequirementForApproval(requirementId);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { text: '' },
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (requirement) {
            setValue('text', requirement.text);
        }
    }, [requirement]);

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onSubmit: SubmitHandler<{ text: string }> = (data) => {
        editRequirement(data, false);
        setErrorAlertText('');
    };

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.requirements.sendOnApproval}>
                <div className={'container_thin'}>
                    <h2>Редактирование требования</h2>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <ControlledTextInput
                            name={'text'}
                            control={control}
                            label={'Текст требования'}
                            error={!!errors.text}
                            required
                            multiline
                        />
                        <Box sx={{ display: 'flex', marginBottom: 4, marginTop: 2 }}>
                            <Box mr={'10px'}>
                                <Button variant="contained" onClick={onSendForApproval}>
                                    Отправить на согласование
                                </Button>
                            </Box>
                            <Box mr={'10px'}>
                                <Button variant="outlined" type={'submit'} onClick={onSave}>
                                    Сохранить требование
                                </Button>
                            </Box>
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onCancel}>
                                    Отменить
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { SendRequirementForApproval };
