import Chart from 'react-apexcharts';
import { FC } from 'react';

type Props = {
    series: number[];
    labels: string[];
    name: string;
};

const DonutDiagram: FC<Props> = ({ series, name, labels }) => {
    return (
        <div style={{ border: '1px solid #8e9698' }}>
            <p style={{ padding: '12px 12px 0' }}>{name}</p>
            <Chart
                options={{
                    chart: {
                        id: 'apexchart-example1',
                    },
                    series,
                    labels,
                }}
                series={series}
                plotoptions={{
                    pie: {
                        donut: {
                            size: '25%',
                        },
                    },
                }}
                type="donut"
            />
        </div>
    );
};

export { DonutDiagram };
