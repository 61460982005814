import * as React from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

import { AppContainer } from 'components/common/appContainer';
import { useTasksPage } from 'hooks/useTasksPage';
import { UITable } from 'components/UI/UITable';
import { Routes } from 'navigation/routes';
import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TasksTableColumns } from 'core/constants/tables/TasksTableColumns';
import { TasksFilter } from './components/TasksFilter';

const Tasks = () => {
    const { setOriginUrl } = useAsyncAppStore();

    const {
        tableRows,
        isFetching,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        setPaginationPage,
        totalCount,
        tasks,
        accomplishTask,
    } = useTasksPage();
    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    useEffect(() => {
        setOriginUrl(Routes.Tasks);
    }, []);

    const selectedTask = useMemo(() => {
        return tasks.find((n) => n.id === selectedRows[0]);
    }, [selectedRows[0]]);

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.tasks.view}>
                <h2>Задачи</h2>

                <TasksFilter dateFrom={dateFrom} setDateTo={setDateTo} dateTo={dateTo} setDateFrom={setDateFrom} />

                <Box mr={'10px'}>
                    <Button variant="outlined" disabled={!selectedTask} onClick={() => accomplishTask(selectedTask)}>
                        Выполнить задачу
                    </Button>
                </Box>

                <UITable
                    totalCount={totalCount}
                    noRowsText={'Нет задач'}
                    rows={tableRows}
                    columns={TasksTableColumns}
                    rowSelectionModel={selectedRows}
                    loading={isFetching}
                    onChangePaginationPage={setPaginationPage}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                />
            </UserRightsContainer>
        </AppContainer>
    );
};

export { Tasks };
