import TextField from '@mui/material/TextField';
import * as React from 'react';
import { FC } from 'react';

type Props = {
    value: string;
    placeholder?: string;
    multiline?: boolean;
    required?: boolean;
    error?: boolean;
    label?: string;
    disabled?: boolean;
    type?: string;
    setValue?: (text: string) => void;
};

const UITextField: FC<Props> = ({
    value,
    setValue,
    placeholder,
    disabled,
    error,
    label,
    required,
    multiline,
    type = 'string',
}) => {
    return (
        <TextField
            value={value}
            size="small"
            variant="outlined"
            placeholder={placeholder}
            multiline={multiline}
            rows={multiline ? 4 : 1}
            required={required}
            error={error}
            label={label}
            disabled={disabled}
            inputProps={{ type }}
            fullWidth
            onChange={(e) => setValue && setValue(e.target.value)}
        />
    );
};

export { UITextField };
