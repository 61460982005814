import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserType } from 'types/user/UserType';

export type UseUserStoreType = {
    user: UserType | null;
    accessToken: string | null;
    refreshToken: string | null;

    setAccessToken: (token: string | null) => void;
    setRefreshToken: (token: string | null) => void;
    setUser: (user: UserType | null) => void;
    logOut: () => void;
};

const useUserStore = create<UseUserStoreType>()(
    persist(
        (set) => ({
            user: null,
            accessToken: '',
            refreshToken: '',
            setAccessToken: (accessToken) => set({ accessToken }),
            setRefreshToken: (refreshToken) => set({ refreshToken }),
            setUser: (user) => set({ user }),
            logOut: () => set({ user: null, accessToken: '', refreshToken: '' }),
        }),
        {
            name: 'user-storage',
        },
    ),
);

export { useUserStore };
