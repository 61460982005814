import { useEffect, useState } from 'react';

import { axiosWithAuthHeader } from 'core/http';
import { USERS } from 'core/http/endpoints';
import { UserType } from 'types/user/UserType';
import { PaginationType } from 'types/PaginationType';

const useUsers = (roleSysName?: string) => {
    const [users, setUsers] = useState<UserType[]>([]);

    useEffect(() => {
        axiosWithAuthHeader
            .get<{ data: UserType[]; pagination: PaginationType }>(USERS, { params: { roleSysName } })
            .then((res) => setUsers(res.data.data))
            .catch((error) => console.log(error));
    }, []);

    return {
        users,
    };
};

export { useUsers };
