import { create } from 'zustand';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';

interface RequirementsState {
    dictionaryRequirementStatuses: IBaseDictionaryEntity[];

    setDictionaryRequirementStatuses: (data: IBaseDictionaryEntity[]) => void;
}

const useRequirementsStore = create<RequirementsState>((set) => ({
    dictionaryRequirementStatuses: [],

    setDictionaryRequirementStatuses: (data: IBaseDictionaryEntity[]) => set({ dictionaryRequirementStatuses: data }),
}));

export { useRequirementsStore };
