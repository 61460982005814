import Button from '@mui/material/Button';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import * as React from 'react';

import { NotificationStatus } from 'core/constants/NotificationStatus';
import { Routes } from 'navigation/routes';
import { NotificationType } from 'types/notifications/NotificationType';
import { useNotificationsPermissions } from 'hooks/useNotificationsPermissions';

type Props = {
    selectedNotificationId: number | null;
    notifications: NotificationType[];
    exportCSVNotifications: () => void;
    renderButtons?: boolean;
};

const NotificationsActionButtons: FC<Props> = ({
    selectedNotificationId,
    notifications,
    exportCSVNotifications,
    renderButtons,
}) => {
    const navigate = useNavigate();
    const {
        canCreateNotification,
        canEditNotification,
        canApproveNotification,
        canMakeRiskDecision,
        canAddRiskSpecialist,
        canSendOnApproval,
    } = useNotificationsPermissions();

    const onCreateNotification = (): void => {
        navigate(Routes.CreateNotification);
    };

    const onEditNotification = (): void => {
        if (!selectedNotificationId) return;

        navigate(`${Routes.EditNotification}/${selectedNotificationId}`);
    };

    const onViewNotification = (): void => {
        if (!selectedNotificationId) return;

        navigate(`${Routes.ViewNotification}/${selectedNotificationId}`);
    };

    const onSendToApproveNotification = (): void => {
        if (!selectedNotificationId) return;

        navigate(`${Routes.SendNotificationToApproveWithEdit}/${selectedNotificationId}`);
    };

    const onApproveNotification = (): void => {
        if (!selectedNotificationId) return;

        navigate(`${Routes.ApproveNotification}/${selectedNotificationId}`);
    };

    const onMakeRiskDecision = (): void => {
        if (!selectedNotificationId) return;

        navigate(`${Routes.NotificationRiskDecision}/${selectedNotificationId}`);
    };

    const onAddRiskSpecialist = (): void => {
        if (!selectedNotificationId) return;

        navigate(`${Routes.NotificationAddRiskSpecialist}/${selectedNotificationId}`);
    };

    const selectedNotification = useMemo(() => {
        return notifications.find((n) => n.id === selectedNotificationId);
    }, [selectedNotificationId]);

    return (
        <div style={{ display: 'flex' }}>
            {renderButtons && (
                <>
                    {canCreateNotification && (
                        <Button variant="outlined" sx={{ mr: '10px' }} onClick={onCreateNotification}>
                            Создать новое уведомление
                        </Button>
                    )}
                    {canEditNotification && (
                        <Button
                            sx={{ mr: '10px' }}
                            variant="outlined"
                            disabled={
                                !selectedNotificationId ||
                                selectedNotification?.status?.sysName !== NotificationStatus.Draft
                            }
                            onClick={onEditNotification}
                        >
                            Редактировать уведомление
                        </Button>
                    )}
                    {canSendOnApproval && (
                        <Button
                            sx={{ mr: '10px' }}
                            variant="outlined"
                            disabled={
                                !selectedNotificationId ||
                                selectedNotification?.status?.sysName !== NotificationStatus.Draft
                            }
                            onClick={onSendToApproveNotification}
                        >
                            Согласовать уведомление
                        </Button>
                    )}
                    {canApproveNotification && (
                        <Button
                            sx={{ mr: '10px' }}
                            variant="outlined"
                            disabled={
                                !selectedNotificationId ||
                                selectedNotification?.status?.sysName !== NotificationStatus.WaitsApproval
                            }
                            onClick={onApproveNotification}
                        >
                            Принять решение
                        </Button>
                    )}
                    {canMakeRiskDecision && (
                        <Button
                            sx={{ mr: '10px' }}
                            variant="outlined"
                            disabled={
                                !selectedNotificationId ||
                                selectedNotification?.status?.sysName !== NotificationStatus.Approved
                            }
                            onClick={onMakeRiskDecision}
                        >
                            Принять решение по риску
                        </Button>
                    )}
                    {canAddRiskSpecialist && (
                        <Button
                            variant="outlined"
                            disabled={
                                !selectedNotificationId ||
                                selectedNotification?.status?.sysName !== NotificationStatus.Approved ||
                                !!selectedNotification?.riskSpecialist?.id
                            }
                            sx={{ mr: '10px' }}
                            onClick={onAddRiskSpecialist}
                        >
                            Назначить сотрудника
                        </Button>
                    )}
                </>
            )}
            <Box mr={'10px'}>
                <Button variant="outlined" disabled={!selectedNotificationId} onClick={onViewNotification}>
                    Просмотр
                </Button>
            </Box>
            {renderButtons && (
                <Box mr={'10px'}>
                    <Button variant="outlined" onClick={exportCSVNotifications}>
                        Выгрузить CSV файл
                    </Button>
                </Box>
            )}
        </div>
    );
};

export { NotificationsActionButtons };
