import { useEffect, useMemo } from 'react';

import { axiosWithAuthHeader } from 'core/http';
import { DICTIONARY_EVENT_STATUS, DICTIONARY_EVENT_TYPES } from 'core/http/endpoints';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';
import { useUserStore } from 'core/store/user/useUserStore';
import { useEventsStore } from 'core/store/events/useEventsStore';

const useEventsAdditionalData = () => {
    const { dictionaryEventTypes, dictionaryEventStatuses, setDictionaryEventTypes, setDictionaryEventStatuses } =
        useEventsStore((state) => state);
    const { accessToken } = useUserStore((state) => state);

    useEffect(() => {
        if (accessToken) {
            getEventTypes().catch((error) => console.log(DICTIONARY_EVENT_TYPES, error));
            getEventStatuses().catch((error) => console.log(DICTIONARY_EVENT_STATUS, error));
        }
    }, [accessToken]);

    const getEventTypes = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_EVENT_TYPES);
        const types = result.data as IBaseDictionaryEntity[];
        setDictionaryEventTypes(types);
    };

    const getEventStatuses = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_EVENT_STATUS);
        const statuses = result.data as IBaseDictionaryEntity[];
        setDictionaryEventStatuses(statuses);
    };

    const dictionaryEventTypesInputOptions = useMemo(() => {
        return dictionaryEventTypes.map((type) => ({
            value: type.id,
            label: type.name,
        }));
    }, [dictionaryEventTypes]);

    const dictionaryEventStatusesInputOptions = useMemo(() => {
        return dictionaryEventStatuses.map((type) => ({
            value: type.id,
            label: type.name,
        }));
    }, [dictionaryEventStatuses]);

    return {
        dictionaryEventTypesInputOptions,
        dictionaryEventStatusesInputOptions,
    };
};

export { useEventsAdditionalData };
