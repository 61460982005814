import { FC, useEffect } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { Box, Button, Stack } from '@mui/material';

import { AppContainer } from 'components/common/appContainer';
import { useSendNotificationToApprove } from 'hooks/useSendNotificationToApprove';
import { NotificationFormType } from 'types/notifications/NotificationFormType';
import { Routes } from 'navigation/routes';
import { useAppStore } from 'core/store/app/useAppStore';
import { UILoader } from 'components/UI/UILoader';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { NotificationStatus } from 'core/constants/NotificationStatus';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { NotificationFormDefaultValues } from 'core/constants/forms/NotificationFormDefaultValues';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';

const SendNotificationToApproveStep1: FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { notification, editNotification, isFetching, userSupervisors, riskSpecialistSupervisors } =
        useSendNotificationToApprove(id);

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { dictionaryRiskObjectsInputOptions, dictionaryPrioritiesInputOptions } = useAdditionalData();

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: NotificationFormDefaultValues,
        shouldUseNativeValidation: false,
        mode: 'all',
    });

    useEffect(() => {
        if (notification) {
            setValue('name', notification.notificationName);
            setValue('reason', notification.riskReason);
            setValue('riskObjectId', notification.riskObject?.id.toString());
            setValue('priorityId', notification.priority?.id.toString());
            setValue('riskRequirements', notification.riskRequirements);
            setValue('detectionDate', dayjs(notification.detectionDate, 'DD.MM.YYYY'));
            setValue('impactDescription', notification.impactDescription);
            setValue('riskLocation', notification.riskLocation);
            setValue('responseTime', notification.responseTime);
            setValue('comment', notification.comment);

            if (notification.status.sysName === NotificationStatus.WaitsApproval) {
                setValue('coordinatingEmployee', {
                    label: userFullNameFromUser(notification.coordinatingUser),
                    value: notification.coordinatingUser.id.toString(),
                });
                setValue('approvingEmployee', {
                    label: userFullNameFromUser(notification.approvingUser),
                    value: notification.approvingUser.id.toString(),
                });
                setValue('approvalComment', notification.approvalComment);
            }
        }
    }, [notification, setValue]);

    const onSubmit: SubmitHandler<NotificationFormType> = () => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onSave = (): void => {
        if (Object.keys(errors)?.length > 0) {
            setErrorAlertText('Заполните все поля формы.');
            return;
        }
        setErrorAlertText('');
        editNotification(getValues());
    };

    const onCancel = (): void => {
        navigate(-1);
    };

    const onApprove = (): void => {
        if (Object.keys(errors)?.length > 0) {
            setErrorAlertText('Заполните все поля формы.');
            return;
        }
        navigate(`${Routes.SendNotificationToApprove}/${id as string}`);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.sendOnApproval}>
                <div className={'container_thin'}>
                    <h2>Отправка уведомления на согласование</h2>
                    <UILoader isVisible={isFetching} />

                    {!!notification && (
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2} mb={'32px'}>
                                <ControlledTextInput
                                    name={'name'}
                                    control={control}
                                    label={'Наименование уведомления'}
                                    error={!!errors.name}
                                    required
                                />
                                <ControlledTextInput
                                    name={'reason'}
                                    control={control}
                                    label={'Причина риска'}
                                    error={!!errors.reason}
                                    required
                                    multiline
                                />
                                <ControlledSelectInput
                                    control={control}
                                    label={'Объект воздействия'}
                                    name={'riskObjectId'}
                                    error={!!errors.riskObjectId}
                                    options={dictionaryRiskObjectsInputOptions}
                                    required
                                />
                                <ControlledSelectInput
                                    control={control}
                                    label={'Приоритет'}
                                    name={'priorityId'}
                                    error={!!errors.priorityId}
                                    options={dictionaryPrioritiesInputOptions}
                                    required
                                />
                                <ControlledTextInput
                                    name={'riskRequirements'}
                                    control={control}
                                    label={'Требования к рискам'}
                                    error={!!errors.riskRequirements}
                                    required
                                />
                                <ControlledDateInput
                                    control={control}
                                    label={'Время обнаружения'}
                                    name={'detectionDate'}
                                    error={!!errors.detectionDate}
                                    required
                                />
                                <ControlledTextInput
                                    name={'impactDescription'}
                                    control={control}
                                    label={'Описание воздействия'}
                                    error={!!errors.impactDescription}
                                    required
                                    multiline
                                />
                                <ControlledTextInput
                                    name={'riskLocation'}
                                    control={control}
                                    label={'Местоположение риска'}
                                    error={!!errors.riskLocation}
                                    required
                                />
                                <ControlledTextInput
                                    name={'responseTime'}
                                    control={control}
                                    label={'Сроки реагирования'}
                                    error={!!errors.responseTime}
                                    required
                                />
                                <ControlledTextInput
                                    name={'comment'}
                                    control={control}
                                    label={'Комментарий'}
                                    error={!!errors.comment}
                                    multiline
                                />
                                {notification.status.sysName === NotificationStatus.WaitsApproval && (
                                    <>
                                        <ControlledAutocompleteInput
                                            control={control}
                                            label={'Согласующий сотрудник'}
                                            name={'coordinatingEmployee'}
                                            error={!!errors.coordinatingEmployee}
                                            options={userSupervisors}
                                            disabled
                                            required
                                        />
                                        <ControlledAutocompleteInput
                                            control={control}
                                            label={'Утверждающий сотрудник'}
                                            name={'approvingEmployee'}
                                            error={!!errors.approvingEmployee}
                                            options={riskSpecialistSupervisors}
                                            required
                                        />
                                        <ControlledTextInput
                                            name={'approvalComment'}
                                            control={control}
                                            label={'Комментарий для согласования'}
                                            error={!!errors.approvalComment}
                                            multiline
                                        />
                                    </>
                                )}

                                {notification.status.sysName === NotificationStatus.WaitsApproval ? (
                                    <Box sx={{ display: 'flex' }}>
                                        <Box mr={'10px'}>
                                            <Button variant="outlined" type={'submit'} onClick={onSave}>
                                                Сохранить уведомление
                                            </Button>
                                        </Box>
                                        <Box mr={'10px'}>
                                            <Button variant="outlined" onClick={onCancel}>
                                                Отменить
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex' }}>
                                        <Box mr={'10px'}>
                                            <Button variant="contained" onClick={onApprove}>
                                                Назначить согласующих
                                            </Button>
                                        </Box>
                                        <Box mr={'10px'}>
                                            <Button variant="outlined" type={'submit'} onClick={onSave}>
                                                Сохранить уведомление
                                            </Button>
                                        </Box>
                                        <Box mr={'10px'}>
                                            <Button variant="outlined" onClick={onCancel}>
                                                Назад
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Stack>
                        </form>
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { SendNotificationToApproveStep1 };
