import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { axiosWithAuthHeader } from 'core/http';
import { USERS } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { Routes } from 'navigation/routes';
import { UserType } from 'types/user/UserType';
import { UserFormType } from 'types/user/UserFormType';
import { usersService } from 'core/services/usersService';

const useEditUser = (id?: string | number) => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);

    const [user, setUser] = useState<UserType>();

    useEffect(() => {
        if (!id) return;

        usersService.getUser(id, (res) => {
            setUser(res.data as UserType);
        });
    }, []);

    const editUser = (data: UserFormType): void => {
        if (!id) return;

        axiosWithAuthHeader
            .put(`${USERS}/${id}`, {
                name: data.name,
                surname: data.lastName,
                patronymic: data.patronymic,
                departmentId: data.department,
                jobTitle: data.jobTitle,
                roles: [data.role],
                email: data.email,
                login: data.login,
                externalUsername: data.externalUsername,
            })
            .then(() => {
                setSuccessAlertText('Пользователь успешно отредактирован.');
                navigate(Routes.Users);
            })
            .catch((error: AxiosError<{ message: string }>) => {
                const errorMessage = error?.response?.data?.message;
                setErrorAlertText(errorMessage || 'Ошибка при сохранении пользователя.');
            });
    };

    return {
        user,
        editUser,
    };
};

export { useEditUser };
