import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { RiskType } from '../types/risks/RiskType';
import { RiskAssessmentFormDefaultValues } from '../core/constants/forms/RiskAssessmentFormDefaultValues';

export const fillRiskFormDefaultValues = (
    risk: RiskType,
    setValue: UseFormSetValue<typeof RiskAssessmentFormDefaultValues>,
) => {
    setValue('rank', risk.rank?.toString());
    setValue('minimalScheduleChange', risk.minimalScheduleChange?.toString());
    setValue('expectedScheduleChange', risk.expectedScheduleChange?.toString());
    setValue('maximumScheduleChange', risk.maximumScheduleChange?.toString());
    setValue('minimalDamageCost', risk.minimalDamageCost?.toString());
    setValue('expectedDamageCost', risk.expectedDamageCost?.toString());
    setValue('maximumDamageCost', risk.maximumDamageCost?.toString());
    setValue('riskProbability', risk.riskProbability?.toString());
    setValue('riskAcceptanceCriterion', risk.riskAcceptanceCriterion?.toString());
    setValue('riskAssessmentMethodId', risk.riskAssessmentMethod?.id?.toString());
};
