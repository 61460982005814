import { useEffect, useMemo } from 'react';

import { axiosWithAuthHeader } from 'core/http';
import { DICTIONARY_RISK_ASSESSMENT_METHOD } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';
import { useUserStore } from 'core/store/user/useUserStore';

const useRisksAdditionalData = () => {
    const { setDictionaryRiskAssessmentMethod, dictionaryRiskAssessmentMethod } = useAppStore((state) => state);
    const { accessToken } = useUserStore((state) => state);

    useEffect(() => {
        if (accessToken) {
            getRiskAssessmentMethods().catch((error) => console.log(DICTIONARY_RISK_ASSESSMENT_METHOD, error));
        }
    }, [accessToken]);

    const getRiskAssessmentMethods = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_RISK_ASSESSMENT_METHOD);
        const statuses = result.data as IBaseDictionaryEntity[];
        setDictionaryRiskAssessmentMethod(statuses);
    };

    const dictionaryPrioritiesInputOptions = useMemo(() => {
        return dictionaryRiskAssessmentMethod.map((method) => ({
            value: method.id,
            label: method.name,
        }));
    }, [dictionaryRiskAssessmentMethod]);

    return {
        dictionaryPrioritiesInputOptions,
    };
};

export { useRisksAdditionalData };
