import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Dayjs } from 'dayjs';

export const RisksFilterDefaultValues = {
    shortName: '',
    sourceOfOrigin: '',
    riskRequirements: '',
    riskClassification: '',
    objectOfInfluence: '',
    lifeCycleStage: '',
    systemSubsystemNode: '',
    staffingSchedule: '',
    reason: '',
    comment: '',
    riskImpactDescription: '',
    author: '',
    status: '',
    createdBy: '',
};

export type UseRisksStoreType = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    paginationPage: number;
    filter: {
        shortName: string;
        sourceOfOrigin: string;
        riskRequirements: string;
        riskClassification: string;
        objectOfInfluence: string;
        lifeCycleStage: string;
        staffingSchedule: string;
        reason: string;
        author: string;
        status: string;
        comment: string;
        systemSubsystemNode: string;
        riskImpactDescription: string;
        createdBy: string;
    };

    setPaginationPage: (page: number) => void;
    resetFilter: () => void;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setFilter: (key: string, value: string) => void;
};

const useRisksStore = create<UseRisksStoreType>()(
    persist(
        (set, get) => ({
            dateFrom: null,
            dateTo: null,
            filter: RisksFilterDefaultValues,
            paginationPage: 0,

            setPaginationPage: (page) => set({ paginationPage: page }),
            setDateFrom: (date) => set({ dateFrom: date }),
            setDateTo: (date) => set({ dateTo: date }),
            setFilter: (key: string, value: string | Dayjs | null) => {
                const filter = get().filter;
                set({ filter: { ...filter, [key]: value } });
            },
            resetFilter: () => {
                set({ filter: RisksFilterDefaultValues });
            },
        }),
        {
            name: 'risks-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useRisksStore };
