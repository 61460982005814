import { FC } from 'react';
import * as React from 'react';
import { Dayjs } from 'dayjs';

import { FilterDateFields } from 'components/common/FilterDateFields';

type Props = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
};

const TasksFilter: FC<Props> = ({ dateFrom, setDateFrom, dateTo, setDateTo }) => {
    return (
        <div>
            <FilterDateFields
                showFilterButton={false}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                dateFrom={dateFrom}
            />
        </div>
    );
};

export { TasksFilter };
