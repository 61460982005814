import { FC, useEffect } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Routes } from 'navigation/routes';
import { useUserStore } from 'core/store/user/useUserStore';
import { useUser } from 'hooks/useUser';
import { useRisksPermissions } from 'hooks/useRisksPermissions';
import { useNotificationsPermissions } from 'hooks/useNotificationsPermissions';
import { useRequirementsPermissions } from 'hooks/useRequirementsPermissions';
import { useEventsPermissions } from 'hooks/useEventsPermissions';
import { useTasksPermissions } from 'hooks/useTasksPermissions';
import { useUsersPagePermissions } from 'hooks/useUsersPagePermissions';
import { useReportsPagePermissions } from 'hooks/useReportsPagePermissions';

import styles from './header.module.scss';
import { HeaderNavLink } from './HeaderNavLink';

const Header: FC = () => {
    const location = useLocation();

    const { canViewRisksPage } = useRisksPermissions();
    const { canViewNotificationsPage } = useNotificationsPermissions();
    const { canViewRequirementsPage } = useRequirementsPermissions();
    const { canViewEventsPage } = useEventsPermissions();
    const { canViewTasksPage } = useTasksPermissions();
    const { canViewUsersPage } = useUsersPagePermissions();
    const { canViewReportsPage } = useReportsPagePermissions();

    const { fetchUser } = useUser();

    const { accessToken, user } = useUserStore((state) => state);
    const { logOut } = useUser();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (user?.id) {
            fetchUser(user.id).catch((error) => console.log(error));
        }
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onLogOut = (): void => {
        logOut();
        handleClose();
    };

    return (
        <header className={styles.header}>
            <div className={'container'}>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <span className={styles.name}>Система управления данными</span>
                    </div>
                    {!!accessToken && (
                        <div className={styles.navigation}>
                            <div className={styles.menu}>
                                <HeaderNavLink
                                    href={Routes.Main}
                                    name={'Главная'}
                                    isActive={location.pathname === Routes.Main}
                                    notificationsAmount={0}
                                />
                                {canViewNotificationsPage && (
                                    <HeaderNavLink
                                        href={Routes.Notifications}
                                        name={'Уведомления'}
                                        isActive={location.pathname === Routes.Notifications}
                                        notificationsAmount={0}
                                    />
                                )}
                                {canViewRisksPage && (
                                    <HeaderNavLink
                                        href={Routes.Risks}
                                        name={'Риски'}
                                        isActive={location.pathname === Routes.Risks}
                                        notificationsAmount={0}
                                    />
                                )}
                                {canViewEventsPage && (
                                    <HeaderNavLink
                                        href={Routes.Events}
                                        name={'Мероприятия'}
                                        isActive={location.pathname === Routes.Events}
                                        notificationsAmount={0}
                                    />
                                )}
                                {canViewRequirementsPage && (
                                    <HeaderNavLink
                                        href={Routes.Requirements}
                                        name={'Требования'}
                                        isActive={location.pathname === Routes.Requirements}
                                        notificationsAmount={0}
                                    />
                                )}
                                <HeaderNavLink
                                    href={Routes.Search}
                                    name={'Поиск'}
                                    isActive={location.pathname === Routes.Search}
                                    notificationsAmount={0}
                                />
                                {canViewTasksPage && (
                                    <HeaderNavLink
                                        href={Routes.Tasks}
                                        name={'Задачи'}
                                        isActive={location.pathname === Routes.Tasks}
                                        notificationsAmount={user?.tasksCount || 0}
                                    />
                                )}
                                <HeaderNavLink
                                    href={Routes.FAQ}
                                    name={'База знаний'}
                                    isActive={location.pathname === Routes.FAQ}
                                    notificationsAmount={0}
                                />
                                {canViewReportsPage && (
                                    <HeaderNavLink
                                        href={Routes.Reports}
                                        name={'Отчёты'}
                                        isActive={location.pathname === Routes.Reports}
                                        notificationsAmount={0}
                                    />
                                )}
                                {canViewUsersPage && (
                                    <HeaderNavLink
                                        href={Routes.Users}
                                        name={'Пользователи'}
                                        isActive={location.pathname === Routes.Users}
                                        notificationsAmount={0}
                                    />
                                )}
                            </div>
                            <div className={styles.user}>
                                <div className={styles.user__info}>
                                    <span>
                                        {user?.name} {user?.patronymic} {user?.surname}
                                    </span>
                                    <span className={styles.user__role}>{user?.jobTitle}</span>
                                </div>
                            </div>
                            <div>
                                <IconButton onClick={handleClick}>
                                    <MoreVertIcon style={{ color: 'white' }} />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={onLogOut}>Выход</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export { Header };
