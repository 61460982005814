import { FC } from 'react';
import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Routes } from 'navigation/routes';
import { AppContainer } from 'components/common/appContainer';
import { useAppStore } from 'core/store/app/useAppStore';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useCreateNotification } from 'hooks/useCreateNotification';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { NotificationFormType } from 'types/notifications/NotificationFormType';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import { NotificationFormDefaultValues } from 'core/constants/forms/NotificationFormDefaultValues';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import styles from './CreateNotification.module.scss';

const CreateNotification: FC = () => {
    const navigate = useNavigate();
    const { createNotification } = useCreateNotification();
    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { dictionaryRiskObjectsInputOptions, dictionaryPrioritiesInputOptions } = useAdditionalData();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: NotificationFormDefaultValues,
        shouldUseNativeValidation: false,
    });

    const onSubmit: SubmitHandler<NotificationFormType> = (data) => {
        createNotification(data);
        setErrorAlertText('');
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCancel = (): void => {
        navigate(Routes.Notifications);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.create}>
                <div className={styles.container}>
                    <h2>Создание нового уведомления</h2>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} mb={'32px'}>
                            <ControlledTextInput
                                name={'name'}
                                control={control}
                                label={'Наименование уведомления'}
                                error={!!errors.name}
                                required
                            />
                            <ControlledTextInput
                                name={'reason'}
                                control={control}
                                label={'Причина риска'}
                                error={!!errors.reason}
                                required
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Объект воздействия'}
                                name={'riskObjectId'}
                                error={!!errors.riskObjectId}
                                options={dictionaryRiskObjectsInputOptions}
                                required
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Приоритет'}
                                name={'priorityId'}
                                error={!!errors.priorityId}
                                options={dictionaryPrioritiesInputOptions}
                                required
                            />
                            <ControlledTextInput
                                name={'riskRequirements'}
                                control={control}
                                label={'Требования к рискам'}
                                error={!!errors.riskRequirements}
                                required
                            />
                            <ControlledDateInput
                                control={control}
                                label={'Время обнаружения'}
                                name={'detectionDate'}
                                error={!!errors.detectionDate}
                                required
                            />
                            <ControlledTextInput
                                name={'impactDescription'}
                                control={control}
                                label={'Описание воздействия'}
                                error={!!errors.impactDescription}
                                required
                            />
                            <ControlledTextInput
                                name={'riskLocation'}
                                control={control}
                                label={'Местоположение риска'}
                                error={!!errors.riskLocation}
                                required
                            />
                            <ControlledTextInput
                                name={'responseTime'}
                                control={control}
                                label={'Сроки реагирования'}
                                error={!!errors.responseTime}
                                required
                            />
                            <ControlledTextInput
                                name={'comment'}
                                control={control}
                                label={'Комментарий'}
                                error={!!errors.comment}
                                multiline
                            />

                            <div className={styles.buttons}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={onSave}>
                                        Сохранить уведомление
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </div>
                        </Stack>
                    </form>
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { CreateNotification };
