import { useEffect, useMemo } from 'react';

import { axiosWithAuthHeader } from 'core/http';
import {
    DICTIONARY_CLASS,
    DICTIONARY_DEPARTMENTS,
    DICTIONARY_LIFECYCLE_STAGE,
    DICTIONARY_PRIORITY,
    DICTIONARY_RISK_OBJECT,
    DICTIONARY_RISK_STATUS,
    DICTIONARY_RISK_SYSTEM,
    DICTIONARY_ROLE,
    DICTIONARY_SOURCE_OF_RISK_DATA,
    DICTIONARY_STAFFING_TABLE,
    NOTIFICATION_STATUS,
} from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';
import { useUserStore } from 'core/store/user/useUserStore';

const useAdditionalData = () => {
    const {
        setNotificationStatuses,
        dictionaryPriorities,
        setDictionaryPriorities,
        notificationStatuses,
        setDictionaryUserRoles,
        dictionaryUserRoles,
        setDictionaryDepartments,
        dictionaryDepartments,
        setSourceOfRiskData,
        sourceOfRiskData,
        setDictionaryClasses,
        dictionaryClasses,
        setDictionaryLifecycleStages,
        dictionaryLifecycleStages,
        dictionaryRiskSystem,
        setDictionaryRiskSystem,
        dictionaryStaffingTable,
        setDictionaryStaffingTable,
        setDictionaryRiskObjects,
        dictionaryRiskObjects,
        riskStatuses,
        setRiskStatuses,
    } = useAppStore((state) => state);
    const { accessToken } = useUserStore((state) => state);

    useEffect(() => {
        if (accessToken) {
            getNotificationStatuses().catch((error) => console.log(NOTIFICATION_STATUS, error));
            getPriorities().catch((error) => console.log(DICTIONARY_PRIORITY, error));
            getRoles().catch((error) => console.log(DICTIONARY_ROLE, error));
            getDepartments().catch((error) => console.log(DICTIONARY_DEPARTMENTS, error));
            getSourceOfRiskData().catch((error) => console.log(DICTIONARY_SOURCE_OF_RISK_DATA, error));
            getDictionaryClass().catch((error) => console.log(DICTIONARY_CLASS, error));
            getDictionaryLifecycleStage().catch((error) => console.log(DICTIONARY_LIFECYCLE_STAGE, error));
            getDictionaryRiskSystem().catch((error) => console.log(DICTIONARY_RISK_SYSTEM, error));
            getDictionaryStaffingTable().catch((error) => console.log(DICTIONARY_STAFFING_TABLE, error));
            getDictionaryRiskObject().catch((error) => console.log(DICTIONARY_RISK_OBJECT, error));
            getRiskStatuses().catch((error) => console.log(DICTIONARY_RISK_STATUS, error));
        }
    }, [accessToken]);

    const getNotificationStatuses = async () => {
        const result = await axiosWithAuthHeader.get(NOTIFICATION_STATUS);
        const statuses = result.data as IBaseDictionaryEntity[];
        setNotificationStatuses(statuses);
    };

    const getRiskStatuses = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_RISK_STATUS);
        const statuses = result.data as IBaseDictionaryEntity[];
        setRiskStatuses(statuses);
    };

    const getPriorities = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_PRIORITY);
        const priorities = result.data as IBaseDictionaryEntity[];
        setDictionaryPriorities(priorities);
    };

    const getRoles = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_ROLE);
        const roles = result.data as IBaseDictionaryEntity[];
        setDictionaryUserRoles(roles);
    };

    const getDepartments = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_DEPARTMENTS);
        const roles = result.data as IBaseDictionaryEntity[];
        setDictionaryDepartments(roles);
    };

    const getSourceOfRiskData = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_SOURCE_OF_RISK_DATA);
        const sourceOfRisk = result.data as IBaseDictionaryEntity[];
        setSourceOfRiskData(sourceOfRisk);
    };

    const getDictionaryClass = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_CLASS);
        const classes = result.data as IBaseDictionaryEntity[];
        setDictionaryClasses(classes);
    };

    const getDictionaryLifecycleStage = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_LIFECYCLE_STAGE);
        const stages = result.data as IBaseDictionaryEntity[];
        setDictionaryLifecycleStages(stages);
    };

    const getDictionaryRiskSystem = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_RISK_SYSTEM);
        const systems = result.data as IBaseDictionaryEntity[];
        setDictionaryRiskSystem(systems);
    };

    const getDictionaryStaffingTable = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_STAFFING_TABLE);
        const systems = result.data as IBaseDictionaryEntity[];
        setDictionaryStaffingTable(systems);
    };

    const getDictionaryRiskObject = async () => {
        const result = await axiosWithAuthHeader.get(DICTIONARY_RISK_OBJECT);
        const objects = result.data as IBaseDictionaryEntity[];
        setDictionaryRiskObjects(objects);
    };

    const dictionaryPrioritiesInputOptions = useMemo(() => {
        return dictionaryPriorities.map((status) => ({
            value: status.id,
            label: status.name,
        }));
    }, [dictionaryPriorities]);

    const notificationStatusesInputOptions = useMemo(() => {
        return notificationStatuses.map((status) => ({
            value: status.id,
            label: status.name,
        }));
    }, [notificationStatuses]);

    const userRolesInputOptions = useMemo(() => {
        return dictionaryUserRoles.map((role) => ({
            value: role.id,
            label: role.name,
        }));
    }, [dictionaryUserRoles]);

    const userDepartmentsInputOptions = useMemo(() => {
        return dictionaryDepartments.map((department) => ({
            value: department.id,
            label: department.name,
        }));
    }, [dictionaryDepartments]);

    const sourceOfRiskDataInputOptions = useMemo(() => {
        return sourceOfRiskData.map((source) => ({
            value: source.id,
            label: source.name,
        }));
    }, [sourceOfRiskData]);

    const dictionaryClassesInputOptions = useMemo(() => {
        return dictionaryClasses.map((dictionaryClass) => ({
            value: dictionaryClass.id,
            label: dictionaryClass.name,
        }));
    }, [dictionaryClasses]);

    const dictionaryLifecycleStagesInputOptions = useMemo(() => {
        return dictionaryLifecycleStages.map((stage) => ({
            value: stage.id,
            label: stage.name,
        }));
    }, [dictionaryLifecycleStages]);

    const dictionaryRiskSystemInputOptions = useMemo(() => {
        return dictionaryRiskSystem.map((system) => ({
            value: system.id,
            label: system.name,
        }));
    }, [dictionaryRiskSystem]);

    const dictionaryStaffingTableInputOptions = useMemo(() => {
        return dictionaryStaffingTable.map((system) => ({
            value: system.id,
            label: system.name,
        }));
    }, [dictionaryStaffingTable]);

    const dictionaryRiskObjectsInputOptions = useMemo(() => {
        return dictionaryRiskObjects.map((obj) => ({
            value: obj.id,
            label: obj.name,
        }));
    }, [dictionaryRiskObjects]);

    const dictionaryRiskStatusesInputOptions = useMemo(() => {
        return riskStatuses.map((obj) => ({
            value: obj.id,
            label: obj.name,
        }));
    }, [riskStatuses]);

    return {
        dictionaryRiskObjectsInputOptions,
        dictionaryStaffingTableInputOptions,
        dictionaryClassesInputOptions,
        userRolesInputOptions,
        dictionaryRiskSystemInputOptions,
        userDepartmentsInputOptions,
        notificationStatusesInputOptions,
        dictionaryPrioritiesInputOptions,
        sourceOfRiskDataInputOptions,
        dictionaryLifecycleStagesInputOptions,
        dictionaryRiskStatusesInputOptions,
    };
};

export { useAdditionalData };
