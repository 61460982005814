import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useMemo } from 'react';

import { tabProps, UITabPanel } from 'components/UI/UITabs';
import { AppContainer } from 'components/common/appContainer';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { RisksReports } from './components/RisksReports';
import { EventsReports } from './components/EventsReports';
import { RequirementsReports } from './components/RequirementsReports';

const Reports = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs = useMemo(() => {
        return [
            { title: 'Риски', component: <RisksReports /> },
            { title: 'Мероприятия', component: <EventsReports /> },
            { title: 'Требования', component: <RequirementsReports /> },
        ];
    }, []);

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.reports.view}>
                <h2>Отчёты</h2>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.title} {...tabProps(index)} />
                        ))}
                    </Tabs>
                </Box>
                {tabs.map((tab, index) => (
                    <UITabPanel key={index} value={value} index={index}>
                        {tab.component}
                    </UITabPanel>
                ))}
            </UserRightsContainer>
        </AppContainer>
    );
};

export { Reports };
