import { FC } from 'react';
import * as React from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Routes } from 'navigation/routes';
import { AppContainer } from 'components/common/appContainer';
import { useViewNotification } from 'hooks/useViewNotification';
import { UILoader } from 'components/UI/UILoader';
import { ViewNotificationComponent } from 'components/common/ViewNotificaionComponent';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import styles from './ViewNotification.module.scss';

const ViewNotification: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { notification } = useViewNotification(id);

    const onCancel = (): void => {
        navigate(Routes.Notifications);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.view}>
                <div className={styles.container}>
                    {notification ? (
                        <>
                            <h2>Просмотр уведомления</h2>
                            <Paper elevation={3} className={styles.fields}>
                                <ViewNotificationComponent notification={notification} showAll />
                                <Button variant="outlined" onClick={onCancel}>
                                    Назад
                                </Button>
                            </Paper>
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ViewNotification };
