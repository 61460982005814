import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Dayjs } from 'dayjs';

export const UsersFilterDefaultValues = {
    name: '',
    role: '',
    department: '',
    jobTitle: '',
    email: '',
    login: '',
};

export type UseUsersStoreType = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    paginationPage: number;
    filter: {
        name: string;
        role: string;
        department: string;
        jobTitle: string;
        email: string;
        login: string;
    };

    setPaginationPage: (page: number) => void;
    resetFilter: () => void;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setFilter: (key: string, value: string) => void;
};

const useUsersStore = create<UseUsersStoreType>()(
    persist(
        (set, get) => ({
            dateFrom: null,
            dateTo: null,
            detectionDate: null,
            filter: UsersFilterDefaultValues,
            paginationPage: 0,

            setPaginationPage: (page) => set({ paginationPage: page }),
            setDateFrom: (date) => set({ dateFrom: date }),
            setDateTo: (date) => set({ dateTo: date }),
            setFilter: (key: string, value: string) => {
                const filter = get().filter;
                set({ filter: { ...filter, [key]: value } });
            },
            resetFilter: () => set({ filter: UsersFilterDefaultValues }),
        }),
        {
            name: 'users-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useUsersStore };
