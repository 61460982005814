import { AxiosResponse } from 'axios';

import { axiosWithAuthHeader } from '../http';
import {
    APPOINT_RISK_OWNER,
    APPROVE_BY_COORDINATOR,
    APPROVE_RISK_ASSESSMENT,
    ASSESS_RISK,
    CHANGE_RISK_OWNER,
    REJECT,
    RISKS,
    SEND_ASSESSMENT_FOR_APPROVAL,
    SEND_ASSESSMENT_FOR_REVISION,
    SEND_FOR_REVISION,
    SEND_FOR_REVISION_BY_COORDINATOR,
} from '../http/endpoints';

// eslint-disable-next-line
type CallbackType = (res: AxiosResponse<any>) => void;

interface RiskAssessmentType {
    riskAssessmentId: string;
    minimalScheduleChange: string;
    expectedScheduleChange: string;
    maximumScheduleChange: string;
    minimalDamageCost: string;
    expectedDamageCost: string;
    maximumDamageCost: string;
    riskProbability: string;
    riskAcceptanceCriterion: string;
    rank: string;
}

export const risksService = {
    getRisk: (id: string | number, successCallback: CallbackType) => {
        axiosWithAuthHeader
            .get(`${RISKS}/${id}`)
            .then(successCallback)
            .catch((error) => console.log(error));
    },
    rejectRisk: (id: number, reason: string, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader.patch(`${RISKS}/${id}${REJECT}`, { reason }).then(successCallback).catch(errorCallback);
    },
    sendRiskForRevision: (id: number, reason: string, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${SEND_FOR_REVISION}`, { reason })
            .then(successCallback)
            .catch(errorCallback);
    },
    appointRiskOwner: (id: string, userId: string, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${APPOINT_RISK_OWNER}`, { userId })
            .then(successCallback)
            .catch(errorCallback);
    },
    sendAssessmentForApproval: (
        id: string,
        data: RiskAssessmentType,
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${SEND_ASSESSMENT_FOR_APPROVAL}`, data)
            .then(successCallback)
            .catch(errorCallback);
    },
    assessRisk: (id: string, data: RiskAssessmentType, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader.patch(`${RISKS}/${id}${ASSESS_RISK}`, data).then(successCallback).catch(errorCallback);
    },
    approveRiskAssessment: (id: string, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${APPROVE_RISK_ASSESSMENT}`)
            .then(successCallback)
            .catch(errorCallback);
    },
    sendAssessmentForRevision: (
        id: string,
        data: RiskAssessmentType & { assessmentRevisionComment: string },
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${SEND_ASSESSMENT_FOR_REVISION}`, data)
            .then(successCallback)
            .catch(errorCallback);
    },
    changeRiskOwner: (
        id: string,
        data: RiskAssessmentType & { assessmentRevisionComment: string; riskOwnerId: string },
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${CHANGE_RISK_OWNER}`, data)
            .then(successCallback)
            .catch(errorCallback);
    },
    sendForRevisionByCoordinator: (
        id: string,
        data: RiskAssessmentType & { assessmentRevisionComment: string },
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${id}${SEND_FOR_REVISION_BY_COORDINATOR}`, data)
            .then(successCallback)
            .catch(errorCallback);
    },
    approveByCoordinator: (id: string, successCallback: CallbackType, errorCallback: CallbackType) => {
        axiosWithAuthHeader.patch(`${RISKS}/${id}${APPROVE_BY_COORDINATOR}`).then(successCallback).catch(errorCallback);
    },
};
