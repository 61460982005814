import { FC, useEffect } from 'react';
import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import dayjs from 'dayjs';

import { Routes } from 'navigation/routes';
import { AppContainer } from 'components/common/appContainer';
import { useAppStore } from 'core/store/app/useAppStore';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useEditNotification } from 'hooks/useEditNotification';
import { UILoader } from 'components/UI/UILoader';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { NotificationFormType } from 'types/notifications/NotificationFormType';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import styles from './EditNotification.module.scss';

const EditNotification: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { isFetching, notification, editNotification } = useEditNotification(id);
    const { dictionaryRiskObjectsInputOptions, dictionaryPrioritiesInputOptions } = useAdditionalData();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            name: '',
            reason: '',
            riskObjectId: '',
            riskRequirements: '',
            detectionDate: dayjs(),
            impactDescription: '',
            riskLocation: '',
            responseTime: '',
            comment: '',
            priorityId: '',
        },
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (notification) {
            setValue('name', notification.notificationName);
            setValue('reason', notification.riskReason);
            setValue('riskObjectId', notification.riskObject?.id.toString());
            setValue('priorityId', notification.priority?.id.toString());
            setValue('riskRequirements', notification.riskRequirements);
            setValue('detectionDate', dayjs(notification.detectionDate, 'DD.MM.YYYY'));
            setValue('impactDescription', notification.impactDescription);
            setValue('riskLocation', notification.riskLocation);
            setValue('responseTime', notification.responseTime);
            setValue('comment', notification.comment);
        }
    }, [notification, setValue]);

    const onSubmit: SubmitHandler<NotificationFormType> = (data) => {
        setErrorAlertText('');
        editNotification(data);
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCancel = (): void => {
        navigate(Routes.Notifications);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.edit}>
                <div className={styles.container}>
                    <h2>Редактирование уведомления</h2>
                    <UILoader isVisible={isFetching} />

                    {!!notification && (
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2} mb={'32px'}>
                                <ControlledTextInput
                                    name={'name'}
                                    control={control}
                                    label={'Наименование уведомления'}
                                    error={!!errors.name}
                                    required
                                />
                                <ControlledTextInput
                                    name={'reason'}
                                    control={control}
                                    label={'Причина риска'}
                                    error={!!errors.reason}
                                    required
                                />
                                <ControlledSelectInput
                                    control={control}
                                    label={'Объект воздействия'}
                                    name={'riskObjectId'}
                                    error={!!errors.riskObjectId}
                                    options={dictionaryRiskObjectsInputOptions}
                                    required
                                />
                                <ControlledSelectInput
                                    control={control}
                                    label={'Приоритет'}
                                    name={'priorityId'}
                                    error={!!errors.priorityId}
                                    options={dictionaryPrioritiesInputOptions}
                                    required
                                />
                                <ControlledTextInput
                                    name={'riskRequirements'}
                                    control={control}
                                    label={'Требования к рискам'}
                                    error={!!errors.riskRequirements}
                                    required
                                />
                                <ControlledDateInput
                                    control={control}
                                    label={'Время обнаружения'}
                                    name={'detectionDate'}
                                    error={!!errors.detectionDate}
                                    required
                                />
                                <ControlledTextInput
                                    name={'impactDescription'}
                                    control={control}
                                    label={'Описание воздействия'}
                                    error={!!errors.impactDescription}
                                    required
                                />
                                <ControlledTextInput
                                    name={'riskLocation'}
                                    control={control}
                                    label={'Местоположение риска'}
                                    error={!!errors.riskLocation}
                                    required
                                />
                                <ControlledTextInput
                                    name={'responseTime'}
                                    control={control}
                                    label={'Сроки реагирования'}
                                    error={!!errors.responseTime}
                                    required
                                />
                                <ControlledTextInput
                                    name={'comment'}
                                    control={control}
                                    label={'Комментарий'}
                                    error={!!errors.comment}
                                    multiline
                                />

                                <div className={styles.buttons}>
                                    <Box mr={'10px'}>
                                        <Button variant="contained" type={'submit'} onClick={onSave}>
                                            Сохранить уведомление
                                        </Button>
                                    </Box>
                                    <Box mr={'10px'}>
                                        <Button variant="outlined" onClick={onCancel}>
                                            Назад
                                        </Button>
                                    </Box>
                                </div>
                            </Stack>
                        </form>
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { EditNotification };
