import * as React from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useMemo } from 'react';

import { useRequirements } from 'hooks/useRequirements';
import { UITable } from 'components/UI/UITable';
import { Routes } from 'navigation/routes';
import { useRequirementsPermissions } from 'hooks/useRequirementsPermissions';
import { RequirementStatus } from 'core/constants/RequirementStatus';
import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { RequirementsTableColumns } from 'core/constants/tables/RequirementsTableColumns';
import { RequirementsFilter } from './components/RequirementsFilter';

type Props = {
    renderButtons?: boolean;
    renderFilter?: boolean;
};

const Requirements: FC<Props> = ({ renderButtons, renderFilter }) => {
    const { setOriginUrl } = useAsyncAppStore();
    const navigate = useNavigate();

    const {
        tableRows,
        isFetching,
        onFilter,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        control,
        onReset,
        setFilter,
        requirements,
        exportCSVRequirements,
        setPaginationPage,
        totalCount,
    } = useRequirements();
    const {
        canCreateRequirement,
        canEditRequirement,
        canSendOnApproval,
        canApprove,
        canConfirm,
        canEditRequirementFromApproved,
    } = useRequirementsPermissions();

    useEffect(() => {
        setOriginUrl(Routes.Requirements);
    }, []);

    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    const onCreateRequirement = (): void => {
        navigate(Routes.CreateRequirement);
    };

    const onEditRequirement = (): void => {
        navigate(`${Routes.EditRequirement}/${selectedRows[0]}`);
    };

    const onSendForApproval = (): void => {
        navigate(`${Routes.SendRequirementForApproval}/${selectedRows[0]}`);
    };

    const onApprove = (): void => {
        navigate(`${Routes.ApproveRequirement}/${selectedRows[0]}`);
    };

    const onConfirm = (): void => {
        navigate(`${Routes.ConfirmRequirement}/${selectedRows[0]}`);
    };

    const onViewRequirement = (): void => {
        navigate(`${Routes.ViewRequirement}/${selectedRows[0]}`);
    };

    const selectedRequirement = useMemo(() => {
        return requirements.find((n) => n.id === selectedRows[0]);
    }, [selectedRows[0]]);

    return (
        <UserRightsContainer allowedUserRoles={userRolesPermissions.requirements.view}>
            {renderFilter && (
                <>
                    <h2>Требования</h2>
                    <RequirementsFilter
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        control={control}
                        setDateTo={setDateTo}
                        setDateFrom={setDateFrom}
                        onReset={onReset}
                        onSubmit={onFilter}
                        onChangeFilter={setFilter}
                    />
                </>
            )}

            <div style={{ display: 'flex' }}>
                {renderButtons && (
                    <>
                        {canCreateRequirement && (
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onCreateRequirement}>
                                    Создать новое требование
                                </Button>
                            </Box>
                        )}
                        {canEditRequirement && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedRequirement ||
                                        selectedRequirement?.requirementStatus?.sysName !== RequirementStatus.Draft
                                    }
                                    onClick={onEditRequirement}
                                >
                                    Редактировать
                                </Button>
                            </Box>
                        )}
                        {canEditRequirementFromApproved && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedRequirement ||
                                        selectedRequirement?.requirementStatus?.sysName !== RequirementStatus.Approved
                                    }
                                    onClick={onEditRequirement}
                                >
                                    Редактировать
                                </Button>
                            </Box>
                        )}
                        {canSendOnApproval && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedRequirement ||
                                        selectedRequirement?.requirementStatus?.sysName !== RequirementStatus.Draft
                                    }
                                    onClick={onSendForApproval}
                                >
                                    Отправить на согласование
                                </Button>
                            </Box>
                        )}
                        {canApprove && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedRequirement ||
                                        selectedRequirement?.requirementStatus?.sysName !==
                                            RequirementStatus.WaitsApproval
                                    }
                                    onClick={onApprove}
                                >
                                    Принять решение
                                </Button>
                            </Box>
                        )}
                        {canConfirm && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedRequirement ||
                                        selectedRequirement?.requirementStatus?.sysName !== RequirementStatus.Approved
                                    }
                                    onClick={onConfirm}
                                >
                                    Утвердить требование
                                </Button>
                            </Box>
                        )}
                    </>
                )}
                <Box mr={'10px'}>
                    <Button variant="outlined" disabled={selectedRows.length === 0} onClick={onViewRequirement}>
                        Просмотр
                    </Button>
                </Box>
                {renderButtons && (
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={exportCSVRequirements}>
                            Выгрузить CSV файл
                        </Button>
                    </Box>
                )}
            </div>

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет требований'}
                rows={tableRows}
                columns={RequirementsTableColumns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                onChangePaginationPage={setPaginationPage}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
            />
        </UserRightsContainer>
    );
};

export { Requirements };
