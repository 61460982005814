import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';

import { axiosWithAuthHeader } from 'core/http';
import { NOTIFICATIONS, NOTIFICATIONS_CSV } from 'core/http/endpoints';
import { NotificationType } from 'types/notifications/NotificationType';
import { useNotificationsStore } from 'core/store/notifications/useNotificationsStore';
import { b64toBlob } from 'utils/b64toBlob';
import { downloadFile } from 'utils/downloadFile';
import { CSVRequestType } from 'types/csv/CSVRequestType';
import { PaginationType } from 'types/PaginationType';
import { userFullNameFromUser } from '../utils/userFullNameFromUser';

const useNotifications = (filterItems: boolean) => {
    const {
        dateFrom,
        dateTo,
        setDateTo,
        setDateFrom,
        filter,
        setFilter,
        detectionDate,
        resetFilter,
        setDetectionDate,
        setPaginationPage,
        paginationPage,
    } = useNotificationsStore((state) => state);

    const { control, reset, getValues, setValue } = useForm({
        defaultValues: {
            name: '',
            reason: '',
            category: '',
            informationSource: '',
            possibleConsequences: '',
            riskLocation: '',
            comment: '',
            responseTime: '',
            author: '',
            state: '',
            priority: '',
            detectionDate: null as Dayjs | null,
        },
        shouldUseNativeValidation: false,
    });

    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setValue('name', filter.name);
        setValue('reason', filter.reason);
        setValue('category', filter.category);
        setValue('informationSource', filter.informationSource);
        setValue('possibleConsequences', filter.possibleConsequences);
        setValue('riskLocation', filter.riskLocation);
        setValue('comment', filter.comment);
        setValue('responseTime', filter.responseTime);
        setValue('author', filter.author);
        setValue('state', filter.state);
        setValue('priority', filter.priority);
        setValue('detectionDate', detectionDate ? dayjs(detectionDate) : null);
    }, []);

    useEffect(() => {
        setIsFetching(true);
        fetchNotifications();
    }, [dateFrom, dateTo, paginationPage]);

    const onFilter = () => {
        fetchNotifications();
    };

    const getFilterParams = () => {
        const formData = getValues();

        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            ...(formData.detectionDate && { detectionDate: formData.detectionDate?.format('YYYY-MM-DD HH:mm:ss') }),
            ...(formData.responseTime && { responseTime: formData.responseTime }),
            ...(formData.riskLocation && { location: formData.riskLocation }),
            ...(formData.possibleConsequences && { impactDescription: formData.possibleConsequences }),
            ...(formData.informationSource && { riskRequirements: formData.informationSource }),
            statusId: formData.state,
            userSearch: formData.author,
            riskObjectId: formData.category,
            priorityId: formData.priority,
            reason: formData.reason,
            name: formData.name,
        };

        return filterItems ? params : { statusId: '3' };
    };

    const fetchNotifications = () => {
        axiosWithAuthHeader
            .get<{ data: NotificationType[]; pagination: PaginationType }>(NOTIFICATIONS, {
                params: { ...getFilterParams(), page: paginationPage },
            })
            .then((res) => {
                setNotifications(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const exportCSVNotifications = () => {
        axiosWithAuthHeader
            .get<CSVRequestType>(NOTIFICATIONS_CSV, { params: getFilterParams() })
            .then((res) => {
                const blob = b64toBlob(res.data.data, res.data.mimeType);
                downloadFile(blob, res.data.name);
            })
            .catch((error) => console.log(error));
    };

    const onReset = () => {
        reset();
        resetFilter();
        fetchNotifications();
    };

    const tableRows = useMemo(() => {
        return notifications?.map((notification) => ({
            id: notification.id,
            name: notification.notificationName,
            reason: notification.riskReason,
            category: notification.riskObject.name,
            informationSource: notification.riskRequirements,
            detectionDate: notification.detectionDate,
            possibleConsequences: notification.impactDescription,
            riskLocation: notification.riskLocation,
            responseTime: notification.responseTime,
            creatorName: userFullNameFromUser(notification.sender),
            createdAt: notification.createDate,
            status: notification.status?.name,
            priority: notification?.priority?.name || '',
        }));
    }, [notifications]);

    return {
        notifications,
        tableRows,
        isFetching,
        onFilter,
        dateFrom: dateFrom ? dayjs(dateFrom) : null,
        setDateFrom,
        dateTo: dateTo ? dayjs(dateTo) : null,
        setDateTo,
        control,
        onReset,
        setFilter,
        setDetectionDate,
        setPaginationPage,
        exportCSVNotifications,
        totalCount,
    };
};

export { useNotifications };
