import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { useRequirementsFilterStore } from 'core/store/requirements/useRequirementsFilterStore';
import { RequirementType } from 'types/requirements/RequirementType';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { axiosWithAuthHeader } from 'core/http';
import { REQUIREMENTS, REQUIREMENTS_CSV } from 'core/http/endpoints';
import { b64toBlob } from 'utils/b64toBlob';
import { downloadFile } from 'utils/downloadFile';
import { CSVRequestType } from 'types/csv/CSVRequestType';
import { PaginationType } from 'types/PaginationType';

const useRequirements = () => {
    const {
        dateFrom,
        dateTo,
        setDateTo,
        setDateFrom,
        filter,
        setFilter,
        resetFilter,
        setPaginationPage,
        paginationPage,
    } = useRequirementsFilterStore((state) => state);

    const { control, reset, setValue, getValues } = useForm({
        defaultValues: {
            text: '',
            author: '',
            statusId: '',
        },
        shouldUseNativeValidation: false,
    });

    const [requirements, setRequirements] = useState<RequirementType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setValue('text', filter.text);
        setValue('author', filter.author);
        setValue('statusId', filter.statusId);
    }, []);

    useEffect(() => {
        setIsFetching(true);
        fetchRequirements();
    }, [dateFrom, dateTo, paginationPage]);

    const onFilter = () => {
        fetchRequirements();
    };

    const getFilterParams = () => {
        const formData = getValues();

        return {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            ...(formData.statusId && { statusId: formData.statusId }),
            ...(formData.text && { text: formData.text }),
            ...(formData.author && { createdBy: formData.author }),
        };
    };

    const fetchRequirements = () => {
        axiosWithAuthHeader
            .get<{ data: RequirementType[]; pagination: PaginationType }>(REQUIREMENTS, {
                params: { ...getFilterParams(), page: paginationPage },
            })
            .then((res) => {
                setRequirements(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const exportCSVRequirements = () => {
        axiosWithAuthHeader
            .get<CSVRequestType>(REQUIREMENTS_CSV, { params: getFilterParams() })
            .then((res) => {
                const blob = b64toBlob(res.data.data, res.data.mimeType);
                downloadFile(blob, res.data.name);
            })
            .catch((error) => console.log(error));
    };

    const onReset = () => {
        reset();
        resetFilter();
        fetchRequirements();
    };

    const tableRows = useMemo(() => {
        return requirements?.map((requirement) => ({
            id: requirement.id,
            text: requirement.text,
            status: requirement.requirementStatus.name,
            createdBy: requirement.createdBy && userFullNameFromUser(requirement.createdBy),
            createdAt: requirement.createDate,
        }));
    }, [requirements]);

    return {
        requirements,
        tableRows,
        isFetching,
        onFilter,
        dateFrom: dateFrom ? dayjs(dateFrom) : null,
        setDateFrom,
        dateTo: dateTo ? dayjs(dateTo) : null,
        setDateTo,
        control,
        onReset,
        setFilter,
        exportCSVRequirements,
        setPaginationPage,
        totalCount,
    };
};

export { useRequirements };
