import { FC, useEffect, useMemo } from 'react';
import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';

import { AppContainer } from 'components/common/appContainer';
import { useAppStore } from 'core/store/app/useAppStore';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { EventFormType } from 'types/events/EventFormType';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useEventsAdditionalData } from 'hooks/useEventsAdditionalData';
import { useEditEvent } from 'hooks/useEditEvent';
import { Routes } from 'navigation/routes';
import { useEventsPermissions } from 'hooks/useEventsPermissions';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { useUsers } from 'hooks/useUsers';
import { UserRoles } from 'core/constants/UserRoles';
import { UserRightsContainer } from 'components/common/UserRightsContainer';

const EditEvent: FC = () => {
    const navigate = useNavigate();
    const { riskId, eventId } = useParams();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { dictionaryEventTypesInputOptions } = useEventsAdditionalData();
    const { users } = useUsers(UserRoles.responsible_for_measure_implementation);
    const { canCreateFromResponsibleAppointed, canCreateFromUnderRevisionByResponsible } = useEventsPermissions();
    const { editEvent, event, permittedUserRolesToEdit } = useEditEvent({ riskId, eventId });

    const expandForm = useMemo(() => {
        return canCreateFromResponsibleAppointed || canCreateFromUnderRevisionByResponsible;
    }, [canCreateFromResponsibleAppointed, canCreateFromUnderRevisionByResponsible]);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            eventText: '',
            eventTypeId: '',
            dueDate: null as Dayjs | null,
            controlDate: null as Dayjs | null,
            eventResponsible: { label: '', value: '' },
        },
        shouldUseNativeValidation: false,
    });

    const responsibleUsers = useMemo(() => {
        return users.map((user) => ({ label: userFullNameFromUser(user), value: user.id }));
    }, [users]);

    useEffect(() => {
        if (event) {
            setValue('eventTypeId', event.eventType.id.toString());
            setValue('eventText', event.eventText);
            if (event.responsible) {
                setValue('eventResponsible', {
                    label: userFullNameFromUser(event.responsible),
                    value: event.responsible.id.toString(),
                });
            }
            if (event.dueDate) {
                setValue('dueDate', dayjs(event.dueDate, 'DD.MM.YYYY'));
            }
            if (event.controlDate) {
                setValue('controlDate', dayjs(event.controlDate, 'DD.MM.YYYY'));
            }
        }
    }, [event]);

    const onSubmit: SubmitHandler<EventFormType> = (data) => {
        editEvent(data);
        setErrorAlertText('');
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCancel = (): void => {
        if (!riskId) return;
        navigate(`${Routes.RiskEvents}/${riskId}`);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={permittedUserRolesToEdit}>
                <div className={'container_thin'}>
                    <h2>Редактирование мероприятия</h2>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} mb={'32px'}>
                            <ControlledSelectInput
                                control={control}
                                label={'Тип мероприятия'}
                                name={'eventTypeId'}
                                error={!!errors.eventTypeId}
                                options={dictionaryEventTypesInputOptions}
                                required
                            />
                            <ControlledTextInput
                                name={'eventText'}
                                control={control}
                                label={'Наименование мероприятия'}
                                error={!!errors.eventText}
                                required
                                multiline
                            />
                            {expandForm && (
                                <>
                                    <ControlledAutocompleteInput
                                        control={control}
                                        label={'Ответственный за исполнение мероприятия'}
                                        name={'eventResponsible'}
                                        error={!!errors.eventResponsible}
                                        options={responsibleUsers}
                                        required
                                    />
                                    <ControlledDateInput
                                        control={control}
                                        label={'Срок выполнения (плановая дата)'}
                                        name={'dueDate'}
                                        error={!!errors.dueDate}
                                        required
                                    />
                                    <ControlledDateInput
                                        control={control}
                                        label={'Срок контроля мероприятия'}
                                        name={'controlDate'}
                                        error={!!errors.controlDate}
                                        required
                                    />
                                </>
                            )}
                            <div style={{ display: 'flex' }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={onSave}>
                                        Сохранить
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </div>
                        </Stack>
                    </form>
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { EditEvent };
