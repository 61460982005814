import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as React from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

import { axiosWithAuthHeader } from 'core/http';
import { DASHBOARD } from 'core/http/endpoints';
import { DashboardDataType } from 'types/dashboard/DashboardDataType';

const useDashboard = () => {
    const [period, setPeriod] = React.useState('this_month');
    const [dashboardData, setDashboardData] = useState<DashboardDataType>();

    const handleChangeFilter = (event: SelectChangeEvent) => {
        setPeriod(event.target.value);
    };

    useEffect(() => {
        axiosWithAuthHeader
            .get<DashboardDataType>(DASHBOARD, { params: { period } })
            .then((res) => {
                setDashboardData(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [period]);

    const selectOptions = useMemo(() => {
        const currentYear = dayjs().year();
        const now = dayjs();
        const lastDayOfPrevMonth = dayjs().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
        const firstDayOfPrevMonth = dayjs().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');

        return [
            {
                value: 'this_month',
                label: `Этот месяц: 01.${now.format('MM')}.${currentYear} - ${now.format('DD.MM.YYYY')}`,
            },
            { value: 'last_month', label: `Прошлый месяц: ${firstDayOfPrevMonth} - ${lastDayOfPrevMonth}` },
            { value: 'this_year', label: `Этот год: 01.01.${currentYear} - ${now.format('DD.MM.YYYY')}` },
            { value: 'last_year', label: `Прошлый год: 01.01.${currentYear - 1} - 31.12.${currentYear - 1}` },
        ];
    }, []);

    return {
        selectOptions,
        period,
        dashboardData,
        handleChangeFilter,
    };
};

export { useDashboard };
