import * as React from 'react';
import { FC } from 'react';
import { Link } from '@mui/material';

import { RiskType } from 'types/risks/RiskType';
import { MATRIX_LINK } from 'core/constants/common';

type Props = {
    risk: RiskType;
};

const RiskAssessmentInfo: FC<Props> = ({ risk }) => {
    return (
        <>
            <div className="fields-container">
                <span>Метод оценки риска:</span>
                <span>{risk.riskAssessmentMethod?.name}</span>
                <span>Изменение сроков минимальное:</span>
                <span>{risk.minimalScheduleChange}</span>
                <span>Изменение сроков ожидаемое:</span>
                <span>{risk.expectedScheduleChange}</span>
                <span>Изменение сроков максимальное:</span>
                <span>{risk.maximumScheduleChange}</span>
                <span>Стоимость ущерба минимальная:</span>
                <span>{risk.minimalDamageCost}</span>
                <span>Стоимость ущерба ожидаемая:</span>
                <span>{risk.expectedDamageCost}</span>
                <span>Стоимость ущерба максимальная:</span>
                <span>{risk.maximumDamageCost}</span>
                <span>Вероятность риска:</span>
                <span>{risk.riskProbability}%</span>
                <span>Критерий приемлемости риска:</span>
                <span>{risk.riskAcceptanceCriterion}</span>
                <strong>Результат количественной оценки (сроки):</strong>
                <span>{risk.riskAssessmentResultTime}</span>
                <strong>Результат количественной оценки (стоимость):</strong>
                <span>{risk.riskAssessmentResultMoney}</span>
                <strong>Ранг риска в порядке возрастания:</strong>
                <span>{risk.rank}</span>
                <Link href={MATRIX_LINK} target={'_blank'}>
                    Открыть матрицу
                </Link>
            </div>
        </>
    );
};

export { RiskAssessmentInfo };
