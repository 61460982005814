import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { NotificationType } from 'types/notifications/NotificationType';
import { notificationsService } from 'core/services/notificationsService';
import { axiosWithAuthHeader } from 'core/http';
import { RISKS } from 'core/http/endpoints';
import { RiskType } from 'types/risks/RiskType';
import { PaginationType } from 'types/PaginationType';
import { SearchRisksFilterDefaultValues, useSearchRisksStore } from 'core/store/risks/useSearchRisksStore';

const useSearchRisksByNotification = (notificationId?: string) => {
    const [notification, setNotification] = useState<NotificationType>();
    const [risks, setRisks] = useState<RiskType[]>([]);
    const [isFetchingRisks, setIsFetchingRisks] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { filter, setFilter, resetFilter, setPaginationPage, paginationPage } = useSearchRisksStore((state) => state);

    const { control, getValues, reset, setValue } = useForm({
        defaultValues: SearchRisksFilterDefaultValues,
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (!notificationId) return;

        getNotification(notificationId);
        setValue('name', filter.name);
        setValue('riskReason', filter.riskReason);
        setValue('objectOfInfluence', filter.objectOfInfluence);
        setValue('riskRequirements', filter.riskRequirements);
        setValue('impactDescription', filter.impactDescription);
        setValue('comment', filter.comment);
        setValue('includeQualificationRisks', filter.includeQualificationRisks);
        setValue('sourceOfOrigin', filter.sourceOfOrigin);
        setValue('riskClassification', filter.riskClassification);
        setValue('lifeCycleStage', filter.lifeCycleStage);
        setValue('systemSubsystemNode', filter.systemSubsystemNode);
        setValue('staffingSchedule', filter.staffingSchedule);
    }, []);

    useEffect(() => {
        if (!notification) return;

        onSearchRisks();
    }, [notification]);

    const getNotification = (id: string | number) => {
        notificationsService.getNotification(id, (res) => {
            setNotification(res.data as NotificationType);
        });
    };

    const onSearchRisks = () => {
        if (!notification) return;
        const formData = getValues();

        const params = {
            ...(formData.name && { name: notification.notificationName }),
            ...(formData.riskReason && { reason: notification.riskReason }),
            ...(formData.objectOfInfluence && { objectId: notification.riskObject.id }),
            ...(formData.riskRequirements && { requirements: notification.riskRequirements }),
            ...(formData.impactDescription && { description: notification.impactDescription }),
            ...(formData.comment && { comment: notification.comment }),
            includeQualificationRisks: formData.includeQualificationRisks,
            sourceId: formData.sourceOfOrigin,
            classId: formData.riskClassification,
            lifecycleId: formData.lifeCycleStage,
            systemId: formData.systemSubsystemNode,
            staffId: formData.staffingSchedule,
            page: paginationPage,
        };

        setIsFetchingRisks(true);
        axiosWithAuthHeader
            .get<{ data: RiskType[]; pagination: PaginationType }>(RISKS, { params })
            .then((res) => {
                setRisks(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetchingRisks(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onReset = () => {
        reset();
        resetFilter();
    };

    const tableRows = useMemo(() => {
        return risks.map((risk) => ({
            id: risk.id,
            shortName: risk.shortName,
            sourceOfRisk: risk.sourceOfRiskData?.name,
            riskRequirements: risk.riskRequirements,
            riskClassification: risk.riskClass?.name,
            objectOfInfluence: risk.riskObject?.name,
            lifeCycleStage: risk.lifecycleStage?.name,
            systemSubsystemNode: risk.riskSystem?.name,
            staffingSchedule: risk.staffingTable?.name,
            reason: risk.reason,
            description: risk.riskImpactDescription,
            comment: risk.comment,
        }));
    }, [risks]);

    return {
        control,
        onReset,
        onSearchRisks,
        notification,
        tableRows,
        totalCount,
        setFilter,
        isFetchingRisks,
        setPaginationPage,
    };
};

export { useSearchRisksByNotification };
