import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { axiosWithAuthHeader } from 'core/http';
import { USERS } from 'core/http/endpoints';
import { UserType } from 'types/user/UserType';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';

const ViewUser: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [user, setUser] = useState<UserType>();

    useEffect(() => {
        if (!id) return;

        axiosWithAuthHeader
            .get(`${USERS}/${id}`)
            .then((res) => {
                setUser(res.data as UserType);
            })
            .catch((error) => console.log(error));
    }, []);

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.users.view}>
                <div className={'container_thin'}>
                    {user ? (
                        <>
                            <h2>Просмотр пользователя</h2>
                            <Paper elevation={3} style={{ padding: '16px' }}>
                                <div className="fields-container">
                                    <span>Имя:</span>
                                    <span>{userFullNameFromUser(user)}</span>
                                    <span>Роль:</span>
                                    <span>{user.userRoles.map((role) => role.name).join(', ')}</span>
                                    <span>Подразделение:</span>
                                    <span>{user.department?.name}</span>
                                    <span>Должность:</span>
                                    <span>{user.jobTitle}</span>
                                    <span>Email:</span>
                                    <span>{user.email}</span>
                                    <span>Логин:</span>
                                    <span>{user.login}</span>
                                    {!!user.externalUsername && (
                                        <>
                                            <span>Логин Kamotive:</span>
                                            <span>{user.externalUsername}</span>
                                        </>
                                    )}
                                </div>
                                <Button variant="outlined" onClick={onCancel}>
                                    Назад
                                </Button>
                            </Paper>
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ViewUser };
