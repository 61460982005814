import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect, useMemo } from 'react';

import { Routes } from 'navigation/routes';
import { UITable } from 'components/UI/UITable';
import { useEvents } from 'hooks/useEvents';
import { EventsTableColumns } from 'core/constants/tables/EventsTableColumns';
import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { useRequirementsPermissions } from 'hooks/useRequirementsPermissions';
import { EventStatus } from 'core/constants/EventStatus';
import { useEventsPermissions } from 'hooks/useEventsPermissions';
import { EventsFilter } from './components/EventsFilter';

type Props = {
    renderButtons?: boolean;
    renderFilter?: boolean;
};

const Events: FC<Props> = ({ renderButtons, renderFilter }) => {
    const { setOriginUrl } = useAsyncAppStore();
    const navigate = useNavigate();

    const {
        setFilter,
        events,
        tableRows,
        isFetching,
        onFilter,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        control,
        onReset,
        totalCount,
        setDueDate,
        setControlDate,
        exportCSVEvents,
        setPaginationPage,
    } = useEvents(!!renderFilter);
    const { canCreateRequirement } = useRequirementsPermissions();
    const { canEdit } = useEventsPermissions();

    useEffect(() => {
        setOriginUrl(Routes.Events);
    }, []);

    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    const selectedEvent = useMemo(() => {
        return events.find((n) => n.id === selectedRows[0]);
    }, [selectedRows]);

    const onCreateRequirement = (): void => {
        if (!selectedEvent) return;
        navigate(`${Routes.CreateRequirement}?eventId=${selectedRows[0]}&riskId=${selectedEvent.riskId}`);
    };

    const onEditEvent = (): void => {
        if (!selectedEvent) return;
        navigate(`${Routes.RiskEvents}/${selectedEvent.riskId}`);
    };

    const onViewEvent = (): void => {
        if (!selectedEvent) return;
        navigate(`${Routes.RiskEvents}/${selectedEvent.riskId}`);
    };

    return (
        <UserRightsContainer allowedUserRoles={userRolesPermissions.events.view}>
            {renderFilter && (
                <>
                    <h2>Мероприятия</h2>
                    <EventsFilter
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        control={control}
                        setDateTo={setDateTo}
                        setDateFrom={setDateFrom}
                        setDueDate={setDueDate}
                        setControlDate={setControlDate}
                        onChangeFilter={setFilter}
                        onSubmit={onFilter}
                        onReset={onReset}
                    />
                </>
            )}

            <div style={{ display: 'flex' }}>
                {renderButtons && (
                    <>
                        {canCreateRequirement && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedEvent ||
                                        !(selectedEvent?.eventStatus?.sysName === EventStatus.ApprovedByCoordinator)
                                    }
                                    onClick={onCreateRequirement}
                                >
                                    Создать требование из мероприятия
                                </Button>
                            </Box>
                        )}
                        {canEdit && (
                            <Box mr={'10px'}>
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !selectedEvent ||
                                        !(
                                            selectedEvent?.eventStatus?.sysName === EventStatus.Draft ||
                                            selectedEvent?.eventStatus?.sysName === EventStatus.WaitsApproval ||
                                            selectedEvent?.eventStatus?.sysName === EventStatus.UnderRevision
                                        )
                                    }
                                    onClick={onEditEvent}
                                >
                                    Редактировать мероприятие
                                </Button>
                            </Box>
                        )}
                    </>
                )}
                <Box mr={'10px'}>
                    <Button variant="outlined" disabled={selectedRows.length === 0} onClick={onViewEvent}>
                        Просмотр
                    </Button>
                </Box>
                {renderButtons && (
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={exportCSVEvents}>
                            Выгрузить CSV файл
                        </Button>
                    </Box>
                )}
            </div>

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет мероприятий'}
                rows={tableRows}
                columns={EventsTableColumns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                onChangePaginationPage={setPaginationPage}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
            />
        </UserRightsContainer>
    );
};

export { Events };
