import { useMemo } from 'react';

import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { useUserStore } from 'core/store/user/useUserStore';

const useTasksPermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canViewTasksPage = useMemo(() => {
        return userRolesPermissions.tasks.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return {
        canViewTasksPage,
    };
};

export { useTasksPermissions };
