import axios, { AxiosError } from 'axios';

import { RefreshTokenResponseType } from 'types/user/RefreshTokenResponseType';
import { useUserStore } from '../store/user/useUserStore';
import { REFRESH } from './endpoints';

export const APP_BASE_URL = `${process.env.REACT_APP_BASE_URL as string}/api/v1/`;

const NO_RETRY_HEADER = 'x-no-retry';

export const axiosGateway = axios.create({
    baseURL: APP_BASE_URL,
});

export const axiosWithAuthHeader = axios.create({
    baseURL: APP_BASE_URL,
});

axiosWithAuthHeader.interceptors.request.use(
    (requestConfig) => {
        const { accessToken } = useUserStore.getState();
        if (accessToken) {
            requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return requestConfig;
    },
    async (error) => {
        await Promise.reject(error);
    },
);

axiosWithAuthHeader.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        const originalRequest = error.config;
        if (!originalRequest) return;

        const { refreshToken, setRefreshToken, setAccessToken, user, logOut } = useUserStore.getState();
        if (
            error?.response?.status === 401 &&
            refreshToken &&
            error?.config?.headers &&
            !error.config.headers[NO_RETRY_HEADER]
        ) {
            if (originalRequest.url === REFRESH) {
                throw error;
            }
            error.config.headers[NO_RETRY_HEADER] = 'true';
            return new Promise((resolve) => {
                axiosGateway
                    .post<RefreshTokenResponseType>(REFRESH, {
                        userId: user?.id,
                        refreshToken,
                    })
                    .then(async (res) => {
                        const responseData = res.data;
                        setAccessToken(responseData.accessToken);
                        setRefreshToken(responseData.refreshToken);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${responseData.accessToken}`;
                        originalRequest.headers['Authorization'] = `Bearer ${responseData.accessToken}`;
                        const originalRequestResult = await axiosWithAuthHeader.request(originalRequest);
                        resolve(originalRequestResult);
                    })
                    .catch(() => {
                        logOut();
                    });
            });
        }
        throw error;
    },
);
