import { Box, Button } from '@mui/material';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { UIModal } from 'components/UI/UIModal';
import { useAppStore } from 'core/store/app/useAppStore';
import { axiosWithAuthHeader } from 'core/http';
import { APPOINT_RESPONSIBLE, EVENTS } from 'core/http/endpoints';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { UserRoles } from 'core/constants/UserRoles';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { useUsers } from 'hooks/useUsers';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    riskId: string;
};

const AppointResponsibleModal: FC<Props> = ({ isVisible, setIsVisible, riskId }) => {
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const { users } = useUsers(UserRoles.responsible_for_measure);

    const {
        control,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            eventResponsible: { label: '', value: '' },
        },
        shouldUseNativeValidation: false,
    });

    const responsibleUsers = useMemo(() => {
        return users.map((user) => ({ label: userFullNameFromUser(user), value: user.id }));
    }, [users]);

    const onSubmit = (): void => {
        if (isValid) {
            onAddResponsible();
        } else {
            setErrorAlertText('Выберите ответственного за мероприятие');
        }
    };

    const onAddResponsible = () => {
        if (!riskId) return;

        const { eventResponsible } = getValues();

        axiosWithAuthHeader
            .patch(`${riskId}${EVENTS}${APPOINT_RESPONSIBLE}`, {
                userId: eventResponsible.value,
            })
            .then(() => {
                setSuccessAlertText('Ответственный за мероприятия назначен');
                setIsVisible(false);
                window.location.reload();
            })
            .catch(() => setErrorAlertText('Ошибка при назначении ответственного за мероприятия'));
    };

    return (
        <UIModal
            isOpen={isVisible}
            title={'Назначить ответственного за мероприятие'}
            onClose={() => setIsVisible(false)}
        >
            <>
                <ControlledAutocompleteInput
                    control={control}
                    label={'Ответственный за мероприятие'}
                    name={'eventResponsible'}
                    error={!!errors.eventResponsible}
                    options={responsibleUsers}
                    required
                />
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box mr={'10px'}>
                        <Button variant="contained" type={'submit'} onClick={onSubmit}>
                            Назначить ответственного
                        </Button>
                    </Box>
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={() => setIsVisible(false)}>
                            Назад
                        </Button>
                    </Box>
                </Box>
            </>
        </UIModal>
    );
};

export { AppointResponsibleModal };
