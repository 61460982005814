import { UseFormSetValue } from 'react-hook-form/dist/types/form';

import { RiskType } from 'types/risks/RiskType';
import { RiskFormDefaultValues } from 'core/constants/forms/RiskFormDefaultValues';

export const fillRiskFormDefaultValues = (risk: RiskType, setValue: UseFormSetValue<typeof RiskFormDefaultValues>) => {
    setValue('name', risk.shortName);
    setValue('sourceOfOrigin', 'Hа основании риска');
    setValue('riskRequirements', risk.riskRequirements);
    setValue('objectOfInfluence', risk.riskObject.id.toString());
    setValue('riskClassification', risk.riskClass.id.toString());
    setValue('lifeCycleStage', risk.lifecycleStage.id.toString());
    setValue('systemSubsystemNode', risk.riskSystem.id.toString());
    setValue('staffingSchedule', risk.staffingTable.id.toString());
    setValue('reason', risk.reason);
    setValue('description', risk.riskImpactDescription);
    setValue('comment', risk.comment);
    setValue('sourceOfOrigin', risk.sourceOfRiskData.name.toString());
};
