export const UserRoles = {
    user_subordinate: 'user_subordinate',
    user_supervisor: 'user_supervisor',
    risk_specialist_subordinate: 'risk_specialist_subordinate',
    risk_specialist_supervisor: 'risk_specialist_supervisor',
    risk_owner: 'risk_owner',
    coordinator: 'coordinator',
    project_manager: 'project_manager',
    responsible_for_measure: 'responsible_for_measure',
    responsible_for_measure_implementation: 'responsible_for_measure_implementation',
    requirements_specialist: 'requirements_specialist',
    administrator: 'administrator',
};
