import dayjs from 'dayjs';

export const NotificationFormDefaultValues = {
    name: '',
    reason: '',
    riskObjectId: '',
    riskRequirements: '',
    detectionDate: dayjs(),
    impactDescription: '',
    riskLocation: '',
    responseTime: '',
    comment: '',
    priorityId: '',
    approvalComment: '',
    coordinatingEmployee: { label: '', value: '' },
    approvingEmployee: { label: '', value: '' },
};
