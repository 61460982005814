import { useMemo } from 'react';

import { useUserStore } from 'core/store/user/useUserStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';

const useNotificationsPermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canViewNotificationsPage = useMemo(() => {
        return userRolesPermissions.notifications.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateNotification = useMemo(() => {
        return userRolesPermissions.notifications.create.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditNotification = useMemo(() => {
        return userRolesPermissions.notifications.edit.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canApproveNotification = useMemo(() => {
        return userRolesPermissions.notifications.approve.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canSendOnApproval = useMemo(() => {
        return userRolesPermissions.notifications.sendOnApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canMakeRiskDecision = useMemo(() => {
        return userRolesPermissions.notifications.makeRiskDecision.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canAddRiskSpecialist = useMemo(() => {
        return userRolesPermissions.notifications.addRiskSpecialist.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return {
        canViewNotificationsPage,
        canCreateNotification,
        canEditNotification,
        canApproveNotification,
        canMakeRiskDecision,
        canAddRiskSpecialist,
        canSendOnApproval,
    };
};

export { useNotificationsPermissions };
