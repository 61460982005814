import { FC, useEffect } from 'react';
import Alert from '@mui/material/Alert';

import { useAppStore } from 'core/store/app/useAppStore';
import styles from './alert.module.scss';

const SuccessAlert: FC = () => {
    const successAlertText = useAppStore((state) => state.successAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);

    useEffect(() => {
        if (successAlertText) {
            const timeout = setTimeout(() => {
                setSuccessAlertText('');
            }, 5000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [successAlertText, setSuccessAlertText]);

    if (!successAlertText) return null;

    return (
        <div className={styles.container}>
            <Alert className={styles.alert} severity="success">
                {successAlertText}
            </Alert>
        </div>
    );
};

export { SuccessAlert };
