import { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'navigation/routes';
import { useUserStore } from 'core/store/user/useUserStore';
import { ErrorBoundary } from '../ErrorBoundary';

type Props = {
    children: ReactNode;
};

const ProtectedRoute: FC<Props> = ({ children }) => {
    const { accessToken } = useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            navigate(Routes.Login);
        }
    }, [accessToken]);

    return <ErrorBoundary>{children}</ErrorBoundary>;
};

export { ProtectedRoute };
