import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

export const RequirementsTableColumns: GridColDef[] = [
    {
        field: 'text',
        flex: 55,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Текст требования</strong>,
    },
    {
        field: 'createdBy',
        flex: 15,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Кем создан</strong>,
    },
    {
        field: 'createdAt',
        flex: 15,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Дата создания</strong>,
    },
    {
        field: 'status',
        flex: 15,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Статус</strong>,
    },
];
