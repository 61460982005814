import { Controller, Control } from 'react-hook-form';
import * as React from 'react';
import { FC } from 'react';
import { Typography } from '@mui/material';

import { UITextField } from 'components/UI/UITextField';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    multiline?: boolean;
    error?: boolean;
    disabled?: boolean;
    hint?: string;
    isEmail?: boolean;
    float?: boolean;
    rules?: (value: string) => boolean;
    onChange?: (value: number) => void;
    // eslint-disable-next-line
    control: Control<any>;
};

const ControlledNumberInput: FC<Props> = ({
    name,
    isEmail,
    rules,
    hint,
    required,
    onChange,
    multiline,
    error,
    label,
    control,
    float,
    disabled,
}) => {
    return (
        <div>
            <Controller
                name={name}
                control={control}
                rules={{
                    required,
                    ...(isEmail && {
                        pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Невалидный email',
                        },
                    }),
                    validate: rules,
                }}
                render={({ field }) => (
                    <UITextField
                        type={'number'}
                        required={required}
                        multiline={multiline}
                        error={error}
                        label={label}
                        disabled={disabled}
                        value={field.value as string}
                        setValue={(text) => {
                            let value = Math.floor(Number(text));
                            if (float) {
                                value = Number.parseFloat(Number(text).toFixed(2));
                            }
                            field.onChange(value);
                            onChange && onChange(value);
                        }}
                    />
                )}
            />
            {hint && (
                <Typography variant="caption" display="block" gutterBottom>
                    {hint}
                </Typography>
            )}
        </div>
    );
};

export { ControlledNumberInput };
