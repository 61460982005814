import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { axiosWithAuthHeader } from 'core/http';
import { RISKS, RISKS_CSV } from 'core/http/endpoints';
import { RisksFilterDefaultValues, useRisksStore } from 'core/store/risks/useRisksStore';
import { RiskType } from 'types/risks/RiskType';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { b64toBlob } from 'utils/b64toBlob';
import { downloadFile } from 'utils/downloadFile';
import { CSVRequestType } from 'types/csv/CSVRequestType';
import { PaginationType } from 'types/PaginationType';

const useRisks = (filterItems: boolean) => {
    const {
        dateFrom,
        dateTo,
        setDateTo,
        setDateFrom,
        filter,
        setFilter,
        resetFilter,
        setPaginationPage,
        paginationPage,
    } = useRisksStore((state) => state);

    const { control, reset, getValues, setValue } = useForm({
        defaultValues: RisksFilterDefaultValues,
        shouldUseNativeValidation: false,
    });

    const [risks, setRisks] = useState<RiskType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setValue('shortName', filter.shortName);
        setValue('reason', filter.reason);
        setValue('objectOfInfluence', filter.objectOfInfluence);
        setValue('riskRequirements', filter.riskRequirements);
        setValue('riskImpactDescription', filter.riskImpactDescription);
        setValue('sourceOfOrigin', filter.sourceOfOrigin);
        setValue('riskClassification', filter.riskClassification);
        setValue('lifeCycleStage', filter.lifeCycleStage);
        setValue('systemSubsystemNode', filter.systemSubsystemNode);
        setValue('staffingSchedule', filter.staffingSchedule);
        setValue('createdBy', filter.createdBy);
        setValue('status', filter.status);
    }, []);

    useEffect(() => {
        setIsFetching(true);
        fetchRisks();
    }, [dateFrom, dateTo, paginationPage]);

    const onFilter = () => {
        fetchRisks();
    };

    const getFilterParams = () => {
        const formData = getValues();

        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            ...(formData.createdBy && { createdBy: formData.createdBy }),
            ...(formData.shortName && { name: formData.shortName }),
            ...(formData.reason && { reason: formData.reason }),
            ...(formData.objectOfInfluence && { objectId: formData.objectOfInfluence }),
            ...(formData.riskRequirements && { requirements: formData.riskRequirements }),
            ...(formData.riskImpactDescription && { description: formData.riskImpactDescription }),
            ...(formData.sourceOfOrigin && { sourceId: formData.sourceOfOrigin }),
            ...(formData.riskClassification && { classId: formData.riskClassification }),
            ...(formData.lifeCycleStage && { lifecycleId: formData.lifeCycleStage }),
            ...(formData.systemSubsystemNode && { systemId: formData.systemSubsystemNode }),
            ...(formData.staffingSchedule && { staffId: formData.staffingSchedule }),
            ...(formData.status && { statusId: formData.status }),
        };

        return filterItems ? params : { statusId: '10' };
    };

    const fetchRisks = () => {
        axiosWithAuthHeader
            .get<{ data: RiskType[]; pagination: PaginationType }>(RISKS, {
                params: { ...getFilterParams(), page: paginationPage },
            })
            .then((res) => {
                setRisks(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const exportCSVRisks = () => {
        axiosWithAuthHeader
            .get<CSVRequestType>(RISKS_CSV, { params: getFilterParams() })
            .then((res) => {
                const blob = b64toBlob(res.data.data, res.data.mimeType);
                downloadFile(blob, res.data.name);
            })
            .catch((error) => console.log(error));
    };

    const onReset = () => {
        reset();
        resetFilter();
        fetchRisks();
    };

    const tableRows = useMemo(() => {
        return risks?.map((risk) => ({
            id: risk.id,
            shortName: risk.shortName,
            sourceOfRisk: risk.sourceOfRiskData?.name,
            riskRequirements: risk.riskRequirements,
            riskClassification: risk.riskClass?.name,
            objectOfInfluence: risk.riskObject?.name,
            lifeCycleStage: risk.lifecycleStage?.name,
            systemSubsystemNode: risk.riskSystem?.name,
            staffingSchedule: risk.staffingTable?.name,
            reason: risk.reason,
            description: risk.riskImpactDescription,
            createdAt: risk.createDate,
            status: risk.riskStatus.name,
            author: userFullNameFromUser(risk.createdBy),
        }));
    }, [risks]);

    return {
        risks,
        tableRows,
        isFetching,
        onFilter,
        dateFrom: dateFrom ? dayjs(dateFrom) : null,
        setDateFrom,
        dateTo: dateTo ? dayjs(dateTo) : null,
        setDateTo,
        control,
        onReset,
        setFilter,
        exportCSVRisks,
        totalCount,
        setPaginationPage,
    };
};

export { useRisks };
