import { FC, ReactNode } from 'react';

import { Header } from '../header';
import { Footer } from '../footer';
import styles from './AppContainer.module.scss';

type Props = {
    children: ReactNode;
};

const AppContainer: FC<Props> = ({ children }) => {
    return (
        <div className={styles.container}>
            <div>
                <Header />
                <div className={'container'}>{children}</div>
            </div>
            <Footer />
        </div>
    );
};

export { AppContainer };
