import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Dayjs } from 'dayjs';

export const RequirementsFilterDefaultValues = {
    text: '',
    author: '',
    statusId: '',
};

export type UseRequirementsStoreType = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    paginationPage: number;
    filter: {
        text: string;
        author: string;
        statusId: string;
    };

    setPaginationPage: (page: number) => void;
    resetFilter: () => void;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setFilter: (key: string, value: string) => void;
};

const useRequirementsFilterStore = create<UseRequirementsStoreType>()(
    persist(
        (set, get) => ({
            dateFrom: null,
            dateTo: null,
            filter: RequirementsFilterDefaultValues,
            paginationPage: 0,

            setDateFrom: (date) => set({ dateFrom: date }),
            setDateTo: (date) => set({ dateTo: date }),
            setFilter: (key: string, value: string) => {
                const filter = get().filter;
                set({ filter: { ...filter, [key]: value } });
            },
            setPaginationPage: (page) => set({ paginationPage: page }),
            resetFilter: () => {
                set({ filter: RequirementsFilterDefaultValues });
            },
        }),
        {
            name: 'requirements-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useRequirementsFilterStore };
