import { FC, useRef } from 'react';
import * as React from 'react';
import { Box, Button } from '@mui/material';

import { UIModal } from 'components/UI/UIModal';
import { useAppStore } from 'core/store/app/useAppStore';
import { axiosWithAuthHeader } from 'core/http';
import { USERS } from 'core/http/endpoints';
import { UserPasswordForm, UserPasswordFormRef } from '../UserPasswordForm';
import { UserType } from '../../../../types/user/UserType';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    user?: UserType;
};

const ChangePasswordModal: FC<Props> = ({ isVisible, setIsVisible, user }) => {
    const passwordFormRef = useRef<UserPasswordFormRef>(null);
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const onSave = () => {
        if (passwordFormRef?.current?.isValid() && user) {
            setErrorAlertText('');
            const values = passwordFormRef?.current?.getValues();
            axiosWithAuthHeader
                .put(`${USERS}/${user.id}`, {
                    name: user.name,
                    surname: user.surname,
                    login: user.login,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                })
                .then(() => {
                    setSuccessAlertText('Пароль успешно изменен.');
                    setIsVisible(false);
                })
                .catch(() => setErrorAlertText('Ошибка при изменении пароля.'));
        } else {
            passwordFormRef?.current?.showErrors();
            setErrorAlertText('Исправьте ошибки в пароле');
        }
    };

    return (
        <UIModal isOpen={isVisible} title={'Изменение пароля'} onClose={() => setIsVisible(false)}>
            <>
                <UserPasswordForm ref={passwordFormRef} title={''} />
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={onSave}>
                            Сохранить пароль
                        </Button>
                    </Box>
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={() => setIsVisible(false)}>
                            Отмена
                        </Button>
                    </Box>
                </Box>
            </>
        </UIModal>
    );
};

export { ChangePasswordModal };
