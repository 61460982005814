import { UserRoles } from './UserRoles';

export const userRolesPermissions = {
    notifications: {
        // просмотр страницы уведомлений
        view: [
            UserRoles.user_subordinate,
            UserRoles.user_supervisor,
            UserRoles.risk_specialist_subordinate,
            UserRoles.risk_specialist_supervisor,
            UserRoles.administrator,
        ],
        // создать уведомление
        create: [UserRoles.user_subordinate, UserRoles.user_supervisor],
        // редактировать уведомление
        edit: [UserRoles.user_subordinate, UserRoles.user_supervisor],
        // согласовать уведомление
        sendOnApproval: [UserRoles.user_subordinate, UserRoles.user_supervisor],
        // принять решение
        approve: [UserRoles.user_supervisor],
        // принять решение по риску
        makeRiskDecision: [UserRoles.risk_specialist_subordinate],
        // назначить сотрудника
        addRiskSpecialist: [UserRoles.risk_specialist_supervisor],
    },
    risks: {
        // просмотр страницы рисков
        view: [
            UserRoles.risk_specialist_subordinate,
            UserRoles.risk_specialist_supervisor,
            UserRoles.project_manager,
            UserRoles.risk_owner,
            UserRoles.coordinator,
            UserRoles.responsible_for_measure,
            UserRoles.requirements_specialist,
            UserRoles.administrator,
        ],
        // создать риск
        create: [
            UserRoles.risk_specialist_supervisor,
            UserRoles.risk_specialist_subordinate,
            UserRoles.project_manager,
            UserRoles.coordinator,
        ],
        // редактировать риск
        edit: [UserRoles.risk_specialist_supervisor, UserRoles.risk_specialist_subordinate, UserRoles.project_manager],
        // отправить на согласование
        sendOnApproval: [UserRoles.risk_specialist_supervisor, UserRoles.risk_specialist_subordinate],
        // согласовать риск
        approve: [UserRoles.project_manager],
        // назначить владельца риска
        addRiskOwner: [UserRoles.project_manager],
        // оценить риск
        assessRisk: [UserRoles.risk_owner],
        // отправить оценку риска на утверждение
        sendAssessmentForApproval: [UserRoles.risk_owner],
        // отправить оценку риска на доработку
        sendUnderRevision: [UserRoles.project_manager],
        // утвердить оценку риска
        approveRiskAssessment: [UserRoles.project_manager],
        // проверить оценку риска
        checkRiskAssessment: [UserRoles.coordinator],
        // сменить владельца риска
        changeRiskOwner: [UserRoles.project_manager],
        // посмотреть план мероприятий
        viewRiskEvents: [
            UserRoles.coordinator,
            UserRoles.project_manager,
            UserRoles.risk_owner,
            UserRoles.responsible_for_measure,
        ],
    },
    events: {
        // просмотр страницы мероприятий
        view: [
            UserRoles.project_manager,
            UserRoles.risk_owner,
            UserRoles.coordinator,
            UserRoles.responsible_for_measure,
            UserRoles.responsible_for_measure_implementation,
            UserRoles.requirements_specialist,
            UserRoles.administrator,
        ],
        edit: [UserRoles.project_manager],
        // создание мероприятий когда нет мероприятий или когда они в статусе черновик
        createFromDraft: [UserRoles.risk_owner],
        // создание мероприятий когда они в статусе на доработке
        createFromUnderRevision: [UserRoles.risk_owner],
        // создание мероприятий когда они в статусе на согласовании
        createFromWaitsApproval: [UserRoles.project_manager],
        // создание мероприятий когда они в статусе ответственный назначен
        createFromResponsibleAppointed: [UserRoles.responsible_for_measure],
        // создание мероприятий когда они в статусе ответственный назначен
        createFromUnderRevisionByResponsible: [UserRoles.responsible_for_measure],
        // создание мероприятия в статусе Согласован руководителем проекта
        createFromApprovedByProjectManager: [UserRoles.coordinator],
        // редактирование мероприятия в статусе черновик
        editFromDraft: [UserRoles.risk_owner, UserRoles.project_manager],
        // редактирование мероприятия в статусе на согласовании
        editFromWaitsApproval: [UserRoles.project_manager],
        // редактирование мероприятия в статусе на доработке
        editFromUnderRevision: [UserRoles.risk_owner, UserRoles.project_manager],
        // редактирование мероприятия когда оно в статусе ответственный назначен
        editFromResponsibleAppointed: [UserRoles.responsible_for_measure],
        // редактирование мероприятия когда оно в статусе Доработка отвественным
        editFromUnderRevisionByResponsible: [UserRoles.responsible_for_measure],
        // редактирование мероприятия когда оно в статусе Согласован руководителем проекта
        editFromApprovedByProjectManager: [UserRoles.coordinator],
        // отправка мероприятий на согласование
        sendForApproval: [UserRoles.risk_owner, UserRoles.project_manager],
        // отправка мероприятий на доработку
        sendForRevision: [UserRoles.project_manager],
        // согласовать мероприятия
        approveEvents: [UserRoles.project_manager],
        // назначить ответственного
        appointResponsible: [UserRoles.project_manager],
        // отправить план мероприятий на согласование руководителем
        canSendForApprovalByManager: [UserRoles.responsible_for_measure],
        // согласовать план мероприятий с датами
        approveEventPlanWithDates: [UserRoles.risk_owner, UserRoles.project_manager, UserRoles.coordinator],
        // отправить план мероприятий на доработку ответственнмоу за мероприятия
        sendEventPlanWithDatesForRevision: [UserRoles.risk_owner, UserRoles.project_manager, UserRoles.coordinator],
    },
    requirements: {
        // просмотр страницы требований
        view: [
            UserRoles.project_manager,
            UserRoles.coordinator,
            UserRoles.administrator,
            UserRoles.requirements_specialist,
        ],
        // редактирование
        edit: [UserRoles.project_manager, UserRoles.requirements_specialist],
        // редактирование требования из статуса согласовано
        editFromApproved: [UserRoles.coordinator],
        // создание требования
        create: [UserRoles.project_manager, UserRoles.coordinator, UserRoles.requirements_specialist],
        // отправить на согласование
        sendOnApproval: [UserRoles.project_manager, UserRoles.requirements_specialist],
        // согласовать
        approve: [UserRoles.project_manager],
        // утвердить
        confirm: [UserRoles.coordinator],
    },
    tasks: {
        // просмотр страницы задач
        view: [
            UserRoles.user_supervisor,
            UserRoles.risk_specialist_subordinate,
            UserRoles.risk_specialist_supervisor,
            UserRoles.risk_owner,
            UserRoles.coordinator,
            UserRoles.project_manager,
            UserRoles.responsible_for_measure,
        ],
    },
    users: {
        // просмотр страницы пользователей
        view: [UserRoles.administrator],
        // создание пользователя
        create: [UserRoles.administrator],
        // редактирование пользователя
        edit: [UserRoles.administrator],
    },
    reports: {
        // просмотр страницы отчетов
        view: [UserRoles.coordinator, UserRoles.project_manager, UserRoles.administrator],
    },
};
