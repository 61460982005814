import { Link } from '@mui/material';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { ControlledNumberInput } from 'components/common/ControlledNumberInput';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { useRisksAdditionalData } from 'hooks/useRisksAdditionalData';
import { MATRIX_LINK } from 'core/constants/common';

type Props = {
    // eslint-disable-next-line
    control: Control<any>;
    // eslint-disable-next-line
    errors: FieldErrors<any>;
    expectedScheduleChange: string;
    riskProbability: string;
    expectedDamageCost: string;
};

const AssessRiskForm: FC<Props> = ({
    control,
    errors,
    expectedScheduleChange,
    riskProbability,
    expectedDamageCost,
}) => {
    const { dictionaryPrioritiesInputOptions } = useRisksAdditionalData();

    const resultOfQuantitativeAssessmentDeadlines = useMemo(() => {
        if (!expectedScheduleChange || !riskProbability) {
            return '-';
        }
        return (Number.parseFloat(expectedScheduleChange) * Number.parseFloat(riskProbability)) / 100;
    }, [expectedScheduleChange, riskProbability]);

    const resultOfQuantitativeAssessmentPrice = useMemo(() => {
        if (!riskProbability || !expectedDamageCost) {
            return '-';
        }
        return (Number.parseFloat(riskProbability) * Number.parseFloat(expectedDamageCost)) / 100;
    }, [riskProbability, expectedDamageCost]);

    return (
        <div className={'fields-container'}>
            <span>Метод оценки риска:</span>
            <ControlledSelectInput
                control={control}
                label={'Метод оценки риска'}
                name={'riskAssessmentMethodId'}
                error={!!errors.riskAssessmentMethodId}
                options={dictionaryPrioritiesInputOptions}
                required
            />
            <span>Изменение сроков минимальное:</span>
            <ControlledNumberInput
                control={control}
                label={'Изменение сроков минимальное'}
                name={'minimalScheduleChange'}
                error={!!errors.minimalScheduleChange}
                required
            />
            <span>Изменение сроков ожидаемое:</span>
            <ControlledNumberInput
                control={control}
                label={'Изменение сроков ожидаемое'}
                name={'expectedScheduleChange'}
                error={!!errors.expectedScheduleChange}
                required
            />
            <span>Изменение сроков максимальное:</span>
            <ControlledNumberInput
                control={control}
                label={'Изменение сроков максимальное'}
                name={'maximumScheduleChange'}
                error={!!errors.maximumScheduleChange}
                required
            />
            <span>Стоимость ущерба минимальная:</span>
            <ControlledNumberInput
                control={control}
                label={'Стоимость ущерба минимальная'}
                name={'minimalDamageCost'}
                error={!!errors.minimalDamageCost}
                required
                float
            />
            <span>Стоимость ущерба ожидаемая:</span>
            <ControlledNumberInput
                control={control}
                label={'Стоимость ущерба ожидаемая'}
                name={'expectedDamageCost'}
                error={!!errors.expectedDamageCost}
                required
                float
            />
            <span>Стоимость ущерба максимальная:</span>
            <ControlledNumberInput
                control={control}
                label={'Стоимость ущерба максимальная'}
                name={'maximumDamageCost'}
                error={!!errors.maximumDamageCost}
                required
                float
            />
            <span>Вероятность риска в %:</span>
            <ControlledNumberInput
                control={control}
                label={'Вероятность риска в %'}
                name={'riskProbability'}
                error={!!errors.riskProbability}
                required
                float
            />
            <span>Критерий приемлемости риска:</span>
            <ControlledTextInput
                name={'riskAcceptanceCriterion'}
                control={control}
                label={'Критерий приемлемости риска'}
                error={!!errors.riskAcceptanceCriterion}
                required
            />
            <span>Результат количественной оценки (сроки):</span>
            <span>{resultOfQuantitativeAssessmentDeadlines}</span>
            <span>Результат количественной оценки (стоимость):</span>
            <span>{resultOfQuantitativeAssessmentPrice}</span>
            <span>Ранг риска в порядке возрастания:</span>
            <ControlledNumberInput
                control={control}
                label={'Ранг риска'}
                name={'rank'}
                error={!!errors.rank}
                required
            />
            <Link href={MATRIX_LINK} target={'_blank'}>
                Открыть матрицу
            </Link>
        </div>
    );
};

export { AssessRiskForm };
