import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { useApproveNotification } from 'hooks/useApproveNotification';
import { AppContainer } from 'components/common/appContainer';
import { ViewNotificationComponent } from 'components/common/ViewNotificaionComponent';
import { UILoader } from 'components/UI/UILoader';
import { Routes } from 'navigation/routes';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { RejectNotificationModal } from '../components/RejectNotificationModal';

const ApproveNotification = () => {
    const { id } = useParams<{ id: string }>();
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();
    const navigate = useNavigate();

    const { notification, rejectModalVisible, setRejectModalVisible, onApprove } = useApproveNotification(id);

    const onGoBack = () => {
        navigate(`${Routes.SendNotificationToApproveWithEdit}/${id as string}`);
    };

    const onCancel = (): void => {
        navigateToOriginalUrl([Routes.Notifications, Routes.Tasks]);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.approve}>
                <div className={'container_thin'}>
                    <h2>Принять решение</h2>
                    {notification ? (
                        <>
                            <ViewNotificationComponent notification={notification} />
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={onApprove}>
                                        Согласовать
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={() => setRejectModalVisible(true)}>
                                        Отклонить
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" type={'submit'} onClick={onGoBack}>
                                        Редактировать уведомление
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" type={'submit'} onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                            <RejectNotificationModal
                                notificationId={notification.id}
                                isVisible={rejectModalVisible}
                                setIsVisible={setRejectModalVisible}
                            />
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ApproveNotification };
