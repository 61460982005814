import { useEffect, useState } from 'react';
import { risksService } from '../core/services/risksService';
import { RiskType } from '../types/risks/RiskType';

const useRisk = (riskId?: string) => {
    const [risk, setRisk] = useState<RiskType>();

    useEffect(() => {
        if (riskId) {
            getRisk(riskId);
        }
    }, []);

    const getRisk = (id: string) => {
        risksService.getRisk(id, (res) => {
            const risk = res.data as RiskType;
            setRisk(risk);
        });
    };

    return {
        risk,
    };
};

export { useRisk };
