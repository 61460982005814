import { create } from 'zustand';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';

interface EventsState {
    dictionaryEventTypes: IBaseDictionaryEntity[];
    dictionaryEventStatuses: IBaseDictionaryEntity[];

    setDictionaryEventTypes: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryEventStatuses: (data: IBaseDictionaryEntity[]) => void;
}

const useEventsStore = create<EventsState>((set) => ({
    dictionaryEventTypes: [],
    dictionaryEventStatuses: [],

    setDictionaryEventTypes: (data: IBaseDictionaryEntity[]) => set({ dictionaryEventTypes: data }),
    setDictionaryEventStatuses: (data: IBaseDictionaryEntity[]) => set({ dictionaryEventStatuses: data }),
}));

export { useEventsStore };
