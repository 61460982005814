import { FC, useState } from 'react';
import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Stack, Paper, Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { Control } from 'react-hook-form';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useEventsAdditionalData } from 'hooks/useEventsAdditionalData';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import styles from './EventsFilter.module.scss';

type Props = {
    onSubmit: () => void;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setDueDate: (date: Dayjs | null) => void;
    setControlDate: (date: Dayjs | null) => void;
    // eslint-disable-next-line
    control: Control<any>;
    onReset: () => void;
    onChangeFilter: (key: string, value: string) => void;
};

const EventsFilter: FC<Props> = ({
    onSubmit,
    onChangeFilter,
    setDueDate,
    setControlDate,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    control,
    onReset,
}) => {
    const { dictionaryEventTypesInputOptions, dictionaryEventStatusesInputOptions } = useEventsAdditionalData();

    const [showAllFields, setShowAllFields] = useState<boolean>(false);

    return (
        <div>
            <div className={styles.timeFields}>
                <span>Дата создания мероприятия:</span>
                <DatePicker
                    className={styles.timeField}
                    label="От"
                    value={dateFrom}
                    slotProps={{
                        textField: {
                            sx: { mr: '10px', ml: '24px' },
                            size: 'small',
                        },
                        field: { clearable: true },
                    }}
                    onChange={(newValue) => {
                        setDateFrom(newValue);
                    }}
                />
                <DatePicker
                    className={styles.timeField}
                    label="До"
                    value={dateTo}
                    slotProps={{
                        textField: {
                            sx: { mr: '10px' },
                            size: 'small',
                        },
                        field: { clearable: true },
                    }}
                    onChange={(newValue) => {
                        setDateTo(newValue);
                    }}
                />
                <Button
                    variant="text"
                    endIcon={showAllFields ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setShowAllFields(!showAllFields)}
                >
                    Расширенный поиск
                </Button>
            </div>

            {showAllFields && (
                <Paper elevation={3} className={styles.allFieldsContainer}>
                    <div className={styles.allFields}>
                        <Stack spacing={2}>
                            <ControlledSelectInput
                                control={control}
                                label={'Тип мероприятия'}
                                name={'eventTypeId'}
                                options={dictionaryEventTypesInputOptions}
                                onChange={(value) => onChangeFilter('eventTypeId', value)}
                            />
                            <ControlledTextInput
                                name={'eventText'}
                                control={control}
                                label={'Текст мероприятия'}
                                onChange={(value) => onChangeFilter('eventText', value)}
                            />
                            <ControlledTextInput
                                name={'responsibleUser'}
                                control={control}
                                label={'Ответственный за выполнение'}
                                onChange={(value) => onChangeFilter('responsibleUser', value)}
                            />
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onSubmit}>
                                    Искать
                                </Button>
                                <Button variant="text" onClick={onReset}>
                                    Очистить поиск
                                </Button>
                            </Box>
                        </Stack>

                        <Stack spacing={2}>
                            <ControlledDateInput
                                control={control}
                                label={'Срок выполнения (плановая дата)'}
                                name={'dueDate'}
                                onChange={(value) => setDueDate(value)}
                            />
                            <ControlledDateInput
                                control={control}
                                label={'Срок контроля мероприятия'}
                                name={'controlDate'}
                                onChange={(value) => setControlDate(value)}
                            />
                            <ControlledTextInput
                                name={'createdBy'}
                                control={control}
                                label={'Кем создано'}
                                onChange={(value) => onChangeFilter('createdBy', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Статус'}
                                name={'statusId'}
                                options={dictionaryEventStatusesInputOptions}
                                onChange={(value) => onChangeFilter('statusId', value)}
                            />
                        </Stack>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export { EventsFilter };
