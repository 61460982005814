import * as React from 'react';

import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { CreateNotification } from 'pages/notifications/CreateNotification';
import { EditNotification } from 'pages/notifications/EditNotification';
import { ViewNotification } from 'pages/notifications/ViewNotification';
import { SendNotificationToApproveStep1 } from 'pages/notifications/SendNotificationToApprove/SendNotificationToApproveStep1';
import { SendNotificationToApproveStep2 } from 'pages/notifications/SendNotificationToApprove/SendNotificationToApproveStep2';
import { ApproveNotification } from 'pages/notifications/ApproveNotification';
import { AddRiskSpecialist } from 'pages/notifications/AddRiskSpecialist';
import { MakeRiskDecision } from 'pages/notifications/MakeRiskDecision';
import { NotificationsSearchRisk } from 'pages/notifications/NotificationsSearchRisk';
import { NotificationsPage } from 'pages/notifications/NotificationsPage';
import { Routes } from '../routes';

export const notificationRoutes = [
    {
        path: Routes.Notifications,
        element: (
            <ProtectedRoute>
                <NotificationsPage />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.CreateNotification,
        element: (
            <ProtectedRoute>
                <CreateNotification />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.EditNotification}/:id`,
        element: (
            <ProtectedRoute>
                <EditNotification />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ViewNotification}/:id`,
        element: (
            <ProtectedRoute>
                <ViewNotification />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.SendNotificationToApproveWithEdit}/:id`,
        element: (
            <ProtectedRoute>
                <SendNotificationToApproveStep1 />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.SendNotificationToApprove}/:id`,
        element: (
            <ProtectedRoute>
                <SendNotificationToApproveStep2 />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ApproveNotification}/:id`,
        element: (
            <ProtectedRoute>
                <ApproveNotification />
            </ProtectedRoute>
        ),
    },

    {
        path: `${Routes.NotificationAddRiskSpecialist}/:id`,
        element: (
            <ProtectedRoute>
                <AddRiskSpecialist />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.NotificationRiskDecision}/:id`,
        element: (
            <ProtectedRoute>
                <MakeRiskDecision />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.NotificationsSearchRisk}/:notificationId`,
        element: (
            <ProtectedRoute>
                <NotificationsSearchRisk />
            </ProtectedRoute>
        ),
    },
];
