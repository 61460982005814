import { useMemo } from 'react';

import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { useUserStore } from 'core/store/user/useUserStore';

const useUsersPagePermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canViewUsersPage = useMemo(() => {
        return userRolesPermissions.users.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateUser = useMemo(() => {
        return userRolesPermissions.users.create.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditUser = useMemo(() => {
        return userRolesPermissions.users.edit.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return {
        canViewUsersPage,
        canCreateUser,
        canEditUser,
    };
};

export { useUsersPagePermissions };
