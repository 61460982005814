import * as React from 'react';

import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { CreateEvent } from 'pages/events/CreateEvent';
import { EditEvent } from 'pages/events/EditEvent';
import { ViewEvent } from 'pages/events/ViewEvent';
import { RiskEvents } from 'pages/events/RiskEvents';
import { EventsPage } from 'pages/events/EventsPage';
import { Routes } from '../routes';

export const eventRoutes = [
    {
        path: Routes.Events,
        element: (
            <ProtectedRoute>
                <EventsPage />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.CreateEvent}/:riskId`,
        element: (
            <ProtectedRoute>
                <CreateEvent />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.EditEvent}/:riskId/:eventId`,
        element: (
            <ProtectedRoute>
                <EditEvent />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ViewEvent}/:id`,
        element: (
            <ProtectedRoute>
                <ViewEvent />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.RiskEvents}/:riskId`,
        element: (
            <ProtectedRoute>
                <RiskEvents />
            </ProtectedRoute>
        ),
    },
];
