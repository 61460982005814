import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { EventFormType } from 'types/events/EventFormType';
import { axiosWithAuthHeader } from 'core/http';
import { EVENTS } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { EventType } from 'types/events/EventType';
import { EventStatus } from '../core/constants/EventStatus';
import { userRolesPermissions } from '../core/constants/userRolesPermissions';

const useEditEvent = ({ riskId, eventId }: { riskId?: string; eventId?: string }) => {
    const navigate = useNavigate();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [event, setEvent] = useState<EventType>();

    useEffect(() => {
        fetchEvent();
    }, []);

    const fetchEvent = () => {
        if (!riskId || !eventId) return;

        axiosWithAuthHeader
            .get(`/${riskId}${EVENTS}/${eventId}`)
            .then((res) => {
                setEvent(res.data as EventType);
            })
            .catch((error) => {
                console.log(EVENTS, error);
            });
    };

    const editEvent = (data: EventFormType): void => {
        if (!riskId || !eventId) return;

        axiosWithAuthHeader
            .put(`/${riskId}${EVENTS}/${eventId}`, {
                eventText: data.eventText,
                eventTypeId: data.eventTypeId,
                dueDate: data.dueDate?.format('YYYY-MM-DD HH:mm:ss'),
                controlDate: data.controlDate?.format('YYYY-MM-DD HH:mm:ss'),
                responsibleId: data.eventResponsible?.value,
            })
            .then(() => {
                setSuccessAlertText('Мероприятие успешно отредактировано.');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при редактировании мероприятия.');
            });
    };

    const permittedUserRolesToEdit = useMemo(() => {
        if (!event) return [];

        return (
            {
                [EventStatus.Draft]: userRolesPermissions.events.editFromDraft,
                [EventStatus.WaitsApproval]: userRolesPermissions.events.editFromWaitsApproval,
                [EventStatus.UnderRevision]: userRolesPermissions.events.editFromUnderRevision,
                [EventStatus.ResponsibleAppointed]: userRolesPermissions.events.editFromResponsibleAppointed,
                [EventStatus.UnderRevisionByResponsible]:
                    userRolesPermissions.events.editFromUnderRevisionByResponsible,
                [EventStatus.ApprovedByProjectManager]: userRolesPermissions.events.editFromApprovedByProjectManager,
            }[event.eventStatus.sysName] || []
        );
    }, [event]);

    return {
        editEvent,
        event,
        permittedUserRolesToEdit,
    };
};

export { useEditEvent };
