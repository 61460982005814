import { AppContainer } from 'components/common/appContainer';
import { Events } from './index';

const EventsPage = () => {
    return (
        <AppContainer>
            <Events renderButtons renderFilter />
        </AppContainer>
    );
};

export { EventsPage };
