import { DashboardObjectsByUserType } from 'types/dashboard/EntityDashboardType';
import { FC } from 'react';

import styles from '../Reports.module.scss';

type Props = {
    data: DashboardObjectsByUserType[];
    name: string;
};

const UsersTableStatistic: FC<Props> = ({ data, name }) => {
    return (
        <div style={{ border: '1px solid #8e9698', padding: '12px' }}>
            <p style={{ marginBottom: '12px' }}>{name}</p>

            {data.map((user, index) => (
                <div key={index} className={styles.usersRow}>
                    <span>
                        {user.surname} {user.name} {user.patronymic}
                    </span>
                    <span>
                        {user.count} ({user.percentage})
                    </span>
                </div>
            ))}
        </div>
    );
};

export { UsersTableStatistic };
