import React, { FC, ReactNode, useEffect, useState } from 'react';

import { useUserStore } from 'core/store/user/useUserStore';
import { PageNotFound } from '../PageNotFound';
import { UILoader } from '../../UI/UILoader';

type Props = {
    children: ReactNode;
    allowedUserRoles: string[];
};

const UserRightsContainer: FC<Props> = ({ children, allowedUserRoles }) => {
    const { user } = useUserStore();
    const [error, setError] = useState<boolean>(false);

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    useEffect(() => {
        if (allowedUserRoles.length === 0) return;
        if (!allowedUserRoles.some((r) => getUserRolesSysNames().includes(r))) {
            setError(true);
        }
    }, [allowedUserRoles, user]);

    if (allowedUserRoles.length === 0) return <UILoader isVisible />;
    if (error) return <PageNotFound />;
    return <>{children}</>;
};

export { UserRightsContainer };
