import * as React from 'react';

import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { CreateRisk } from 'pages/risks/CreateRisk';
import { ViewRisk } from 'pages/risks/ViewRisk';
import { EditRisk } from 'pages/risks/EditRisk';
import { SendRiskToApprove } from 'pages/risks/SendRiskToApprove';
import { ApproveRisk } from 'pages/risks/ApproveRisk';
import { AddRiskOwner } from 'pages/risks/AddRiskOwner';
import { AssessRisk } from 'pages/risks/AssessRisk';
import { ApproveRiskAssessment } from 'pages/risks/ApproveRiskAssessment';
import { SendRiskAssessmentForRevision } from 'pages/risks/SendRiskAssessmentForRevision';
import { ChangeRiskOwner } from 'pages/risks/ChangeRiskOwner';
import { CheckRiskAssessment } from 'pages/risks/CheckRiskAssessment';
import { SendForRevisionByCoordinator } from 'pages/risks/SendForRevisionByCoordinator';
import { RisksPage } from 'pages/risks/RisksPage';
import { Routes } from '../routes';

export const riskRoutes = [
    {
        path: Routes.CreateRisk,
        element: (
            <ProtectedRoute>
                <CreateRisk />
            </ProtectedRoute>
        ),
    },
    {
        path: Routes.Risks,
        element: (
            <ProtectedRoute>
                <RisksPage />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ViewRisk}/:riskId`,
        element: (
            <ProtectedRoute>
                <ViewRisk />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.EditRisk}/:riskId`,
        element: (
            <ProtectedRoute>
                <EditRisk />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.SendRiskToApprove}/:riskId`,
        element: (
            <ProtectedRoute>
                <SendRiskToApprove />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ApproveRisk}/:riskId`,
        element: (
            <ProtectedRoute>
                <ApproveRisk />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.AddRiskOwner}/:riskId`,
        element: (
            <ProtectedRoute>
                <AddRiskOwner />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.AssessRisk}/:riskId`,
        element: (
            <ProtectedRoute>
                <AssessRisk />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ApproveRiskAssessment}/:riskId`,
        element: (
            <ProtectedRoute>
                <ApproveRiskAssessment />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.SendRiskAssessmentForRevision}/:riskId`,
        element: (
            <ProtectedRoute>
                <SendRiskAssessmentForRevision />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.ChangeRiskOwner}/:riskId`,
        element: (
            <ProtectedRoute>
                <ChangeRiskOwner />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.CheckRiskAssessment}/:riskId`,
        element: (
            <ProtectedRoute>
                <CheckRiskAssessment />
            </ProtectedRoute>
        ),
    },
    {
        path: `${Routes.SendForRevisionByCoordinator}/:riskId`,
        element: (
            <ProtectedRoute>
                <SendForRevisionByCoordinator />
            </ProtectedRoute>
        ),
    },
];
