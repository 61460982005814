import { FC, useState } from 'react';
import * as React from 'react';
import { Button } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { RiskType } from 'types/risks/RiskType';

type Props = {
    risk: RiskType;
};

const ViewRiskIdentification: FC<Props> = ({ risk }) => {
    const [showAll, setShowAll] = useState<boolean>(false);

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>Идентификация риска</h3>
                <Button
                    variant="text"
                    endIcon={showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    style={{ marginLeft: 12 }}
                    onClick={() => setShowAll(!showAll)}
                >
                    Развернуть
                </Button>
            </div>
            {showAll && (
                <div className="fields-container">
                    <span>Классификация риска:</span>
                    <span>{risk?.riskClass?.name}</span>
                    <span>Объект воздействия:</span>
                    <span>{risk?.riskObject?.name}</span>
                    <span>Этап ЖЦИ:</span>
                    <span>{risk?.lifecycleStage?.name}</span>
                    <span>Система/подсистема/узел:</span>
                    <span>{risk?.riskSystem?.name}</span>
                    <span>Штатное расписание:</span>
                    <span>{risk?.staffingTable?.name}</span>
                    <span>Причина риска:</span>
                    <span>{risk?.reason}</span>
                    <span>Описание воздействия риска:</span>
                    <span>{risk?.riskImpactDescription}</span>
                </div>
            )}
        </>
    );
};

export { ViewRiskIdentification };
