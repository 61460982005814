import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Box, Button } from '@mui/material';

import { AppContainer } from 'components/common/appContainer';
import { useRisk } from 'hooks/useRisk';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { UILoader } from 'components/UI/UILoader';
import { Routes } from 'navigation/routes';
import { risksService } from 'core/services/risksService';
import { useAppStore } from 'core/store/app/useAppStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { RiskAssessmentInfo } from '../components/RiskAssessmentInfo';

const CheckRiskAssessment = () => {
    const navigate = useNavigate();
    const { riskId } = useParams();

    const { risk } = useRisk(riskId);
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const onSendForRevision = () => {
        navigate(`${Routes.SendForRevisionByCoordinator}/${riskId as string}`);
    };

    const onApprove = () => {
        if (!riskId) return;

        risksService.approveByCoordinator(
            riskId,
            () => {
                setSuccessAlertText('Оценка риска успешно утверждена');
                navigate(-1);
            },
            () => {
                setErrorAlertText('Ошибка при утверждении оценки риска');
            },
        );
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.checkRiskAssessment}>
                <div className={'container_thin'}>
                    <h2>Оценка риска</h2>
                    {risk ? (
                        <>
                            <ViewRiskComponent risk={risk} />
                            <h3>Оценка</h3>
                            <RiskAssessmentInfo risk={risk} />
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" onClick={onApprove}>
                                        Утвердить оценку риска
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onSendForRevision}>
                                        Вернуть на доработку
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={() => navigate(-1)}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { CheckRiskAssessment };
