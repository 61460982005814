import { DatePicker } from '@mui/x-date-pickers';
import { Button } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import { Dayjs } from 'dayjs';
import { FC } from 'react';

import styles from './FilterDateFields.module.scss';

type Props = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setShowAllFields?: (value: boolean) => void;
    showAllFields?: boolean;
    title?: string;
    showFilterButton?: boolean;
};

const FilterDateFields: FC<Props> = ({
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    showAllFields,
    setShowAllFields,
    title = 'Дата создания:',
    showFilterButton = true,
}) => {
    return (
        <div className={styles.timeFields}>
            <span>{title}</span>
            <DatePicker
                className={styles.timeField}
                label="От"
                value={dateFrom}
                slotProps={{
                    textField: {
                        sx: { mr: '10px', ml: '24px' },
                        size: 'small',
                    },
                    field: { clearable: true },
                }}
                onChange={(newValue) => {
                    setDateFrom(newValue);
                }}
            />
            <DatePicker
                className={styles.timeField}
                label="До"
                value={dateTo}
                slotProps={{
                    textField: {
                        sx: { mr: '10px' },
                        size: 'small',
                    },
                    field: { clearable: true },
                }}
                onChange={(newValue) => {
                    setDateTo(newValue);
                }}
            />
            {showFilterButton && (
                <Button
                    variant="text"
                    endIcon={showAllFields ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setShowAllFields && setShowAllFields(!showAllFields)}
                >
                    Расширенный поиск
                </Button>
            )}
        </div>
    );
};

export { FilterDateFields };
