import { Controller, Control } from 'react-hook-form';
import * as React from 'react';
import { FC } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    error?: boolean;
    onChange?: (value: string) => void;
    // eslint-disable-next-line
    control: Control<any>;
    options: {
        label: string;
        value: string | number;
    }[];
};

const ControlledSelectInput: FC<Props> = ({ name, onChange, required, error, label, control, options }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field }) => (
                <FormControl size={'small'} error={error} required={required} fullWidth>
                    <InputLabel error={error}>{label}</InputLabel>
                    <Select
                        inputRef={field.ref}
                        value={field.value as string}
                        label={label}
                        onChange={(event) => {
                            field.onChange(event.target.value);
                            onChange && onChange(event.target.value);
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    );
};

export { ControlledSelectInput };
