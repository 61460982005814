import { Controller, Control } from 'react-hook-form';
import * as React from 'react';
import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    onChange?: (value: boolean) => void;
    // eslint-disable-next-line
    control: Control<any>;
};

const ControlledCheckBoxInput: FC<Props> = ({ name, required, label, control, onChange }) => (
    <FormControlLabel
        required={required}
        control={
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <Checkbox
                        checked={field.value as boolean}
                        onChange={(e) => {
                            field.onChange(e.target.checked);
                            onChange && onChange(e.target.checked);
                        }}
                    />
                )}
            />
        }
        label={label}
    />
);

export { ControlledCheckBoxInput };
