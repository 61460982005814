import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Dayjs } from 'dayjs';

export const EventsFilterDefaultValues = {
    eventTypeId: '',
    eventText: '',
    responsibleUser: '',
    dueDate: null as Dayjs | null,
    controlDate: null as Dayjs | null,
    createdBy: '',
    statusId: '',
};

interface EventsFilterState {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    dueDate: Dayjs | null;
    controlDate: Dayjs | null;
    filter: {
        eventTypeId: string;
        eventText: string;
        responsibleUser: string;
        createdBy: string;
        statusId: string;
    };

    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setDueDate: (date: Dayjs | null) => void;
    setControlDate: (date: Dayjs | null) => void;
    setFilter: (key: string, value: string) => void;
    resetFilter: () => void;
}

const useEventsFilterStore = create<EventsFilterState>()(
    persist(
        (set, get) => ({
            dateFrom: null,
            dateTo: null,
            dueDate: null,
            controlDate: null,
            filter: EventsFilterDefaultValues,

            setDateFrom: (date) => set({ dateFrom: date }),
            setDateTo: (date) => set({ dateTo: date }),
            setDueDate: (date) => set({ dueDate: date }),
            setControlDate: (date) => set({ controlDate: date }),
            setFilter: (key: string, value: string) => {
                const filter = get().filter;
                set({ filter: { ...filter, [key]: value } });
            },
            resetFilter: () => {
                set({ filter: EventsFilterDefaultValues });
            },
        }),
        {
            name: 'events-filter-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useEventsFilterStore };
