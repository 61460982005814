import { useMemo } from 'react';

import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { useUserStore } from 'core/store/user/useUserStore';

const useReportsPagePermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canViewReportsPage = useMemo(() => {
        return userRolesPermissions.reports.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return { canViewReportsPage };
};

export { useReportsPagePermissions };
