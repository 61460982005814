import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { axiosWithAuthHeader } from 'core/http';
import { RISKS } from 'core/http/endpoints';
import { RiskType } from 'types/risks/RiskType';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import styles from './ViewRisk.module.scss';

const ViewRisk: FC = () => {
    const navigate = useNavigate();
    const { riskId } = useParams();

    const [risk, setRisk] = useState<RiskType>();

    useEffect(() => {
        if (!riskId) return;

        axiosWithAuthHeader
            .get(`${RISKS}/${riskId}`)
            .then((res) => {
                setRisk(res.data as RiskType);
            })
            .catch((error) => console.log(error));
    }, []);

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.view}>
                <div className={styles.container}>
                    {risk ? (
                        <>
                            <h2>Просмотр риска</h2>
                            <Paper elevation={3} className={styles.fields}>
                                <ViewRiskComponent risk={risk} showAll />
                                <Button variant="outlined" onClick={onCancel}>
                                    Назад
                                </Button>
                            </Paper>
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ViewRisk };
