import { axiosWithAuthHeader } from 'core/http';
import { APPROVE, CONFIRM, REQUIREMENTS, SEND_FOR_APPROVAL } from 'core/http/endpoints';
import { useEditRequirement } from './useEditRequirement';

const useSendRequirementForApproval = (requirementId?: string) => {
    const { editRequirement, requirement, setErrorAlertText, setSuccessAlertText, navigate } =
        useEditRequirement(requirementId);

    const onSendForApproval = () => {
        if (!requirementId) return;

        axiosWithAuthHeader
            .patch(`${REQUIREMENTS}/${requirementId}${SEND_FOR_APPROVAL}`)
            .then(() => {
                setSuccessAlertText('Требование отправлено на согласование');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при отправке требования на согласование');
            });
    };

    const onApprove = () => {
        if (!requirementId) return;

        axiosWithAuthHeader
            .patch(`${REQUIREMENTS}/${requirementId}${APPROVE}`)
            .then(() => {
                setSuccessAlertText('Требование успешно согласовано');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при согласовании требования');
            });
    };

    const onConfirm = () => {
        if (!requirementId) return;

        axiosWithAuthHeader
            .patch(`${REQUIREMENTS}/${requirementId}${CONFIRM}`)
            .then(() => {
                setSuccessAlertText('Требование успешно утверждено');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при утверждении требования');
            });
    };

    return {
        onSendForApproval,
        editRequirement,
        requirement,
        onApprove,
        onConfirm,
    };
};

export { useSendRequirementForApproval };
