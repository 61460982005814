export const TaskSysName = {
    ApproveNotification: 'approve_notification',
    ApproveRisk: 'approve_risk',
    NotificationWaitsApproval: 'notification_waits_approval',
    AppointRiskSpecialist: 'appointRiskSpecialist',
    CreateRiskFromNotification: 'createRiskFromNotification',
    EditAfterProjectManagerRevision: 'edit_after_project_manager_revision',
    Assess: 'assess',
    AppointRiskOwner: 'appoint_risk_owner',
    EditRiskAssessmentAfterProjectManagerRevision: 'edit_risk_assessment_after_project_manager_revision',
    CheckBeforeApprovingByProjectManager: 'check_before_approving_by_project_manager',
    EditAfterCoordinatorRevision: 'edit_after_coordinator_revision',
    ApproveAssessment: 'approve_assessment',
    CreateEventsFromRisk: 'create_events_from_risk',
    EditPlanAfterProjectManagerRevision: 'edit_plan_after_project_manager_revision',
    ApprovePlan: 'approve_plan',
    AppointResponsibleForPlan: 'appoint_responsible_for_plan',
    AssessEvents: 'assess_events',
    ApproveOrSendForRevisionByRiskOwner: 'approve_or_send_for_revision_by_risk_owner',
    ApproveRequirement: 'approve_requirement',
    ConfirmRequirement: 'confirm_requirement',
};
