import { useEffect, useState } from 'react';

import { NotificationType } from 'types/notifications/NotificationType';
import { Routes } from 'navigation/routes';
import { useAppStore } from 'core/store/app/useAppStore';
import { notificationsService } from 'core/services/notificationsService';
import { useNavigateToOriginUrl } from './useNavigateToOriginUrl';

const useApproveNotification = (id?: string | number) => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);
    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);

    const [notification, setNotification] = useState<NotificationType>();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;
        getNotification(id);
    }, []);

    const getNotification = (id: string | number) => {
        notificationsService.getNotification(id, (res) => {
            setNotification(res.data as NotificationType);
            setIsFetching(false);
        });
    };

    const onApprove = () => {
        if (!id) return;

        notificationsService.approveNotification(
            id,
            () => {
                setSuccessAlertText('Уведомление согласовано');
                navigateToOriginalUrl([Routes.Notifications, Routes.Tasks]);
            },
            () => {
                setErrorAlertText('Ошибка при согласовании уведомления');
            },
        );
    };

    return {
        isFetching,
        notification,
        onApprove,
        rejectModalVisible,
        setRejectModalVisible,
    };
};

export { useApproveNotification };
