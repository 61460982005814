const CREATE = '/create';
const EDIT = '/edit';
const VIEW = '/view';
const APPROVE = '/approve';
const SEND_TO_APPROVE = '/send-to-approve';
const CONFIRM = '/confirm';

const NOTIFICATIONS = '/notifications';
const RISKS = '/risks';
const EVENTS = '/events';
const USERS = '/users';
const REQUIREMENTS = '/requirements';

export const Routes = {
    Main: '/',
    UIKit: '/uikit',
    Login: '/login',
    Dashboard: '/dashboard',
    Notifications: NOTIFICATIONS,
    CreateNotification: NOTIFICATIONS + CREATE,
    EditNotification: NOTIFICATIONS + EDIT,
    ViewNotification: NOTIFICATIONS + VIEW,
    Risks: '/risks',
    Events: EVENTS,
    CreateEvent: EVENTS + CREATE,
    EditEvent: EVENTS + EDIT,
    ViewEvent: EVENTS + VIEW,
    SendNotificationToApproveWithEdit: NOTIFICATIONS + SEND_TO_APPROVE + EDIT,
    SendNotificationToApprove: NOTIFICATIONS + SEND_TO_APPROVE,
    ApproveNotification: NOTIFICATIONS + APPROVE,
    NotificationAddRiskSpecialist: `${NOTIFICATIONS}/add-risk-specialist`,
    NotificationRiskDecision: `${NOTIFICATIONS}/risk-decision`,
    NotificationsSearchRisk: `${NOTIFICATIONS}/search-risk`,
    CreateRisk: RISKS + CREATE,
    ViewRisk: RISKS + VIEW,
    EditRisk: RISKS + EDIT,
    SendRiskToApprove: RISKS + SEND_TO_APPROVE,
    ApproveRisk: RISKS + APPROVE,
    AddRiskOwner: `${RISKS}/add-risk-owner`,
    ApproveRiskAssessment: `${RISKS}/approve-risk-assessment`,
    CheckRiskAssessment: `${RISKS}/check-risk-assessment`,
    SendForRevisionByCoordinator: `${RISKS}/send-for-revision-by-coordinator`,
    SendRiskAssessmentForRevision: `${RISKS}/send-risk-assessment-for-revision`,
    ChangeRiskOwner: `${RISKS}/change-risk-owner`,
    AssessRisk: `${RISKS}/assess-risk`,
    RiskEvents: `/risk-events`,
    Requirements: REQUIREMENTS,
    Search: '/search',
    Tasks: '/tasks',
    FAQ: '/faq',
    Reports: '/reports',
    Users: USERS,
    CreateUser: USERS + CREATE,
    EditUser: USERS + EDIT,
    ViewUser: USERS + VIEW,
    CreateRequirement: REQUIREMENTS + CREATE,
    EditRequirement: REQUIREMENTS + EDIT,
    SendRequirementForApproval: REQUIREMENTS + SEND_TO_APPROVE,
    ApproveRequirement: REQUIREMENTS + APPROVE,
    ConfirmRequirement: REQUIREMENTS + CONFIRM,
    ViewRequirement: REQUIREMENTS + VIEW,
};
