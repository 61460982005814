import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { forwardRef, useImperativeHandle } from 'react';

import { ControlledTextInput } from 'components/common/ControlledTextInput';

const MIN_PASSWORD_LENGTH = 8;

export type UserPasswordFormRef = {
    isValid: () => boolean;
    getValues: () => {
        password: string;
        confirmPassword: string;
    };
    showErrors: () => void;
};

type Props = {
    title?: string;
};

const UserPasswordForm = forwardRef<UserPasswordFormRef, Props>(function UserPasswordForm({ title = 'Пароль' }, ref) {
    const {
        control,
        watch,
        trigger,
        formState: { errors },
    } = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
        shouldUseNativeValidation: false,
    });

    const watchFields = watch(['password', 'confirmPassword']);

    const validatePassword = (password: string) =>
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /\d/.test(password) &&
        password.length >= MIN_PASSWORD_LENGTH;

    const validateConfirmPassword = () => watchFields[0] === watchFields[1];

    useImperativeHandle(
        ref,
        () => {
            return {
                isValid: () => {
                    return watchFields[0] === watchFields[1] && validatePassword(watchFields[0]);
                },
                getValues: () => {
                    return {
                        password: watchFields[0],
                        confirmPassword: watchFields[1],
                    };
                },
                showErrors: trigger,
            };
        },
        [watchFields, trigger],
    );

    return (
        <Stack spacing={2}>
            {title && <h4>{title}</h4>}
            <ControlledTextInput
                name={'password'}
                control={control}
                label={'Введите пароль'}
                error={!!errors.password}
                rules={validatePassword}
                type={'password'}
                required
            />
            <ControlledTextInput
                name={'confirmPassword'}
                control={control}
                label={'Повторите пароль'}
                error={!!errors.confirmPassword}
                rules={validateConfirmPassword}
                type={'password'}
                required
            />
            <div>
                {watchFields[0] !== watchFields[1] && (
                    <Typography variant="caption" display="block" gutterBottom>
                        Пароли не совпадают
                    </Typography>
                )}
                {watchFields[0].length < MIN_PASSWORD_LENGTH && (
                    <Typography variant="caption" display="block" gutterBottom>
                        Пароль должен должен содержать минимум 8 символов
                    </Typography>
                )}
                {!/[A-Z]/.test(watchFields[0]) && (
                    <Typography variant="caption" display="block" gutterBottom>
                        Пароль должен содержать заглавные буквы
                    </Typography>
                )}
                {!/[a-z]/.test(watchFields[0]) && (
                    <Typography variant="caption" display="block" gutterBottom>
                        Пароль должен содержать буквы в нижнем регистре
                    </Typography>
                )}
                {!/\d/.test(watchFields[0]) && (
                    <Typography variant="caption" display="block" gutterBottom>
                        Пароль должен содержать цифры
                    </Typography>
                )}
            </div>
        </Stack>
    );
});

export { UserPasswordForm };
