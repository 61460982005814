import { Box, Button, Stack } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRef } from 'react';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { AppContainer } from 'components/common/appContainer';
import { Routes } from 'navigation/routes';
import { UserFormType } from 'types/user/UserFormType';
import { useAppStore } from 'core/store/app/useAppStore';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { useCreateUser } from 'hooks/useCreateUser';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { UserPasswordForm, UserPasswordFormRef } from '../components/UserPasswordForm';

const CreateUser = () => {
    const navigate = useNavigate();
    const passwordFormRef = useRef<UserPasswordFormRef>(null);
    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { userRolesInputOptions, userDepartmentsInputOptions } = useAdditionalData();
    const { createUser } = useCreateUser();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            name: '',
            lastName: '',
            patronymic: '',
            role: '',
            department: '',
            email: '',
            jobTitle: '',
            login: '',
            externalUsername: '',
        },
        shouldUseNativeValidation: false,
    });

    const onSubmit: SubmitHandler<UserFormType> = (data) => {
        if (passwordFormRef?.current?.isValid()) {
            createUser(data, passwordFormRef?.current?.getValues());
            setErrorAlertText('');
        } else {
            passwordFormRef?.current?.showErrors();
            setErrorAlertText('Исправьте ошибки в пароле');
        }
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCancel = (): void => {
        navigate(Routes.Users);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.users.create}>
                <div className={'container_thin'}>
                    <h2>Создание нового пользователя</h2>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} mb={'32px'}>
                            <ControlledTextInput
                                name={'lastName'}
                                control={control}
                                label={'Фамилия'}
                                error={!!errors.lastName}
                                required
                            />
                            <ControlledTextInput
                                name={'name'}
                                control={control}
                                label={'Имя'}
                                error={!!errors.name}
                                required
                            />
                            <ControlledTextInput
                                name={'patronymic'}
                                control={control}
                                label={'Отчество'}
                                error={!!errors.patronymic}
                                required
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Роль'}
                                name={'role'}
                                error={!!errors.role}
                                options={userRolesInputOptions.filter((role) => role.label !== 'Администратор')}
                                required
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Подразделение'}
                                name={'department'}
                                error={!!errors.department}
                                options={userDepartmentsInputOptions}
                                required
                            />
                            <ControlledTextInput
                                name={'jobTitle'}
                                control={control}
                                label={'Должность'}
                                error={!!errors.jobTitle}
                                required
                            />
                            <ControlledTextInput
                                name={'email'}
                                control={control}
                                label={'Email'}
                                error={!!errors.email}
                                required
                                isEmail
                            />
                            <ControlledTextInput
                                name={'login'}
                                control={control}
                                label={'Логин'}
                                error={!!errors.login}
                                required
                            />
                            <ControlledTextInput
                                name={'externalUsername'}
                                control={control}
                                label={'Логин Kamotive'}
                                error={!!errors.externalUsername}
                            />
                            <UserPasswordForm ref={passwordFormRef} />
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={onSave}>
                                        Сохранить пользователя
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Отменить
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>
                    </form>
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { CreateUser };
