import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAppStore } from 'core/store/app/useAppStore';
import { useUserStore } from 'core/store/user/useUserStore';
import { axiosWithAuthHeader } from 'core/http';
import { EVENTS, REQUIREMENTS } from 'core/http/endpoints';
import { EventType } from 'types/events/EventType';

const useCreateRequirement = (eventId?: string, riskId?: string) => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);
    const user = useUserStore((state) => state.user);
    const [event, setEvent] = useState<EventType>();

    useEffect(() => {
        fetchEvent();
    }, []);

    const fetchEvent = () => {
        if (!eventId || !riskId) return;

        axiosWithAuthHeader
            .get(`/${riskId}${EVENTS}/${eventId}`)
            .then((res) => {
                setEvent(res.data as EventType);
            })
            .catch((error) => {
                console.log(EVENTS, error);
            });
    };

    const createRequirement = (data: { text: string }): void => {
        if (!user) return;

        const url = eventId ? `${REQUIREMENTS}/${eventId}` : REQUIREMENTS;

        axiosWithAuthHeader
            .post(url, {
                text: data.text,
            })
            .then(() => {
                setSuccessAlertText('Требование успешно создано.');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при создании требования.');
            });
    };

    return {
        createRequirement,
        event,
    };
};

export { useCreateRequirement };
