import { useMemo } from 'react';

import { useUserStore } from 'core/store/user/useUserStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';

const useRequirementsPermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canViewRequirementsPage = useMemo(() => {
        return userRolesPermissions.requirements.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateRequirement = useMemo(() => {
        return userRolesPermissions.requirements.create.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditRequirement = useMemo(() => {
        return userRolesPermissions.requirements.edit.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditRequirementFromApproved = useMemo(() => {
        return userRolesPermissions.requirements.editFromApproved.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canSendOnApproval = useMemo(() => {
        return userRolesPermissions.requirements.sendOnApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canApprove = useMemo(() => {
        return userRolesPermissions.requirements.approve.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canConfirm = useMemo(() => {
        return userRolesPermissions.requirements.confirm.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return {
        canEditRequirementFromApproved,
        canViewRequirementsPage,
        canCreateRequirement,
        canEditRequirement,
        canSendOnApproval,
        canApprove,
        canConfirm,
    };
};

export { useRequirementsPermissions };
