import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type UseAsyncAppStoreType = {
    originUrl: string | null;

    setOriginUrl: (value: string | null) => void;
};

const useAsyncAppStore = create<UseAsyncAppStoreType>()(
    persist(
        (set) => ({
            originUrl: null,
            setOriginUrl: (value) => set({ originUrl: value }),
        }),
        {
            name: 'app-storage',
        },
    ),
);

export { useAsyncAppStore };
