import { useNavigate } from 'react-router-dom';

import { axiosWithAuthHeader } from 'core/http';
import { NOTIFICATIONS } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { useUserStore } from 'core/store/user/useUserStore';
import { Routes } from 'navigation/routes';
import { NotificationFormType } from 'types/notifications/NotificationFormType';

const useCreateNotification = () => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);
    const user = useUserStore((state) => state.user);

    const createNotification = (data: NotificationFormType): void => {
        if (!user) return;

        axiosWithAuthHeader
            .post(NOTIFICATIONS, {
                notificationName: data.name,
                riskReason: data.reason,
                riskObjectId: data.riskObjectId,
                riskRequirements: data.riskRequirements,
                detectionDate: data.detectionDate?.format('YYYY-MM-DD HH:mm:ss'),
                impactDescription: data.impactDescription,
                riskLocation: data.riskLocation,
                responseTime: data.responseTime,
                comment: data.comment,
                senderId: user.id,
                priorityId: data.priorityId,
            })
            .then(() => {
                setSuccessAlertText('Уведомление успешно создано.');
                navigate(Routes.Notifications);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при сохранении формы.');
            });
    };

    return {
        createNotification,
    };
};

export { useCreateNotification };
