import { AxiosResponse } from 'axios';

import { NotificationEditRequestType } from 'types/notifications/NotificationEditRequestType';
import { axiosWithAuthHeader } from '../http';
import { USERS } from '../http/endpoints';

// eslint-disable-next-line
type CallbackType = (res: AxiosResponse<any>) => void;

export const usersService = {
    getUser: (id: string | number, successCallback: CallbackType) => {
        axiosWithAuthHeader
            .get(`${USERS}/${id}`)
            .then((res) => {
                successCallback(res);
            })
            .catch((error) => console.log(error));
    },
    editUser: (
        id: string | number,
        params: NotificationEditRequestType,
        successCallback: CallbackType,
        errorCallback: CallbackType,
    ) => {
        axiosWithAuthHeader.put(`${USERS}/${id}`, params).then(successCallback).catch(errorCallback);
    },
};
