import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { USERS } from 'core/http/endpoints';
import { axiosWithAuthHeader } from 'core/http';
import { UserType } from 'types/user/UserType';
import { UsersFilterDefaultValues, useUsersStore } from 'core/store/users/useUsersStore';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { PaginationType } from 'types/PaginationType';

const useUsersPage = () => {
    const {
        dateFrom,
        dateTo,
        setDateTo,
        setDateFrom,
        filter,
        setFilter,
        resetFilter,
        setPaginationPage,
        paginationPage,
    } = useUsersStore((state) => state);

    const { control, reset, getValues, setValue } = useForm({
        defaultValues: UsersFilterDefaultValues,
        shouldUseNativeValidation: false,
    });

    const [users, setUsers] = useState<UserType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setValue('name', filter.name);
        setValue('role', filter.role);
        setValue('department', filter.department);
        setValue('jobTitle', filter.jobTitle);
        setValue('email', filter.email);
        setValue('login', filter.login);
    }, []);

    useEffect(() => {
        setIsFetching(true);
        fetchUsers();
    }, [dateFrom, dateTo, paginationPage]);

    const onFilter = () => {
        fetchUsers();
    };

    const fetchUsers = () => {
        const formData = getValues();

        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            ...(formData.name && { name: formData.name }),
            ...(formData.role && { role: formData.role }),
            ...(formData.department && { department: formData.department }),
            ...(formData.jobTitle && { job: formData.jobTitle }),
            ...(formData.email && { email: formData.email }),
            ...(formData.login && { login: formData.login }),
            page: paginationPage,
        };
        axiosWithAuthHeader
            .get<{ data: UserType[]; pagination: PaginationType }>(USERS, { params })
            .then((res) => {
                setUsers(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const onReset = () => {
        reset();
        resetFilter();
        fetchUsers();
    };

    const tableRows = useMemo(() => {
        return users?.map((user) => ({
            id: user.id,
            name: userFullNameFromUser(user),
            role: user.userRoles?.map((role) => role.name).join(', '),
            subdivision: user.department?.name,
            jobTitle: user.jobTitle,
            email: user.email,
            login: user.login,
            externalUsername: user.externalUsername,
            createdAt: user.createDate,
        }));
    }, [users]);

    return {
        users,
        tableRows,
        isFetching,
        onFilter,
        dateFrom: dateFrom ? dayjs(dateFrom) : null,
        setDateFrom,
        dateTo: dateTo ? dayjs(dateTo) : null,
        setDateTo,
        control,
        onReset,
        setFilter,
        setPaginationPage,
        totalCount,
    };
};

export { useUsersPage };
