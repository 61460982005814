import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { FC } from 'react';

import { Routes } from 'navigation/routes';
import { UITable } from 'components/UI/UITable';
import { useUsersPage } from 'hooks/useUsersPage';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { UsersFilter } from './components/UsersFilter';

const columns: GridColDef[] = [
    {
        field: 'name',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>ФИО</strong>,
    },
    {
        field: 'role',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Роль</strong>,
    },
    {
        field: 'subdivision',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Подразделение</strong>,
    },
    {
        field: 'jobTitle',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Должность</strong>,
    },
    {
        field: 'email',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Email</strong>,
    },
    {
        field: 'login',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Логин</strong>,
    },
    {
        field: 'externalUsername',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Логин Kamotive</strong>,
    },
    {
        field: 'createdAt',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Дата создания <br /> пользователя
            </strong>
        ),
    },
];

type Props = {
    renderButtons?: boolean;
    renderFilter?: boolean;
};

const Users: FC<Props> = ({ renderButtons, renderFilter }) => {
    const navigate = useNavigate();

    const {
        tableRows,
        isFetching,
        onFilter,
        control,
        onReset,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
        setFilter,
        totalCount,
        setPaginationPage,
    } = useUsersPage();

    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    const onCreateUser = (): void => {
        navigate(Routes.CreateUser);
    };

    const onEditUser = (): void => {
        navigate(`${Routes.EditUser}/${selectedRows[0]}`);
    };

    const onViewUser = (): void => {
        navigate(`${Routes.ViewUser}/${selectedRows[0]}`);
    };

    return (
        <UserRightsContainer allowedUserRoles={userRolesPermissions.users.view}>
            {renderFilter && (
                <>
                    <h2>Пользователи</h2>
                    <UsersFilter
                        dateTo={dateTo}
                        dateFrom={dateFrom}
                        setDateTo={setDateTo}
                        setDateFrom={setDateFrom}
                        control={control}
                        onReset={onReset}
                        onSubmit={onFilter}
                        onChangeFilter={setFilter}
                    />
                </>
            )}

            <div style={{ display: 'flex' }}>
                {renderButtons && (
                    <>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onCreateUser}>
                                Создать пользователя
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" disabled={selectedRows.length === 0} onClick={onEditUser}>
                                Редактировать пользователя
                            </Button>
                        </Box>
                    </>
                )}
                <Box mr={'10px'}>
                    <Button variant="outlined" disabled={selectedRows.length === 0} onClick={onViewUser}>
                        Просмотр
                    </Button>
                </Box>
            </div>

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет пользователей'}
                rows={tableRows}
                columns={columns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                onChangePaginationPage={setPaginationPage}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
            />
        </UserRightsContainer>
    );
};

export { Users };
