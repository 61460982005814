import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CardContent, Typography } from '@mui/material';

import { FilterDateFields } from 'components/common/FilterDateFields';
import { axiosWithAuthHeader } from 'core/http';
import { DASHBOARD_REQUIREMENTS } from 'core/http/endpoints';
import { EntityDashboardType } from 'types/dashboard/EntityDashboardType';
import { StatisticHistogram } from 'components/common/StatisticHistogram/StatisticHistogram';
import { UILoader } from 'components/UI/UILoader';
import styles from '../Reports.module.scss';
import { UsersTableStatistic } from './UsersTableStatistic';

const RequirementsReports = () => {
    const [dateTo, setDateTo] = useState<Dayjs | null>(null);
    const [dateFrom, setDateFrom] = useState<Dayjs | null>(null);
    const [data, setData] = useState<EntityDashboardType>();

    useEffect(() => {
        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
        };

        axiosWithAuthHeader
            .get<EntityDashboardType>(DASHBOARD_REQUIREMENTS, { params })
            .then((res) => {
                setData(res.data);
            })
            .catch((error) => console.log(error));
    }, [dateFrom, dateTo]);

    const eventsStatistic = useMemo(() => {
        if (!data) return [];

        return [
            { title: 'Создано требований:', value: data.createdAmount },
            { title: 'Утверждено требований:', value: data.approvedAmount },
        ];
    }, [data]);

    return (
        <div>
            <FilterDateFields
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                dateFrom={dateFrom}
                showFilterButton={false}
                title={'Выберите период:'}
            />

            {data ? (
                <div className={styles.container}>
                    <div className={styles.cardsContainer}>
                        {eventsStatistic.map((data, index) => (
                            <CardContent key={index} className={styles.card}>
                                <Typography variant="body2" color="text.secondary" className={styles.cardTitle}>
                                    {data.title}
                                </Typography>
                                <div className={styles.cardStatistic}>
                                    <Typography variant="h5" component="div">
                                        {data.value || '0'}
                                    </Typography>
                                </div>
                            </CardContent>
                        ))}
                    </div>
                    <div className={styles.cardsContainer}>
                        <StatisticHistogram
                            name={'Требования'}
                            data={data.objectsByStatus.map((data) => +data.count)}
                            categories={data.objectsByStatus.map((data) => data.statusName)}
                        />
                        <UsersTableStatistic data={data.objectsByUser} name={'Сотрудники, создавшие требования:'} />
                    </div>
                </div>
            ) : (
                <UILoader isVisible />
            )}
        </div>
    );
};

export { RequirementsReports };
