import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { ControlledCheckBoxInput } from 'components/common/ControlledCheckBoxInput';
import { useSearchRisksByNotification } from 'hooks/useSearchRisksByNotification';
import { UITable } from 'components/UI/UITable';
import { Routes } from 'navigation/routes';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import styles from './NotificationSearchRisk.module.scss';

const columns: GridColDef[] = [
    {
        field: 'shortName',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Краткое
                <br />
                наименование
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'sourceOfRisk',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Источник
                <br />
                возникновения
            </strong>
        ),
    },
    {
        field: 'riskRequirements',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Требования
                <br />к рискам
            </strong>
        ),
    },
    {
        field: 'riskClassification',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Классификация
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'objectOfInfluence',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Объект
                <br />
                воздействия
            </strong>
        ),
    },
    {
        field: 'lifeCycleStage',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Этап ЖЦИ</strong>,
    },
    {
        field: 'systemSubsystemNode',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Система /<br />
                подсистема /<br />
                узел
            </strong>
        ),
    },
    {
        field: 'staffingSchedule',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Штатное
                <br />
                расписание
            </strong>
        ),
    },
    {
        field: 'reason',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Причина
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'description',
        flex: 9,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Описание <br /> воздействия <br /> риска
            </strong>
        ),
    },
    {
        field: 'comment',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Комментарий</strong>,
    },
];

const NotificationsSearchRisk = () => {
    const { notificationId } = useParams();
    const navigate = useNavigate();

    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    const {
        sourceOfRiskDataInputOptions,
        dictionaryClassesInputOptions,
        dictionaryLifecycleStagesInputOptions,
        dictionaryRiskSystemInputOptions,
        dictionaryStaffingTableInputOptions,
    } = useAdditionalData();

    const {
        control,
        onSearchRisks,
        notification,
        tableRows,
        onReset,
        isFetchingRisks,
        setPaginationPage,
        totalCount,
        setFilter,
    } = useSearchRisksByNotification(notificationId);

    const onGoBack = () => {
        navigate(-1);
    };

    const onViewRisk = (): void => {
        if (!selectedRows[0]) return;
        navigate(`${Routes.ViewRisk}/${selectedRows[0] as string}`);
    };

    const onCreateRisk = (): void => {
        navigate(`${Routes.CreateRisk}?riskId=${selectedRows[0] as string}`);
    };

    return (
        <AppContainer>
            <h2>Поиск существующего риска в БД</h2>
            {notification ? (
                <div className={styles.form}>
                    <ControlledCheckBoxInput
                        name={'name'}
                        control={control}
                        label={'Наименование'}
                        onChange={(value) => setFilter('name', value)}
                    />
                    <span className={styles.formLabel}>{notification.notificationName}</span>
                    <span className={styles.formLabel}>Источник возникнования:</span>
                    <ControlledSelectInput
                        control={control}
                        label={'Источник возникновения'}
                        name={'sourceOfOrigin'}
                        options={sourceOfRiskDataInputOptions}
                        onChange={(value) => setFilter('sourceOfOrigin', value)}
                    />

                    <ControlledCheckBoxInput
                        name={'riskReason'}
                        control={control}
                        label={'Причина риска'}
                        onChange={(value) => setFilter('riskReason', value)}
                    />
                    <span className={styles.formLabel}>{notification.riskReason}</span>
                    <span className={styles.formLabel}>Классификация риска:</span>
                    <ControlledSelectInput
                        control={control}
                        label={'Классификация риска'}
                        name={'riskClassification'}
                        options={dictionaryClassesInputOptions}
                        onChange={(value) => setFilter('riskClassification', value)}
                    />

                    <ControlledCheckBoxInput
                        name={'objectOfInfluence'}
                        control={control}
                        label={'Объект воздействия'}
                        onChange={(value) => setFilter('objectOfInfluence', value)}
                    />
                    <span className={styles.formLabel}>{notification.riskObject.name}</span>
                    <span className={styles.formLabel}>Этап ЖЦИ:</span>
                    <ControlledSelectInput
                        control={control}
                        label={'Этап ЖЦИ'}
                        name={'lifeCycleStage'}
                        options={dictionaryLifecycleStagesInputOptions}
                        onChange={(value) => setFilter('lifeCycleStage', value)}
                    />

                    <ControlledCheckBoxInput
                        name={'riskRequirements'}
                        control={control}
                        label={'Требования к рискам'}
                        onChange={(value) => setFilter('riskRequirements', value)}
                    />
                    <span className={styles.formLabel}>{notification.riskRequirements}</span>
                    <span className={styles.formLabel}>Система/подсистема/узел:</span>
                    <ControlledSelectInput
                        control={control}
                        label={'Система/подсистема/узел'}
                        name={'systemSubsystemNode'}
                        options={dictionaryRiskSystemInputOptions}
                        onChange={(value) => setFilter('systemSubsystemNode', value)}
                    />

                    <ControlledCheckBoxInput
                        name={'impactDescription'}
                        control={control}
                        label={'Описание воздействия'}
                        onChange={(value) => setFilter('impactDescription', value)}
                    />
                    <span className={styles.formLabel}>{notification.impactDescription}</span>
                    <span className={styles.formLabel}>Штатное расписание:</span>
                    <ControlledSelectInput
                        control={control}
                        label={'Штатное расписание'}
                        name={'staffingSchedule'}
                        options={dictionaryStaffingTableInputOptions}
                        onChange={(value) => setFilter('staffingSchedule', value)}
                    />

                    <ControlledCheckBoxInput
                        name={'comment'}
                        control={control}
                        label={'Комментарий'}
                        onChange={(value) => setFilter('comment', value)}
                    />
                    <span className={styles.formLabel}>{notification.comment}</span>
                </div>
            ) : (
                <UILoader isVisible />
            )}

            <ControlledCheckBoxInput
                name={'includeQualificationRisks'}
                control={control}
                label={'Включая квалифицированные риски'}
                onChange={(value) => setFilter('includeQualificationRisks', value)}
            />

            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                <Box mr={'10px'}>
                    <Button variant="outlined" onClick={onSearchRisks}>
                        Поиск существующего риска в БД
                    </Button>
                </Box>
                <Box mr={'10px'}>
                    <Button variant="outlined" disabled={selectedRows.length === 0} onClick={onCreateRisk}>
                        Создать на основе выбранного риска
                    </Button>
                </Box>
                <Box mr={'10px'}>
                    <Button variant="outlined" onClick={onReset}>
                        Очистить поиск
                    </Button>
                </Box>
                <Box mr={'10px'}>
                    <Button variant="outlined" disabled={selectedRows.length === 0} onClick={onViewRisk}>
                        Просмотр
                    </Button>
                </Box>
                <Box mr={'10px'}>
                    <Button variant="outlined" onClick={onGoBack}>
                        Назад
                    </Button>
                </Box>
            </Box>
            <h3>Найдено рисков: {tableRows.length}</h3>

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет рисков'}
                rows={tableRows}
                columns={columns}
                rowSelectionModel={selectedRows}
                loading={isFetchingRisks}
                onChangePaginationPage={setPaginationPage}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
            />
        </AppContainer>
    );
};

export { NotificationsSearchRisk };
