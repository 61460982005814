import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationType } from 'types/notifications/NotificationType';
import { axiosWithAuthHeader } from 'core/http';
import { NOTIFICATIONS, SEND_FOR_APPROVAL } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { NotificationFormType } from 'types/notifications/NotificationFormType';
import { UserRoles } from 'core/constants/UserRoles';
import { Routes } from 'navigation/routes';
import { useUsers } from './useUsers';

const useSendNotificationToApprove = (id?: string | number) => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);

    const [notification, setNotification] = useState<NotificationType>();
    const [isFetching, setIsFetching] = useState<boolean>(true);

    const { users } = useUsers();

    useEffect(() => {
        if (!id) return;

        getNotification(id);
    }, []);

    const getNotification = (id: string | number) => {
        axiosWithAuthHeader
            .get(`${NOTIFICATIONS}/${id}`)
            .then((res) => {
                setNotification(res.data as NotificationType);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const editNotification = (data: NotificationFormType): void => {
        if (!id || !notification) return;

        const { approvingUser, coordinatingUser } = notification;
        const { approvingEmployee, coordinatingEmployee } = data;

        axiosWithAuthHeader
            .put(`${NOTIFICATIONS}/${id}`, {
                notificationName: data.name,
                riskReason: data.reason,
                riskObjectId: data.riskObjectId,
                riskRequirements: data.riskRequirements,
                detectionDate: data.detectionDate?.format('YYYY-MM-DD HH:mm:ss'),
                impactDescription: data.impactDescription,
                riskLocation: data.riskLocation,
                responseTime: data.responseTime,
                comment: data.comment,
                priorityId: data.priorityId,
                approvingUserId: approvingEmployee?.value || approvingUser?.id,
                coordinatingUserId: coordinatingEmployee?.value || coordinatingUser?.id,
                approvalComment: data.approvalComment || notification.approvalComment,
            })
            .then(() => {
                setSuccessAlertText('Уведомление успешно отредактировано.');
                // navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при сохранении формы.');
            });
    };

    const onSendToApprove = (data: {
        comment: string;
        approvingEmployee: { label: string; value: string };
        coordinatingEmployee: { label: string; value: string };
    }) => {
        axiosWithAuthHeader
            .post(`${NOTIFICATIONS}/${id as string}${SEND_FOR_APPROVAL}`, {
                approvalComment: data.comment,
                coordinatingUserId: data.coordinatingEmployee.value,
                approvingUserId: data.approvingEmployee.value,
            })
            .then(() => {
                setSuccessAlertText('Уведомление отправлено на согласование.');
                navigate(Routes.Notifications);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при отправке формы.');
            });
    };

    const userSupervisors = useMemo(() => {
        return users
            .filter((user) => user.userRoles.find((role) => role.sysName === UserRoles.user_supervisor))
            .map((user) => ({ label: `${user.surname} ${user.name} ${user.patronymic}`, value: user.id }));
    }, [users]);

    const riskSpecialistSupervisors = useMemo(() => {
        return users
            .filter((user) => user.userRoles.find((role) => role.sysName === UserRoles.risk_specialist_supervisor))
            .map((user) => ({ label: `${user.surname} ${user.name} ${user.patronymic}`, value: user.id }));
    }, [users]);

    return {
        isFetching,
        notification,
        editNotification,
        userSupervisors,
        riskSpecialistSupervisors,
        onSendToApprove,
    };
};

export { useSendNotificationToApprove };
