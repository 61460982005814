import { useNavigate } from 'react-router-dom';

import { useUserStore } from 'core/store/user/useUserStore';
import { axiosWithAuthHeader } from 'core/http';
import { LOG_OUT, USERS } from 'core/http/endpoints';
import { Routes } from 'navigation/routes';
import { UserType } from 'types/user/UserType';

export const useUser = () => {
    const navigate = useNavigate();
    const { setAccessToken, setUser, setRefreshToken, user } = useUserStore((state) => state);

    const logOut = (): void => {
        if (!user) return;

        axiosWithAuthHeader.post(LOG_OUT, { userId: user.id }).catch((error) => console.log(error));
        setRefreshToken(null);
        setAccessToken(null);
        setUser(null);
        navigate(Routes.Login);
    };

    const fetchUser = async (id: number) => {
        const result = await axiosWithAuthHeader.get(`${USERS}/${id}`);
        const user: UserType = result.data as UserType;
        setUser(user);
    };

    return {
        logOut,
        user,
        fetchUser,
    };
};
