import { Controller, Control } from 'react-hook-form';
import * as React from 'react';
import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    error?: boolean;
    // eslint-disable-next-line
    control: Control<any>;
    onChange?: (date: Dayjs | null) => void;
};

const ControlledDateInput: FC<Props> = ({ name, onChange, required, error, label, control }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field }) => (
                <DatePicker
                    format="DD.MM.YYYY"
                    label={label}
                    value={field.value as Dayjs}
                    inputRef={field.ref}
                    slotProps={{
                        textField: {
                            required,
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error,
                        },
                    }}
                    onChange={(date) => {
                        field.onChange(date);
                        onChange && onChange(date);
                    }}
                />
            )}
        />
    );
};

export { ControlledDateInput };
