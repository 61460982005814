import React, { ReactNode } from 'react';

import { PageNotFound } from '../PageNotFound';
import { AppContainer } from '../appContainer';

type Props = {
    children: ReactNode;
    hasError?: boolean;
};

type State = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: !!props.hasError };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <AppContainer>
                    <PageNotFound text={'Что-то пошло не так'} errorCode={'500'} />
                </AppContainer>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary };
