import { FC } from 'react';
import cn from 'classnames';
import Chip from '@mui/material/Chip';

import styles from './HeaderNavLink.module.scss';

type Props = {
    name: string;
    href: string;
    isActive: boolean;
    notificationsAmount: number;
};

const HeaderNavLink: FC<Props> = ({ name, href, isActive, notificationsAmount }) => {
    return (
        <div className={styles.button}>
            <a href={href} className={cn(styles.link, isActive && styles.link_active)}>
                {name}
            </a>
            {!!notificationsAmount && (
                <Chip label={notificationsAmount} color="error" size="small" className={styles.notificationChip} />
            )}
        </div>
    );
};

export { HeaderNavLink };
